<div class="vsla-card-card">
  <div class="vsla-card-card__body">
    <div class="vsla-card-card__body__left">
      <div class="vsla-card-card__logo">
        <img src="/assets/imgs/care.svg" />
      </div>
      <div class="vsla-card-card__title">{{ group ? group.name : 'Nhóm VSLA' }}</div>
      <div class="vsla-card-card__description" *ngIf="term?._meetingsCount">
        {{'home.label.vsla.current_periods' | translate}}: {{ term._meetingsCount }}
      </div>
    </div>
    <div class="vsla-card-card__body__right">
      <img src="/assets/imgs/vsla-card.svg" />
    </div>
  </div>
  <div class="vsla-card-card__footer">
    <button>
      {{'home.label.vsla.go_to_group' | translate}}
    </button>
  </div>
</div>