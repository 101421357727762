import { Pipe, PipeTransform, Inject } from '@angular/core';
import { vsprintf, sprintf } from 'sprintf-js';
import _ from 'lodash';

@Pipe({
  name: 'ccFormat'
})
export class CcFormatPipe implements PipeTransform {

  transform(value: any, data: object | []): any {
    // const defaultValue = args[0];
    try {
      if (_.isArray(data)) {
        return vsprintf(value, data);
      }
      if (_.isObject(data)) {
        return sprintf(value, data);
      }
      return value;
    } catch (e) {
        return  value;
    }
  }
}
