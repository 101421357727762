import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { NewFeedComponent } from './new-feed/new-feed.component';
import { NewFeedLoadingComponent } from './new-feed-loading/new-feed-loading.component';
import { RouterModule } from '@angular/router';
import { VslaCardComponent } from './vsla-card/vsla-card.component';
import { FeatureCardComponent } from './feature-card/feature-card.component';
import { MfiPromotionCardComponent } from './mfi-promotion-card/mfi-promotion-card.component';
import { ApplicationCardComponent } from './application-card/application-card.component';
import { LocationAlertCardComponent } from './location-alert-card/location-alert-card.component';
import { NewsCategoryComponent } from './news-category/news-category.component';
import { InputCurrencyModule } from '@shared/input-currency/input-currency.module';
import { CtaCardComponent } from './cta-card/cta-card.component';
import { SharedModule } from '@shared/shared.module';
import { CrossSellingCardComponent } from './cross-selling-card/cross-selling-card.component';
import { HomeSlideComponent } from './home-slide/home-slide.component';
import { RefererCardComponent } from './referer-card/referer-card.component';
import { CcFormV2Module } from '@shared/cc-form-v2/cc-form-v2.module';
import { RefererCodeComponent } from './referer-code/referer-code.component';
import { InputPhoneModule } from '@shared/input-phone/input-phone.module';
import { ChatCardComponent } from 'src/app/home-new/components/chat-card/chat-card.component';
import { RecentChatsComponent } from 'src/app/home-new/components/recent-chats/recent-chats.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { FinanceCardComponent } from './finance-card/finance-card.component';
import { ImageModule } from '@shared/image/image.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TizoFormsModule } from '@shared/forms/forms.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import { ServiceCardComponent } from './service-card/service-card.component';
import { ProductCollectionsComponent } from './product-collections/product-collections.component';

@NgModule({
    declarations: [
        NewFeedComponent,
        NewFeedLoadingComponent,
        VslaCardComponent,
        FeatureCardComponent,
        MfiPromotionCardComponent,
        ApplicationCardComponent,
        LocationAlertCardComponent,
        VslaCardComponent,
        NewsCategoryComponent,
        NewFeedComponent,
        CtaCardComponent,
        CrossSellingCardComponent,
        HomeSlideComponent,
        RefererCardComponent,
        RefererCodeComponent,
        ChatCardComponent,
        RecentChatsComponent,
        HomeHeaderComponent,
        FinanceCardComponent,
        ServiceCardComponent,
        ProductCollectionsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        InputCurrencyModule,
        SharedModule,
        CcFormV2Module,
        InputPhoneModule,
        ImageModule,
        TranslateModule,
        ReactiveFormsModule,
        PipesModule
    ],
    exports: [
        NewFeedComponent,
        NewFeedLoadingComponent,
        VslaCardComponent,
        FeatureCardComponent,
        MfiPromotionCardComponent,
        ApplicationCardComponent,
        LocationAlertCardComponent,
        VslaCardComponent,
        NewsCategoryComponent,
        NewFeedComponent,
        CtaCardComponent,
        CrossSellingCardComponent,
        HomeSlideComponent,
        RefererCardComponent,
        RefererCodeComponent,
        ChatCardComponent,
        RecentChatsComponent,
        HomeHeaderComponent,
        FinanceCardComponent,
        ServiceCardComponent,
        ProductCollectionsComponent
    ]
})
export class ComponentsModule {
}
