import { Directive, AfterViewInit, ElementRef } from '@angular/core';
import { IonInput } from '@ionic/angular';

@Directive({
  selector: '[tizoAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  constructor(public element: ElementRef) {

  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.element.nativeElement.setFocus();
    }, 500);
  }
}
