import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { IonicCoreService } from '@services/ionic-core.service';
import { RefererService } from '@services/referer.service';
import { CommonConfirmModalComponent } from '@shared/components/common-confirm-modal/common-confirm-modal.component';
import { PhonePipe } from '@shared/input-phone/phone.pipe';
import { ModalConfirmProps } from '@shared/models';
import { phoneNumberValidator } from '@shared/validators';

@Component({
  selector: 'tizo-referer-card',
  templateUrl: './referer-card.component.html',
  styleUrls: ['./referer-card.component.scss'],
})
export class RefererCardComponent implements OnInit {
  form = new FormGroup({
    referrerPhoneNumber: new FormControl('', [phoneNumberValidator])
  });
  constructor(
    private coreService: IonicCoreService,
    private modalCtrl: ModalController,
    private phonePipe: PhonePipe,
    private refererService: RefererService,
  ) { }

  ngOnInit() {
  }

  async onSubmit() {
    const phoneNumberCtrl = this.form.get('referrerPhoneNumber');

    if (!phoneNumberCtrl.valid) {
      return this.coreService.showToastWarning({
        message: 'Vui lòng nhập SĐT đúng định dạng.'
      });
    }

    const cmpProps: ModalConfirmProps = {
      content: `Bạn chắc chắn người giới thiệu bạn có số điện thoại là <b>${this.phonePipe.transform(phoneNumberCtrl.value)}</b> ?`,
    };
    const modal = await this.modalCtrl.create({
      cssClass: 'modal-transparent',
      component: CommonConfirmModalComponent,
      componentProps: cmpProps
    });
    modal.present();
    modal.onDidDismiss().then(data => {
      if (data.data) {
        if (data.data.confirmed) {
          this.doSave(phoneNumberCtrl.value);
        }
      }
    });
  }

  async doSave(phoneNumber: string) {
    await this.coreService.showLoading();
    this.refererService.saveReferer(phoneNumber).then(() => {
      this.coreService.showToastSuccess({
        message: 'Thêm người giới thiệu thành công'
      });
    }).catch((e: Error) => {
      this.coreService.showToastError({
        message: 'Thêm người giới thiệu không thành công. ' + e.message
      });
      console.error(e);
    }).finally(() => {
      this.coreService.hideLoading();
    });
  }

}
