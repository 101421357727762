import { Pipe, PipeTransform } from '@angular/core';
import { removeHartVn } from '@shared/utils';
import _get from 'lodash/get';

@Pipe({
  name: 'localSearch'
})
export class LocalSearchPipe implements PipeTransform {

  transform(arr: Array<any>, fieldName: string, query: string): any {
    if (!query) {
      return arr;
    }
    return arr.filter(item => {
      const fieldCompare = removeHartVn(_get(item, fieldName, '').trim()).toLocaleLowerCase().replace(/ /g, '');
      const queryCompare = removeHartVn(query).trim().toLocaleLowerCase().replace(/ /g, '');
      return fieldCompare.indexOf(queryCompare) !== -1;
    });
  }
}
