import { Component, OnInit, forwardRef, Input, ViewChild, ElementRef, OnChanges, Output, EventEmitter, HostBinding } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';

@Component({
  selector: 'tizo-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true
    }
  ]
})
export class InputNumberComponent implements OnInit, ControlValueAccessor, OnChanges {
  isFocus: boolean;
  @ViewChild('input') inputElement: ElementRef;
  @Input() suffix = '';
  @Input() max: number;
  @Input() min: number;
  @Input() showMask = true;
  @Input() autoFocus: boolean;
  @Output() focus: EventEmitter<void> = new EventEmitter();
  @Output() blur: EventEmitter<void> = new EventEmitter();
  @Input('disabled') set disabled(isDisabled) {
    this.isDisabled = isDisabled;
  }
  @HostBinding('class.disabled') isDisabled;
  form = new FormControl();
  constructor() { }

  ngOnInit() { }
  ngOnChanges() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 500);
    }
  }
  writeValue(val) {
    this.form.setValue(val);
  }
  registerOnChange(fn) {
    this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched() {

  }
  onFocus() {
    this.focus.emit();
    this.isFocus = true;
  }
  onBlur() {
    this.blur.emit();
    this.isFocus = false;
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.isDisabled = isDisabled;
    }
  }
}
