export const MONTH_NAMES = [
    'Tháng 1',
    'Tháng 2',
    'Tháng 3',
    'Tháng 4',
    'Tháng 5',
    'Tháng 6',
    'Tháng 7',
    'Tháng 8',
    'Tháng 9',
    'Tháng 10',
    'Tháng 11',
    'Tháng 12'
];

export const WEEK_DAYS_NAME = [
    'CN', 'Th2', 'Th3', 'Th4', 'Th5', 'Th6', 'Th7'
];

export const LANGUAGE_KEYS = {
    en: 'en_US',
    vi: 'vi_VN',
};
