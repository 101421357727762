import {
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    Output,
    ElementRef,
    Inject,
    Injector,
    AfterViewInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {KEY_CODES} from '@shared/contants';
import {CURRENCY_CONFIG} from '@shared/input-currency/token';
import {ICurrencyInput} from '@shared/models';

const DEFAULT_CURRENCY_INPUT: ICurrencyInput = {
    unit: 1000,
    roundSuffix: '.000',
    currencySuffix: 'đ',
    mask: 'separator',
    thousandSeparator: '.',
    allowNegative: false,
    rounding: false
};

@Component({
    selector: 'tizo-currency-field',
    templateUrl: './currency-field.component.html',
    styleUrls: ['./currency-field.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CurrencyFieldComponent), multi: true}
    ]
})
export class CurrencyFieldComponent implements OnInit, ControlValueAccessor, AfterViewInit {
    @Input() name = '';
    @Input() placeholder = '';

    @ViewChildren('fieldContainer') fieldContainers: QueryList<ElementRef<HTMLInputElement>>;
    @Input() config: Partial<ICurrencyInput> = {};
    @Input() min = 0;
    @Input() readonly: boolean;
    @Output() change: EventEmitter<number> = new EventEmitter();
    @Output() focus = new EventEmitter();
    @Output() blur = new EventEmitter();
    @Input() required: boolean;
    @Input('disabled') set disabled(isDisabled) {
        this.isDisabled = isDisabled;
    }

    @HostBinding('class.disabled') isDisabled;
    suffix: string;
    form: FormControl = new FormControl('');
    isFocus: boolean;
    currencyConfig: ICurrencyInput = DEFAULT_CURRENCY_INPUT;

    constructor(private elemment: ElementRef) {
    }

    ngOnInit() {

        if (this.config) {
            this.currencyConfig = {...this.currencyConfig, ...this.config};
        }
        if (this.isDisabled) {
            this.form.disable();
        }
        this.suffix = this.currencyConfig.rounding ? '.000' : '';
    }

    ngAfterViewInit() {
        this.fieldContainers.forEach(container => {
            // tslint:disable-next-line:only-arrow-functions
            container.nativeElement.addEventListener('click', function(event) {
                event.stopPropagation();
                const inputElement = this.querySelector('input');
                if (inputElement) {
                    inputElement.focus();
                }
            }, true);
        });
    }

    writeValue(val) {
        if (this.currencyConfig.rounding) {
            const formValue = val === 0 ? 0 : (+val / this.currencyConfig.unit || '');
            this.form.setValue(formValue);
        } else {
            this.form.setValue(val);
        }
    }

    registerOnChange(fn) {
        this.form.valueChanges.subscribe(val => {
            if (this.currencyConfig.rounding) {
                const formValue = +val * this.currencyConfig.unit;
                fn(formValue);
                this.change.emit(formValue);
            } else {
                fn(val);
                this.change.emit(val);
            }
        });
    }

    registerOnTouched() {

    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.isDisabled = isDisabled;
        }
    }

    onFocus(event) {
        this.isFocus = true;
        this.focus.emit();
    }

    onBlur(event) {
        this.isFocus = false;
        this.blur.emit();
    }

    onPress(event) {
        const canNotPress: boolean = (event.keyCode === KEY_CODES.minus && !this.currencyConfig.allowNegative);
        if (canNotPress) {
            event.preventDefault();
        }
    }
}
