import { firestore } from 'firebase';
import { DatePipe } from '@angular/common';
import { Application, ApplicationStatus, Tenant } from '@canalcircle/models';
import _get from 'lodash/get';
import { Injectable } from '@angular/core';

const TITLE_PATH = 'data.mucDichVay.name';

export default class TizoApplicationModel {
    private datePipe: DatePipe;
    id: string;
    mfiTitle: string;
    title: string;
    icon: string;
    wantedLoanAmount: number;
    statusText: string;

    constructor(
        private application: Application,
        private tenant: Tenant,
    ) {
        // @TODO: find a way to share instance of datepipe
        this.datePipe = new DatePipe('en_US');
        this.setExtraInfo();
    }

    async setExtraInfo() {
        this.mfiTitle = `Đơn vay ` + (this.tenant?.shortName || this.tenant?.name);
        this.id = this.application.id;
        const titleFromApplication = _get(this.application, TITLE_PATH);
        this.title = titleFromApplication ?
            titleFromApplication + ' ' + this.getFormattedCreatedAt2() :
            `Vay lúc: ` + this.getFormattedCreatedAt();

        switch (this.application.status) {
            case ApplicationStatus.CLIENT_DRAFT:
            case ApplicationStatus.UNCLAIMED:
                this.icon = '/assets/icon/applications/draft.svg';
                break;
            case ApplicationStatus.DRAFT:
            case ApplicationStatus.SUBMITTED:
            case ApplicationStatus.REVIEWING:
            case ApplicationStatus.REVIEWED:
            case ApplicationStatus.APPROVED:
            case ApplicationStatus.APPROVED_ALLOCATE_WAITING:
            case ApplicationStatus.ALLOCATED:
            case ApplicationStatus.ALLOCATED_DISBURSE_WAITING:
                this.icon = '/assets/icon/applications/pending.svg';
                break;
            case ApplicationStatus.DISBURSED:
            case ApplicationStatus.DISBURSED_SETTLE_WAITING:
            case ApplicationStatus.SETTLED:
                this.icon = '/assets/icon/applications/success.svg';
                break;
            case ApplicationStatus.REJECTED:
            case ApplicationStatus.WITHDRAWN:
            case ApplicationStatus.DELETED:
                this.icon = '/assets/icon/applications/rejected.svg';
                break;
        }

        // @ts-ignore
        this.wantedLoanAmount = this.application.data?.loanProduct?.amount;

        switch (this.application.status) {
            case ApplicationStatus.CLIENT_DRAFT:
                this.statusText = 'Chưa gửi';
                break;
            case ApplicationStatus.UNCLAIMED:
                this.statusText = 'Đã gửi';
                break;
            case ApplicationStatus.DRAFT:
            case ApplicationStatus.SUBMITTED:
            case ApplicationStatus.REVIEWING:
            case ApplicationStatus.REVIEWED:
            case ApplicationStatus.APPROVED:
            case ApplicationStatus.APPROVED_ALLOCATE_WAITING:
            case ApplicationStatus.ALLOCATED:
            case ApplicationStatus.ALLOCATED_DISBURSE_WAITING:
                this.statusText = 'Chờ xử lý';
                break;
            case ApplicationStatus.DISBURSED:
            case ApplicationStatus.DISBURSED_SETTLE_WAITING:
            case ApplicationStatus.SETTLED:
                this.statusText = 'Đã giải ngân';
                break;
            case ApplicationStatus.REJECTED:
            case ApplicationStatus.WITHDRAWN:
            case ApplicationStatus.DELETED:
                this.statusText = 'Từ chối';
                break;
        }
    }


    // getIconColor() {
    //     switch (this.application.status) {
    //         case ApplicationStatus.CLIENT_DRAFT:
    //             return 'danger';
    //         case ApplicationStatus.UNCLAIMED:
    //             return 'primary';
    //         case ApplicationStatus.DRAFT:
    //         case ApplicationStatus.SUBMITTED:
    //         case ApplicationStatus.REVIEWING:
    //         case ApplicationStatus.REVIEWED:
    //         case ApplicationStatus.APPROVED:
    //         case ApplicationStatus.APPROVED_ALLOCATE_WAITING:
    //         case ApplicationStatus.ALLOCATED:
    //         case ApplicationStatus.ALLOCATED_DISBURSE_WAITING:
    //             return 'tertiary';
    //         case ApplicationStatus.DISBURSED:
    //         case ApplicationStatus.DISBURSED_SETTLE_WAITING:
    //         case ApplicationStatus.SETTLED:
    //             return 'success';
    //         case ApplicationStatus.REJECTED:
    //         case ApplicationStatus.WITHDRAWN:
    //         case ApplicationStatus.DELETED:
    //             return 'warning';
    //     }
    // }

    getFormattedCreatedAt() {
        return this.datePipe.transform((this.application.createdAt as firestore.Timestamp).toDate(), 'dd/MM/yyyy HH:mm:ss');
    }

    getFormattedCreatedAt2() {
        return this.datePipe.transform((this.application.createdAt as firestore.Timestamp).toDate(), 'dd/MM/yyyy');
    }
}
