<div class="service-card">
  <ion-item class="ion-no-padding" lines="none" style="--inner-padding-end:0">
    <ion-icon src="assets/icon/home/Ticket.svg" slot="start" style="margin-right:10px;margin-left: 3px;"></ion-icon>
    <ion-label>
      <ion-text style="font-size: 16px;font-weight: 600;"> {{ 'home.label.service_card' | translate | uppercase }}
      </ion-text>
    </ion-label>
  </ion-item>

  <ng-container *ngIf="(isSeller$ | async); else notSeller">
    <ion-row>
      <ion-col size="6">
        <ion-card mode="ios" button class="ion-no-margin service-card__item service-card--total-order"
          (click)="goToOrders()">
          <ion-row>
            <ion-icon src="assets/icon/home/Buy.svg">
            </ion-icon>
            <div>
              <div class="font-weight-bold service-card__title">{{data?.totalOrders}} {{'home.label.order' | translate}}
              </div>
              <div class="service-card__subtitle">{{'home.label.ordered' | translate}}</div>
            </div>
          </ion-row>
        </ion-card>
      </ion-col>
      <ion-col size="6">
        <ion-card mode="ios" button class="ion-no-margin service-card__item service-card--total-amount"
          (click)="goToOrders()">
          <ion-row>
            <ion-icon src="assets/icon/home/Graph.svg">
            </ion-icon>
            <div>
              <div class="font-weight-bold service-card__title">{{data?.totalPrice | currencyFormat}}</div>
              <div class="service-card__subtitle">{{'home.label.total_amount' | translate}}</div>
            </div>
          </ion-row>
        </ion-card>
      </ion-col>

      <ion-col size="6">
        <ion-card mode="ios" button class="ion-no-margin service-card__item service-card--profit"
          (click)="goToCommisions()">
          <ion-row>
            <ion-icon src="assets/icon/home/Download.svg">
            </ion-icon>
            <div>
              <div class="font-weight-bold service-card__title">{{(crossSellingPoints?.moneys || 0) | currencyFormat}}</div>
              <div class="service-card__subtitle">{{'home.label.profit' | translate}}</div>
            </div>
          </ion-row>
        </ion-card>
      </ion-col>
      <ion-col size="6">
        <ion-card mode="ios" button class="ion-no-margin service-card__item service-card--rank">
          <ion-row>
            <ion-icon src="assets/icon/home/badge.svg">
            </ion-icon>
            <div>
              <div class="font-weight-bold service-card__title">Nghiệp dư</div>
              <div class="service-card__subtitle">{{'home.label.rank' | translate}}</div>
            </div>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
  </ng-container>

  <ng-template #notSeller>
    <img (click)="onRegisterSeller()" class="w-100" src="/assets/imgs/home/seller-banner.svg" />
  </ng-template>
</div>