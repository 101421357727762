import { Component, Input, OnInit } from '@angular/core';
import { TizoCta } from '@canalcircle/models';
import { NavController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';

const {Browser} = Plugins;


@Component({
    selector: 'tizo-cta-card',
    templateUrl: './cta-card.component.html',
    styleUrls: ['./cta-card.component.scss'],
})
export class CtaCardComponent implements OnInit {
    @Input() cta: TizoCta;

    constructor(
        private navController: NavController,
    ) {
    }

    ngOnInit() {
    }

    go() {
        if (this.cta.globalLink) {
            return Browser.open({url: this.cta.globalLink});
        }
        if (this.cta.localLink) {
            const [path, queryStr] = this.cta.localLink.split('?');
            if (queryStr) {
                const queryParams = {};
                queryStr.split('&').forEach(pair => {
                    const [key, value] = pair.split('=');
                    queryParams[key] = value;
                });
                this.navController.navigateForward([path], {queryParams});
            } else {
                this.navController.navigateForward([this.cta.localLink]);
            }
        }
    }

}
