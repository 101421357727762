import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageFormat'
})
export class PercentageFormatPipe implements PipeTransform {
  transform(value: number): any {
    const val = Math.floor(value);
    return (val > 0 ? '+' + val : val) + '%';
  }
}
