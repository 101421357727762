import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    Clipboard,
    PhotoViewer,
    CallNumber,
    SMS,
    ScreenOrientation
  ]
})
export class IonicNativeModule {
}
