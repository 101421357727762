import { marker } from '@biesbjerg/ngx-translate-extract-marker';
export const VSLA_ROLES = {
    vslaleader: 'vslaleader',
    vslaassistant: 'vslaassistant',
    vslavaultkeeper: 'vslavaultkeeper',
    vslakeykeeper: 'vslakeykeeper',
    vslamoneycounter: 'vslamoneycounter',
    vslamember: 'vslamember'
};


// Role name for cc system
export const CC_ROLES = {
    officer: 'officer',
    agent: 'agent',
    client: 'client',
    ccsuperagent: 'ccsuperagent',
    ccagent: 'ccagent',
    seller: 'seller',
    pendingseller: 'pendingseller',
    ccofficeragent: 'ccofficeragent',
};

marker('common.roles.officer');
marker('common.roles.agent');
marker('common.roles.client');
marker('common.roles.ccsuperagent');
marker('common.roles.ccagent');
marker('common.roles.ccofficeragent');
marker('common.roles.member');
marker('common.roles.seller');




