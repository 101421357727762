<div
  class="currency-input">
  <input
    [attr.disabled]="isDisabled"
    [readonly]="readonly"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (keypress)="onPress($event)"
    [formControl]="formCtrl"
    [mask]="formCtrl.value === 0 ? '' : currencyConfig.mask"
    [suffix]="suffix"
    [thousandSeparator]="currencyConfig.thousandSeparator"
    type="tel">
  <div class="currency-input__suffix">{{currencyConfig.currencySuffix}}</div>
</div>
