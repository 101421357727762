import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: Array<any>, filed: string, order = -1): any {
    if (!value) {
      return [];
    }
    return value.sort((a, b) => a[filed] - b[filed]);
  }
}
