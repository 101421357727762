import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Office, Tenant, Config } from '@canalcircle/models';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '@shared/models';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  tenantIdsActived$: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
  tenantIdsActivedByClients$: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
  tenantIdsActivedByLocation$: BehaviorSubject<Array<string>> = new BehaviorSubject([]);

  constructor(
    private http: HttpClient,
    private afStore: AngularFirestore,
    private userService: UserService,
  ) {
  }

  init(user: User) {
    this.checkCurrentActiveTenants(user);
    this.checkCurrentActiveTenantsByLocation(user);
    this.checkCurrentActiveTenantsByClients(user);
  }

  // loadAuthUser() {
  //   this.userService.getCurrentUser$().subscribe((user) => {
  //     this.checkCurrentActiveTenants(user);
  //   });
  // }

  checkCurrentActiveTenants(user) {
    // 1.In case no tenants and no location,show card allow user select tenants
    if (
      (user?.tenantIds?.length === 0 || !user?.tenantIds) &&
      !user?.meta?.location?.province?.id
    ) {
      this.tenantIdsActived$.next([]);
    } else if (
      (user?.tenantIds?.length === 0 || !user?.tenantIds) &&
      !!user?.meta?.temporaryAddress?.province?.id
    ) {
      this.findTenantsByLocation({
        provinceCode: user?.meta?.temporaryAddress.province?.code,
        districtCode: user?.meta?.temporaryAddress.district?.code || '',
        wardCode: user?.meta?.temporaryAddress.ward?.code || '',
      }).subscribe((tenants) => {
        this.tenantIdsActived$.next(tenants.map((tenant) => tenant.id) || []);
      });
    } else if (
      (user?.tenantIds?.length === 0 || !user?.tenantIds) &&
      !!user?.meta?.location?.province?.id
    ) {
      this.findTenantsByLocation({
        provinceCode: user?.meta?.location?.province?.code,
        districtCode: user?.meta?.location?.district?.code || '',
        wardCode: user?.meta?.location?.ward?.code || '',
      }).subscribe((tenants) => {
        this.tenantIdsActived$.next(tenants.map((tenant) => tenant.id) || []);
      });
    } else if (user.tenantIds.length > 0) {
      this.tenantIdsActived$.next(user.tenantIds);
    } else {
      this.tenantIdsActived$.next([]);
    }
  }

  checkCurrentActiveTenantsByLocation(user: User) {
    if (
      !!user?.meta?.temporaryAddress?.province?.id
    ) {
      this.findTenantsByLocation({
        provinceCode: user?.meta?.temporaryAddress.province?.code,
        districtCode: user?.meta?.temporaryAddress.district?.code || '',
        wardCode: user?.meta?.temporaryAddress.ward?.code || '',
      }).subscribe((tenants) => {
        this.tenantIdsActivedByLocation$.next(tenants.map((tenant) => tenant.id) || []);
      });
    } else if (
      !!user?.meta?.location?.province?.id
    ) {
      this.findTenantsByLocation({
        provinceCode: user?.meta?.location?.province?.code,
        districtCode: user?.meta?.location?.district?.code || '',
        wardCode: user?.meta?.location?.ward?.code || '',
      }).subscribe((tenants) => {
        this.tenantIdsActivedByLocation$.next(tenants.map((tenant) => tenant.id) || []);
      });
    } else {
      this.tenantIdsActivedByLocation$.next([]);
    }
  }

  checkCurrentActiveTenantsByClients(user: User) {
    return this.userService.getClientsByCurrentUser().subscribe(clients => {
      this.tenantIdsActivedByClients$.next(clients.map(c => c.tenantId));
    });
  }

  findTenantsByLocation(params: {
    provinceCode: string;
    districtCode: string;
    wardCode: string;
  }): Observable<Array<Tenant>> {
    const url = environment.functionsUrl + '/tizo_v2/tenant/by-province';
    return this.http
      .get<{ data: Array<Tenant> }>(url, {
        params,
      })
      .pipe(
        map((res) => res.data),
        // Because dump of api =))
        catchError((e) => {
          console.log('get ActiveTenant error', e);
          return of([]);
        })
      );
  }

  findOfficesByLocation(params: {
    provinceCode: string;
    districtCode: string;
    wardCode: string;
  }): Observable<Array<Office>> {
    const url = environment.functionsUrl + '/tizo_v2/office/by-province';
    return this.http
      .get<{ data: Array<Office> }>(url, {
        params,
      })
      .pipe(
        map((res) => res.data),
        // Because dump of api =))
        catchError((e) => of([]))
      );
  }

  getTenantsByIds$(tenantIds: string[]): Observable<Tenant[]> {
    return this.afStore
      .collection<Tenant>('tenants', (ref) => ref.where('id', 'in', tenantIds))
      .valueChanges();
  }

  getEnabledFeatureTenants$(featureKey: string): Observable<Tenant[]> {
    return this.afStore
      .collection<Tenant>('tenants', (ref) => ref.where(featureKey, '==', true))
      .valueChanges();
  }

  getEnabledSavingTenants$(): Observable<Tenant[]> {
    return this.getEnabledFeatureTenants$('isEnabledSavingTizo');
  }

  // getEnabledSavingTenants$(): Observable<Tenant[]> {
  //   return this.getEnabledSavingTenantsConfig$()
  //     .pipe(switchMap(configs => this.getTenantsByIds$(configs.map(item => item.id))));
  // }

  getEnabledSavingTenantIds$(): Observable<string[]> {
    return this.getEnabledSavingTenants$().pipe(
      map((tenants) => tenants.map((t) => t.id))
    );
  }

  getEnabledLoanTenantIds$(): Observable<string[]> {
    return this.getEnabledLoanTenants$().pipe(
      map((tenants) => tenants.map((t) => t.id))
    );
  }

  getEnabledLoanTenants$(): Observable<Tenant[]> {
    return this.getEnabledFeatureTenants$('isEnabledLoanTizo');
  }

  getAllTenants(): Observable<Tenant[]> {
    return this.afStore
      .collection<Tenant>('tenants')
      .get()
      .pipe(
        map(actions => actions.docs.map(d => d.data() as Tenant))
      );
  }

  getTenantsById(tenantId: string) {
    return this.afStore.collection<Tenant>('tenants',
      ref => ref.where('id', '==', tenantId))
      .get()
      .pipe(take(1));
  }

  getTenantsByIds(tenantIds: Array<string>) {
    return this.afStore.collection<Tenant>('tenants',
      ref => ref.where('id', 'in', tenantIds))
      .valueChanges()
      .pipe(take(1));
  }
}
