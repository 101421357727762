import { Component, OnInit, Input, forwardRef, ElementRef, Output, EventEmitter, HostBinding } from '@angular/core';
import { ControlValueAccessor, AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as momnet from 'moment';
@Component({
  selector: 'tizo-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    }
  ]
})
export class DateInputComponent implements OnInit, ControlValueAccessor {
  @Input() mask = '00/00/0000';
  @Input() showMaskedType = true;
  @Input() value: string;
  @Output() blur = new EventEmitter();
  @Output() focus = new EventEmitter();

  form: FormControl = new FormControl('');
  @Input('disabled') set disabled(isDisabled) {
    this.isDisabled = isDisabled;
  }
  @HostBinding('class.disabled') isDisabled;
  onTouch: () => void;
  constructor(
    private element: ElementRef
  ) { }

  ngOnInit() {
    if (this.value) {
      const formatedDate = this.formatDate(this.value);
      this.form.setValue(formatedDate);
    }
  }
  writeValue(val) {
    if (val) {
      const formatedDate = this.formatDate(val);
      this.form.setValue(formatedDate);
    }
  }
  registerOnChange(fn) {
    this.form.valueChanges.subscribe(val => {
      fn(this.reFormatDate(val));
    });
  }
  registerOnTouched(fn) {
    this.onTouch = fn;
  }
  validate(c: AbstractControl) {
    // return this.form.errors ? this.form.errors : null;
  }

  formatDate(date: string) {
    return momnet(date).format('DD/MM/YYYY');
  }
  reFormatDate(date: string) {
    return this.isValidDate(date) ? momnet(date, 'DD/MM/YYYY').toDate().toISOString() : date;
  }
  isValidDate(date: string) {
    return momnet(date, 'DD/MM/YYYY', true).isValid();
  }

  onFocus() {
    this.focus.emit();
  }
  onBlur() {
    this.blur.emit();
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.isDisabled = isDisabled;
    }
  }
}
