export const APPLICATION_STORAGE_KEYS = {
  agreeTermOfLoan: 'tizo-agreeTermOfLoan',
  language: 'tizo-language',
  userMode: 'tizo-user-mode',
  viewedCollections: 'tizo-viewed-collections'
}

export enum USER_MODE {
  LOAN = 'loan',
  OVERDRAFT = 'overdraft'
}