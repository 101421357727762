<ion-card button class="new-feed">
    <ion-card-content class="ion-no-padding new-feed__container">
        <div class="new-feed__img-wrapper">
            <div class="new-feed__img-container">
                <img [src]="newFeed.image" class="new-feed__img">
            </div>
        </div>
       
        <div class="new-feed__info">
            <div class="new-feed__title">{{newFeed.title}}</div>
            <div class="new-feed__date">
                {{newFeed.createdAt.toDate() | date : 'dd/MM/yyyy'}}
            </div>
        </div>
    </ion-card-content>
</ion-card>