import { DividerComponent } from './divider/divider.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BtnPrimaryComponent } from './btn-primary/btn-primary.component';
import { HeaderPrimaryComponent } from './header-primary/header-primary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BtnSelectComponent } from './btn-select/btn-select.component';
import { NoLoginComponent } from './no-login/no-login.component';
import { CommonConfirmModalComponent } from '@shared/components/common-confirm-modal/common-confirm-modal.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CommonIonIconComponent } from '@shared/components/common-ion-icon/common-ion-icon.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DialogPrimaryComponent } from './dialog-primary/dialog-primary.component';
import { FormFieldsModule } from '@shared/form-fields/form-fields.module';
import { InputPhoneModule } from '@shared/input-phone/input-phone.module';
import { HeaderSearchbarComponent } from './header-searchbar/header-searchbar.component';
import { SeparatorComponent } from './separator/separator.component';
import { AvatarComponent } from './avatar/avatar.component';
import { UpdatePopupModalComponent } from './update-popup-modal/update-popup-modal.component';
import { SectionComponent } from '@shared/components/section/section.component';
import { CommonWelcomeComponent } from './common-welcome-component/common-welcome-component';
import { RoleBadgeComponent } from '@shared/components/role-badge/role-badge.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        BtnPrimaryComponent,
        HeaderPrimaryComponent,
        BtnSelectComponent,
        NoLoginComponent,
        CommonConfirmModalComponent,
        DialogPrimaryComponent,
        CommonIonIconComponent,
        SpinnerComponent,
        SeparatorComponent,
        HeaderSearchbarComponent,
        AvatarComponent,
        UpdatePopupModalComponent,
        SectionComponent,
        CommonWelcomeComponent,
        DividerComponent,
        RoleBadgeComponent

    ],
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        NgxMaskModule,
        PipesModule,
        FormFieldsModule,
        InputPhoneModule,
        TranslateModule
    ],
    exports: [
        BtnPrimaryComponent,
        HeaderPrimaryComponent,
        BtnSelectComponent,
        NoLoginComponent,
        CommonConfirmModalComponent,
        DialogPrimaryComponent,
        CommonIonIconComponent,
        SpinnerComponent,
        SeparatorComponent,
        HeaderSearchbarComponent,
        AvatarComponent,
        SectionComponent,
        CommonWelcomeComponent,
        DividerComponent,
        RoleBadgeComponent

    ],
    entryComponents: [
        CommonConfirmModalComponent,
        DialogPrimaryComponent,
        UpdatePopupModalComponent,
    ],
})
export class ComponentsModule {
}
