import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'tizo-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit {
  constructor() { }

  ngOnInit() { }

}
