<ion-card class="cross-selling-card" button>
  <ion-row class="ion-align-items-center">
    <ion-col size="7" class="ion-text-center">
      <div style="color:#6D3DB8;font-weight: bold">Mua sắm Tizo</div>
      <div style="color:#ffff;font-weight: bold;font-style: italic;font-size: 18px">VÔ VÀN ƯU ĐÃI!</div>
      <tizo-btn-primary
          size="small"
          style="margin: auto"
          shape="round"
          expand="">{{'common.label.showMore' | translate}}
      </tizo-btn-primary>
    </ion-col>
    <ion-col size="5">
      <img src="assets/combo.png">
    </ion-col>
  </ion-row>
</ion-card>
