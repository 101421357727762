import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'common-ion-icon',
  templateUrl: './common-ion-icon.component.html',
  styleUrls: ['./common-ion-icon.component.scss']
})
export class CommonIonIconComponent implements OnInit {
  @Input() icon: string;
  @Input() fontSize = '22px';
  @Input() color = 'inherit';
  src = '';

  ngOnInit() {
    // @TODO: check this
    this.src = (window.location.hostname.startsWith('localhost') || window.location.hostname.startsWith('192')) ?
      this.icon : this.icon;
  }
}
