import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FineractService } from '@services/fineract.service';
import { TenantService } from '@services/tenant.service';
import { UserService } from '@services/user.service';
import { CurrencyFormatPipe } from '@shared/input-currency/pipes/currency-format.pipe';
import { ILocation } from '@shared/models';
import { firestore } from 'firebase';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, timeout } from 'rxjs/operators';
import {
    Client,
    DepositPeriodTypeCodeEnum,
    DepositTypeIdEnum,
    ICreateSavingAccountData,
    IDepositSavingAccountData,
    ISavingAccount,
    ISavingAccountShort,
    ISavingProduct,
    ISavingRequest,
    ISavingStatus,
    IViewSavingAccountData,
    IWithdrawalSavingAccountData,
    SavingAccountTransactionTypeEnum,
    SavingProductEnum,
    SavingRequestStatusEnum,
    SavingRequestType
} from '@canalcircle/models';
import * as moment from 'moment';
import { TranslatePipe } from '@shared/translate/translate.pipe';
import * as _ from 'lodash';
import { ROUTES } from '@shared/contants';
import { RoutingOfficeService } from '@services/routing-office.service';
import { TranslateService } from '@ngx-translate/core';

// @TODO: move to models
export type SavingRequestData =
    ICreateSavingAccountData
    | IDepositSavingAccountData
    | IWithdrawalSavingAccountData
    | IViewSavingAccountData;

@Injectable({
    providedIn: 'root',
})
export class SavingService {
    readonly COLLECTION_ID = 'savingRequests';
    readonly ALLOW_TXN_TYPES = [
        SavingAccountTransactionTypeEnum.WITHDRAWAL,
        SavingAccountTransactionTypeEnum.DEPOSIT,
        SavingAccountTransactionTypeEnum.INTEREST_POSTING,
        SavingAccountTransactionTypeEnum.DEPOSIT_AS_ROLLOVER,
        SavingAccountTransactionTypeEnum.WITHDRAW_TO_ROLLOVER,
    ];

    constructor(
        private httpClient: HttpClient,
        private fineractService: FineractService,
        private afStore: AngularFirestore,
        private userService: UserService,
        private currencyFormatPipe: CurrencyFormatPipe,
        private tenantService: TenantService,
        private translateService: TranslateService,
        // private translatePipe: TranslatePipe,
        private routingOfficeService: RoutingOfficeService,
    ) {

    }

    public async createSavingAccountRequest(
        product: ISavingProduct,
        userId: string,
        userName: string,
        userPhone: string,
        refererPhoneNumber: string,
        nationalId: string,
        amount: number,
        officePaths: string[],
        client?: Client,
        province?: ILocation,
        district?: ILocation,
        ward?: ILocation,
        otherLocation?: string,
    ) {
        const data: ICreateSavingAccountData & { refererPhoneNumber: string } = {
            product,
            amount,
            fullName: userName,
            phoneNumber: userPhone,
            nationalId,
            province: province || null,
            district: district || null,
            ward: ward || null,
            refererPhoneNumber,
        };

        const request: ISavingRequest<ICreateSavingAccountData> = {
            id: this.afStore.createId(),
            tenantId: product.tenantId,
            createdAt: firestore.Timestamp.now(),
            updatedAt: firestore.Timestamp.now(),
            title: `Yêu cầu mở sổ tiết kiệm. Loại sản phẩm: ${product.name}`,
            userId,
            type: SavingRequestType.CREATE_ACCOUNT,
            status: SavingRequestStatusEnum.PENDING,
            events: {
                [firestore.Timestamp.now().toMillis()]: {
                    status: SavingRequestStatusEnum.PENDING,
                    message: null
                }
            },
            data,
            clientId: client?.id || null,
            clientExternalId: client?.externalIdInfo?.[client?.tenantId] || null,
            clientName: client?.name || userName || null,
            officeId: client?.officeInfo?.[client?.tenantId]?.id || null,
            officePaths: officePaths.length === 0 ? null : officePaths,
        };

        return this.afStore.collection(this.COLLECTION_ID).doc(request.id).set(request, { merge: true });
    }

    public getAuthorizedOrSentAuthorizedRequestTenantIds$(): Observable<string[]> {
        return combineLatest([
            this.fineractService.authorizedViewAccountsClients$.asObservable(),
            this.getSavingRequests$([SavingRequestType.VIEW_ACCOUNTS], true),
        ]).pipe(
            map(([authorizedClients, allAuthorizedRequests]) => {
                const notCanceledAuthorizedRequests = allAuthorizedRequests.filter(r => ![
                    SavingRequestStatusEnum.CANCELED,
                    SavingRequestStatusEnum.REJECTED
                ].includes(r.status));

                const tenantIds = notCanceledAuthorizedRequests.map(request => request.tenantId).concat(authorizedClients.map(client => client.tenantId));

                return _.uniq(tenantIds);
            })
        );
    }

    public async createViewSavingAccountRequests(
        userId: string,
        userName: string,
        userPhone: string,
        nationalId: string,
        province: ILocation,
        district: ILocation,
        ward: ILocation,
        enableGeneric = true
    ) {
        const batch = this.afStore.firestore.batch();

        const [tenantWithOfficePathsAndClient, ignoredTenantIds] = await Promise.all([
            this.routingOfficeService.getTenantWithOfficePathsAndClient(
                'saving',
                province,
                district,
                ward
            ),
            this.getAuthorizedOrSentAuthorizedRequestTenantIds$().pipe(first()).toPromise()
        ]);

        for (const [tenantId, { client, officePaths }] of Object.entries(tenantWithOfficePathsAndClient)) {
            if (ignoredTenantIds.includes(tenantId) || (!enableGeneric && tenantId === 'generic')) {
                continue;
            }

            const data: IViewSavingAccountData = {
                fullName: userName,
                phoneNumber: userPhone,
                nationalId,
                province,
                district,
                ward,
            };

            const request: ISavingRequest<IViewSavingAccountData> = {
                id: this.afStore.createId(),
                tenantId,
                createdAt: firestore.Timestamp.now(),
                updatedAt: firestore.Timestamp.now(),
                title: `Yêu cầu xem sổ vay, sổ tiết kiệm.`,
                userId,
                type: SavingRequestType.VIEW_ACCOUNTS,
                status: SavingRequestStatusEnum.PENDING,
                events: {
                    [firestore.Timestamp.now().toMillis()]: {
                        status: SavingRequestStatusEnum.PENDING,
                        message: null
                    }
                },
                data,
                clientId: client?.id || null,
                clientExternalId: client?.externalIdInfo?.[client?.tenantId] || null,
                clientName: client?.name || userName || null,
                officeId: client?.officeInfo?.[client?.tenantId]?.id || null,
                officePaths: officePaths.length > 0 ? officePaths : null,
            };

            batch.set(this.afStore.collection(this.COLLECTION_ID).doc(request.id).ref, request, { merge: true });
        }


        return batch.commit();
    }

    public async createDepositSavingAccountRequest(saving: ISavingAccount, userId: string, amount: number) {
        const clients = await this.userService.getClientsByUserId$(userId).pipe(first()).toPromise();
        const client = clients.find(c => c.tenantId === saving._tenantId);

        const data: IDepositSavingAccountData = {
            savingId: saving.id.toString(),
            savingAccountNo: saving.accountNo,
            savingType: saving._type,
            amount,
        };

        const request: ISavingRequest<IDepositSavingAccountData> = {
            id: this.afStore.createId(),
            tenantId: saving._tenantId,
            createdAt: firestore.Timestamp.now(),
            updatedAt: firestore.Timestamp.now(),
            title: `Yêu cầu gửi ${this.currencyFormatPipe.transform(amount)} vào sổ tiết kiệm ${saving.accountNo}`,
            userId,
            type: SavingRequestType.DEPOSIT,
            status: SavingRequestStatusEnum.PENDING,
            events: {
                [firestore.Timestamp.now().toMillis()]: {
                    status: SavingRequestStatusEnum.PENDING,
                    message: null
                }
            },
            data,
            clientId: client?.id.toString() || null,
            clientExternalId: saving.clientId.toString() || client?.externalIdInfo?.[client?.tenantId] || null,
            clientName: saving.clientName || client?.name || null,
            officeId: client?.officeInfo?.[client?.tenantId]?.id || null,
            officePaths: client?.officePaths || null,
        };

        return this.afStore.collection(this.COLLECTION_ID).doc(request.id).set(request, { merge: true });
    }

    public async createWithdrawalSavingAccountRequest(saving: ISavingAccount, userId: string, amount: number) {
        const clients = await this.userService.getClientsByUserId$(userId).pipe(first()).toPromise();
        const client = clients.find(c => c.tenantId === saving._tenantId);

        const data: IWithdrawalSavingAccountData = {
            savingId: saving.id.toString(),
            savingAccountNo: saving.accountNo,
            savingType: saving._type,
            amount,
        };

        const request: ISavingRequest<IWithdrawalSavingAccountData> = {
            id: this.afStore.createId(),
            tenantId: saving._tenantId,
            createdAt: firestore.Timestamp.now(),
            updatedAt: firestore.Timestamp.now(),
            title: saving._type === SavingProductEnum.RECURRING ?
                `Yêu cầu rút ${this.currencyFormatPipe.transform(amount)} từ sổ tiết kiệm ${saving.accountNo}` :
                `Yêu cầu đáo hạn sớm sổ tiết kiệm ${saving.accountNo}`,
            userId,
            type: SavingRequestType.WITHDRAWAL,
            status: SavingRequestStatusEnum.PENDING,
            events: {
                [firestore.Timestamp.now().toMillis()]: {
                    status: SavingRequestStatusEnum.PENDING,
                    message: null
                }
            },
            data,
            clientId: client?.id.toString() || null,
            clientExternalId: saving.clientId.toString() || client?.externalIdInfo?.[client?.tenantId] || null,
            clientName: saving.clientName || client?.name || null,
            officeId: client?.officeInfo?.[client?.tenantId]?.id || null,
            officePaths: client?.officePaths || null,
        };

        return this.afStore.collection(this.COLLECTION_ID).doc(request.id).set(request, { merge: true });
    }

    public getFixedSavingProducts$(): Observable<ISavingProduct[]> {
        return this.tenantService.getEnabledSavingTenantIds$().pipe(
            switchMap(tenantIds => tenantIds.length > 0 ?
                this.getFixedSavingProductsForTenantId$(tenantIds) :
                of([])
            ),
            map(products => {
                return products.map(p => this.formatSavingProduct(p));
            }),
        );
    }

    public getRecurringSavingProducts$(): Observable<ISavingProduct[]> {
        return this.tenantService.getEnabledSavingTenantIds$().pipe(
            switchMap(tenantIds => tenantIds.length > 0 ?
                this.getRecurringSavingProductsForTenantIds$(tenantIds) :
                of([])
            ),
            map(products => {
                return products.map(p => this.formatSavingProduct(p));
            }),
        );
    }

    // @TODO: update model to use union type
    public getSavingRequests$(types: SavingRequestType[] = [], loadClient?: boolean): Observable<ISavingRequest<SavingRequestData>[]> {
        return this.userService.getCurrentUserId().pipe(
            switchMap(uid => this.afStore.collection<ISavingRequest<SavingRequestData>>('savingRequests', ref => ref.where('userId', '==', uid))
                .valueChanges()
                .pipe(
                    map(savingRequests => {
                        return savingRequests
                            .filter(sr => types.length === 0 || types.includes(sr.type))
                            .sort((s1, s2) => s2.createdAt.toMillis() - s1.createdAt.toMillis());
                    }),
                    switchMap(savingRequests => {
                        if (!loadClient) {
                            return of(savingRequests);
                        }
                        if (savingRequests.length === 0) {
                            return of([]);
                        }

                        const observables$ = savingRequests.map(request => {
                            return this.userService.getClientByUserIdAndTenantId(request.userId, request.tenantId).pipe(
                                map(client => ({
                                    ...request,
                                    clientId: client?.id || null
                                })),
                            );
                        });

                        return combineLatest(observables$);
                    })
                ))
        );
    }

    getLatestSavingRequest$() {
        return this.getSavingRequests$().pipe(
            map(savingRequests => savingRequests.length > 0 ? savingRequests[0] : null),
            // @TODO need refactor
            map(request => {
                if (request != null) {
                    (request as any)._type = 'saving';
                }
                return request;
            })
        );
    }

    public getDepositWithdrawalRequestsForSaving$(savingId: string, tenantId: string): Observable<ISavingRequest<SavingRequestData>[]> {
        return this.getSavingRequests$([SavingRequestType.DEPOSIT, SavingRequestType.WITHDRAWAL]).pipe(
            map(requests => {
                console.log({ requests });
                return requests.filter(request => {
                    const check1 = request.tenantId === tenantId;
                    let check2 = false;
                    if (request.type === SavingRequestType.DEPOSIT) {
                        check2 = (request.data as IDepositSavingAccountData).savingId === savingId;
                    }
                    if (request.type === SavingRequestType.WITHDRAWAL) {
                        check2 = (request.data as IWithdrawalSavingAccountData).savingId === savingId;
                    }
                    return check1 && check2;
                });
            })
        );
    }

    public cancelSavingRequests(requestId: string): Promise<void> {
        return this.afStore.firestore.runTransaction(async txn => {
            const ref = this.afStore.collection('savingRequests').doc(requestId).ref;
            const request = (await txn.get(ref)).data() as ISavingRequest<any>;
            if (!request) {
                throw new Error('Yêu cầu không tồn tại');
            }
            if (request.status !== SavingRequestStatusEnum.PENDING) {
                throw new Error('Trạng thái yêu cầu không hợp lệ');
            }

            await txn.update(ref, {
                status: SavingRequestStatusEnum.CANCELED,
                events: {
                    ...request.events,
                    [firestore.Timestamp.now().toMillis()]: {
                        status: SavingRequestStatusEnum.CANCELED,
                        message: null
                    }
                }
            });
        });
    }

    public getSavingAccounts$(statusKey?: keyof ISavingStatus): Observable<ISavingAccount[]> {
        return this.fineractService.authenticatedClients$.pipe(
            switchMap(clients => clients.length === 0 ?
                of([]) :
                forkJoin(
                    clients.map(
                        client => this.getSavingAccountsForTenantId$(client.externalIdInfo[client.tenantId], client.tenantId).pipe(
                            // In case not authorized yet.
                            catchError(e => of([] as ISavingAccountShort[])),
                            map(savingAccountShorts => {
                                return statusKey ?
                                    savingAccountShorts.filter(acc => acc.status[statusKey] === true) :
                                    savingAccountShorts;
                            }),
                            switchMap(savingAccountShorts => {
                                const $s: Observable<ISavingAccount>[] = [];
                                savingAccountShorts.forEach(savingAccountShort => {
                                    if (savingAccountShort.depositType.id === DepositTypeIdEnum.FIXED_DEPOSIT) {
                                        $s.push(this.getFixedSavingAccount(savingAccountShort.id.toString(), savingAccountShort._tenantId));
                                    } else if (savingAccountShort.depositType.id === DepositTypeIdEnum.RECURRING_DEPOSIT) {
                                        $s.push(this.getRecurringSavingAccount(savingAccountShort.id.toString(), savingAccountShort._tenantId));
                                    } else if (savingAccountShort.depositType.id === DepositTypeIdEnum.MANDATORY_DEPOSIT) {
                                        $s.push(this.getMandatorySavingAccount(savingAccountShort.id.toString(), savingAccountShort._tenantId));
                                    }
                                });
                                return $s.length === 0 ?
                                    of([]) :
                                    forkJoin($s);
                            })
                        )
                    )
                )),
            map((accounts: ISavingAccount[][]) => accounts.flat().flat().sort((ac1, ac2) => +ac2.id - +ac1.id))
        );
    }

    public getSavingAccount(type: SavingProductEnum, id: string, tenantId: string): Observable<ISavingAccount> {
        switch (type) {
            case SavingProductEnum.RECURRING:
                return this.getRecurringSavingAccount(id, tenantId);
            case SavingProductEnum.FIXED:
                return this.getFixedSavingAccount(id, tenantId);
            case SavingProductEnum.MANDATORY:
                return this.getMandatorySavingAccount(id, tenantId);
        }
    }

    public getRecurringSavingAccount(id: string, tenantId: string): Observable<ISavingAccount> {
        return this.httpClient.get<ISavingAccount>(`${this.fineractService.baseUrl}/self/recurringdepositaccounts/${id}?associations=transactions`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(p => this.formatSavingAccount(p, tenantId, SavingProductEnum.RECURRING))
        );
    }

    public getFixedSavingAccount(id: string, tenantId: string): Observable<ISavingAccount> {
        return this.httpClient.get<ISavingAccount>(`${this.fineractService.baseUrl}/self/fixeddepositaccounts/${id}?associations=transactions`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(p => this.formatSavingAccount(p, tenantId, SavingProductEnum.FIXED))
        );
    }

    public getMandatorySavingAccount(id: string, tenantId: string): Observable<ISavingAccount> {
        return this.httpClient.get<ISavingAccount>(`${this.fineractService.baseUrl}/self/savingsaccounts/${id}?associations=transactions`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(p => this.formatSavingAccount(p, tenantId, SavingProductEnum.MANDATORY))
        );
    }

    public calculateSaving(
        product: ISavingProduct,
        amount: number,
        depositCount: number,
    ): {
        accumulateInterestAmount: number,
        accumulatePrincipalAmount: number,
        accumulateMaturityAmount: number,
        maturityDate: Date,
    } {
        let maturityDate: Date;
        let toYear: number;
        let accumulateInterestAmount: number;
        let accumulatePrincipalAmount: number;
        let accumulateMaturityAmount: number;

        switch (product.depositPeriodType.code) {
            case DepositPeriodTypeCodeEnum.DAYS:
                maturityDate = moment().add(product.depositPeriod, 'days').toDate();
                toYear = product.depositPeriod / 365;
                break;
            case DepositPeriodTypeCodeEnum.WEEKS:
                maturityDate = moment().add(product.depositPeriod, 'weeks').toDate();
                toYear = product.depositPeriod / 52;
                break;
            case DepositPeriodTypeCodeEnum.MONTHS:
                maturityDate = moment().add(product.depositPeriod, 'months').toDate();
                toYear = product.depositPeriod / 12;
                break;
            case DepositPeriodTypeCodeEnum.YEARS:
                maturityDate = moment().add(product.depositPeriod, 'years').toDate();
                toYear = product.depositPeriod;
                break;
        }

        switch (product.depositTypeEnum) {
            case SavingProductEnum.FIXED:
                accumulatePrincipalAmount = amount;
                break;
            case SavingProductEnum.RECURRING:
                accumulatePrincipalAmount = depositCount * amount;
                break;
        }

        accumulateInterestAmount = accumulatePrincipalAmount * toYear * product.interestRate / 100;
        accumulateMaturityAmount = accumulatePrincipalAmount + accumulateInterestAmount;

        return {
            accumulateInterestAmount,
            accumulatePrincipalAmount,
            accumulateMaturityAmount,
            maturityDate,
        };
    }

    private getFixedSavingProductsForTenantId$(tenantIds: string[]): Observable<ISavingProduct[]> {
        return this.httpClient.get<ISavingProduct[]>(this.fineractService.baseUrl + '/tizo/savingproducts', {
            params: {
                depositProductType: SavingProductEnum.RECURRING,
                tenantIds
            },
            headers: this.fineractService.prepareHeaderForTenant('default'),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
        );
    }

    private getRecurringSavingProductsForTenantIds$(tenantIds: string[]): Observable<ISavingProduct[]> {
        return this.httpClient.get<ISavingProduct[]>(this.fineractService.baseUrl + '/tizo/savingproducts', {
            params: {
                depositProductType: SavingProductEnum.FIXED,
                tenantIds
            },
            headers: this.fineractService.prepareHeaderForTenant('default'),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
        );
    }

    public getSavingAccountsForTenantId$(clientId: string, tenantId: string): Observable<ISavingAccountShort[]> {
        return this.httpClient.get<{ savingsAccounts: ISavingAccountShort[] }>(`${this.fineractService.baseUrl}/self/clients/${clientId}/accounts`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(({ savingsAccounts }) => {
                savingsAccounts = savingsAccounts || [];
                return savingsAccounts.filter(savingAccount =>
                    [DepositTypeIdEnum.FIXED_DEPOSIT, DepositTypeIdEnum.RECURRING_DEPOSIT, DepositTypeIdEnum.MANDATORY_DEPOSIT].includes(savingAccount.depositType.id)
                ).map(savingAccount => ({
                    ...savingAccount,
                    _tenantId: tenantId,
                }));
            }),
        );
    }

    public getEntryPage(): Observable<string> {
        return combineLatest([
            this.getSavingRequests$([SavingRequestType.VIEW_ACCOUNTS, SavingRequestType.CREATE_ACCOUNT]),
            this.fineractService.authorizedViewAccountsClients$.asObservable(),
        ]).pipe(
            map(([savingRequests, authorizedClients]) => {
                const validCreateSavingAccountAndViewAccountsRequests = savingRequests.filter(request =>
                    ![SavingRequestStatusEnum.CANCELED, SavingRequestStatusEnum.REJECTED].includes(request.status));

                if (
                    validCreateSavingAccountAndViewAccountsRequests.length > 0 ||
                    authorizedClients.length > 0
                ) {
                    return ROUTES.SAVING_HOME;
                }

                return ROUTES.SAVING_ON_BOARDING;
            })
        );
    }

    private formatSavingAccount(savingAccount: ISavingAccount, tenantId: string, type: SavingProductEnum) {
        savingAccount.transactions = savingAccount.transactions || [];
        savingAccount.transactions = savingAccount.transactions.filter(txn => this.ALLOW_TXN_TYPES.includes(txn.transactionType.id) && !txn.reversed);
        savingAccount.transactions.forEach(txn => {
            txn._title = this.translateService.instant('saving.enums.' + txn.transactionType.code);
            txn._date = new Date(txn.date[0], txn.date[1] - 1, txn.date[2]);

            return txn;
        });

        savingAccount._tenantId = tenantId;
        savingAccount._type = type;

        if (type === SavingProductEnum.MANDATORY) {
            // @ts-ignore
            savingAccount.nominalAnnualInterestRate = savingAccount.floatingRate?.currentInterestRate;
            // @ts-ignore
            savingAccount.depositProductName = savingAccount.savingsProductName;
        }
        return savingAccount;
    }


    private formatSavingProduct(savingProduct: ISavingProduct) {
        // tslint:disable-next-line:max-line-length
        savingProduct._fullName = `${savingProduct.name} - ${savingProduct.interestRate}% / năm - ${savingProduct.depositPeriod} ${this.translateService.instant('saving.enums.' + savingProduct.depositPeriodType?.code)}`;
        return savingProduct;
    }

}
