import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tizo-feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./feature-card.component.scss'],
})
export class FeatureCardComponent implements OnInit {
  @Input() name: string;
  @Input() key: string;
  @Input() icon: string;
  constructor() { }

  ngOnInit() {}

}
