import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tizo-new-feed-loading',
  templateUrl: './new-feed-loading.component.html',
  styleUrls: ['./new-feed-loading.component.scss'],
})
export class NewFeedLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
