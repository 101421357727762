import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// @@Todo
// Need load from config,temporary store it in local;
const POINT_SUFFIX = 'loyalty.label.points';

@Pipe({
  name: 'points'
})
export class PointsPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
  ) {

  }
  transform(value: string | number): string {
    if (value === null || value === undefined) {
      return `0 ${this.translateService.instant( POINT_SUFFIX)}`;
    }
    return `${value} ${this.translateService.instant( POINT_SUFFIX)}`;
  }
}

