import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Client, ISavingAccountShort } from '@canalcircle/models';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, of, Subscription } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, tap, timeout } from 'rxjs/operators';
import { SavingService } from 'src/app/saving/saving.service';
import { AuthService } from './auth.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';
import { AngularFireAuth } from '@angular/fire/auth';

export interface IRole {
    id: number;
    name: string;
    description: string;
    disabled: boolean;
}

export interface IAuthenticateResponse {
    username: string;
    userId: number;
    base64EncodedAuthenticationKey: string;
    authenticated: boolean;
    officeId: number;
    officeName: string;
    roles: IRole[];
    permissions: string[];
    shouldRenewPassword: boolean;
    isTwoFactorAuthenticationRequired: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FineractService {
    readonly TIMEOUT = 15000;
    done$: BehaviorSubject<number> = new BehaviorSubject(0);

    authenticatedClients$ = new BehaviorSubject<Client[]>([]);
    authorizedViewAccountsClients$ = new BehaviorSubject<Client[]>([]);

    loadAuthenticatedClientsSubscription: Subscription;
    loadAuthorizedViewAccountsClientsSubscription: Subscription;

    tokensByTenant: Map<string, string> = new Map();
    baseUrl = environment.fineractUrl;

    constructor(
        private httpClient: HttpClient,
        private tenantService: TenantService,
        private afAuth: AngularFireAuth,
        private userService: UserService,
    ) {
        this.authenticatedClients$.pipe(tap(clients => console.log('authenticatedClients$: ', clients))).subscribe();
        this.authorizedViewAccountsClients$.pipe(tap(clients => console.log('authorizedViewAccountsClients$: ', clients))).subscribe();
    }

    init() {
        if (this.loadAuthorizedViewAccountsClientsSubscription && !this.loadAuthorizedViewAccountsClientsSubscription.closed) {
            this.loadAuthorizedViewAccountsClientsSubscription.unsubscribe();
        }
        if (this.loadAuthenticatedClientsSubscription && !this.loadAuthorizedViewAccountsClientsSubscription.closed) {
            this.loadAuthenticatedClientsSubscription.unsubscribe();
        }

        this.loadAuthorizedViewAccountsClients();
        this.loadAuthenticatedClients();
    }

    reset() {
        this.authenticatedClients$.next([]);
        this.authorizedViewAccountsClients$.next([]);
        this.tokensByTenant = new Map();
    }

    loadAuthenticatedClients() {
        this.loadAuthenticatedClientsSubscription = this.afAuth.idToken.pipe(
            switchMap(token => combineLatest([
                token ? this.tenantService.getEnabledSavingTenantIds$() : of([]),
                of(token),
                token ? this.userService.getCurrentUserWithClients$() : of({ user: null, clients: [] }),
            ])),
            switchMap(([enabledTenantIds, firebaseToken, { user, clients }]) => {
                const sufficentClients = clients.filter(client => client.tenantId && client.externalIdInfo?.[client.tenantId]);
                const sufficentClientIds = sufficentClients.map(c => c.tenantId);
                const targetTenantIds = _.intersection(enabledTenantIds, sufficentClientIds);
                console.log({ enabledTenantIds, targetTenantIds, firebaseToken, user, clients });

                if (targetTenantIds.length === 0 || !firebaseToken || !user) {
                    return of([]);
                }

                return forkJoin(
                    targetTenantIds.map(
                        tenantId => this.getFineractToken(tenantId, firebaseToken, user.authInfo.phoneNumber)
                            .then(token => ({
                                token,
                                tenantId,
                                client: clients.find(c => c.tenantId === tenantId)
                            }))
                    )
                );
            })
        ).subscribe((pairs) => {
            const authenticatedClients = [];
            this.tokensByTenant.clear();
            pairs.forEach(({ token, tenantId, client }) => {
                if (!token) {
                    this.tokensByTenant.delete(tenantId);
                } else {
                    authenticatedClients.push(client);
                    this.tokensByTenant.set(tenantId, token);
                }
            });
            this.done$.next(1);
            this.authenticatedClients$.next(authenticatedClients);
        }, error => {
            console.error(error);
            this.done$.next(-1);
        }, () => {
            console.log('INIT COMPLETED');
        });
    }

    loadAuthorizedViewAccountsClients() {
        this.loadAuthorizedViewAccountsClientsSubscription = this.authenticatedClients$.pipe(
            switchMap(clients => {
                return forkJoin(
                    clients.map(
                        client => this.httpClient.get<{ savingsAccounts: ISavingAccountShort[] }>(`${this.baseUrl}/self/clients/${client.externalIdInfo[client.tenantId]}/accounts`, {
                            headers: this.prepareHeaderForTenant(client.tenantId),
                        }).pipe(
                            mapTo({
                                client,
                                authorized: true
                            }),
                            catchError(e => of({
                                client,
                                authorized: false
                            }))
                        )
                    )
                );
            }),
            map(clientsInfo => clientsInfo.filter(clientInfo => clientInfo.authorized).map(clientInfo => clientInfo.client))
        ).subscribe(clients => {
            this.authorizedViewAccountsClients$.next(clients);
        });
    }

    async getFineractToken(tenantId: string, firebaseToken: string, phoneNumber: string): Promise<string> {
        const response = await this.httpClient.post<IAuthenticateResponse>(this.baseUrl + '/self/authentication', {
            phone_number: phoneNumber,
            firebaseToken,
        }, {
            headers: this.prepareHeaderForTenant(tenantId),
        })
            .pipe(
                timeout(this.TIMEOUT),
                catchError(e => {
                    console.error(e);
                    return of(null);
                })
            )
            .toPromise<IAuthenticateResponse>();

        return response?.base64EncodedAuthenticationKey;
    }

    prepareHeaderForTenant(tenantId: string) {
        return new HttpHeaders({
            'fineract-platform-tenantid': tenantId,
            Accept: 'application/json'
        });
    }
}
