import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigService } from '@services/config.service';
import { StaticPage } from '@shared/models/staticPage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tizo-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit {
  destroy$ = new Subject();
  @Input() pageKey: string;
  page: StaticPage;
  isLoading;
  constructor(
    private modalCtrl: ModalController,
    private configService: ConfigService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    console.log(this.pageKey);
    this.configService.getStaticPage$(this.pageKey).pipe(
      takeUntil(this.destroy$),
    ).subscribe(page => {
      this.page = page;
      this.isLoading = false;
    }, e => {
      console.error(e);
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
