import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { VSLAGroup, VSLATerm } from '@canalcircle/models';

@Component({
  selector: 'tizo-vsla-card',
  templateUrl: './vsla-card.component.html',
  styleUrls: ['./vsla-card.component.scss'],
})
export class VslaCardComponent implements OnInit {
  @Input() group?: VSLAGroup;
  @Input() term?: VSLATerm;
  @Output() onClick = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
