import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from '@shared/models';
import {Subject} from 'rxjs';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

marker('home.label.good_morning');
marker('home.label.good_afternoon');
marker('home.label.good_evening');

@Component({
    selector: 'tizo-home-header',
    templateUrl: './home-header.component.html',
    styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit, OnDestroy, OnChanges {
    defaultAvatar = 'assets/icon/no-avatar.svg';
    dayPeriods: string;
    // user: User;
    lastName = '';
    destroy$: Subject<void> = new Subject();
    @Input() user: User;
    @Input() totalPoints: number;
    @Input() totalUnreadNotifications: number;

    @Output() onClickNotifications = new EventEmitter();
    @Output() onClickAccount = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.dayPeriods = this.getDayPeriods();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.user) {
            const arr = this.user?.authInfo?.displayName?.split(' ');
            this.lastName = arr?.[arr?.length - 1];
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getDayPeriods(): string {
        const today = new Date();
        const curHr = today.getHours();
        const moringText = 'home.label.good_morning';
        const afternoonText = 'home.label.good_afternoon';
        const eveningText = 'home.label.good_evening';

        if (curHr < 12) {
            return moringText;
        } else if (curHr < 18) {
            return eveningText;
        } else {
            return afternoonText;
        }
    }

    goToAccountPage() {
        this.onClickAccount.emit();
    }

    goToNotificationPage() {
        this.onClickNotifications.emit();
    }

    // goToAccountPage() {
    //     this.navCtrl.navigateForward(['main-tab', 'account']);
    // }
    //
    // goToNotificationPage() {
    //     this.navCtrl.navigateForward(['main-tab', 'notifications']);
    //
    // }
}
