import { trigger, transition, animate, style } from '@angular/animations';

export const translateLeft = trigger('translateLeft', [
    transition(':leave', [
        animate('200ms', style({ opacity: 0, transform: 'translateX(-100%)' }))
    ]),
    transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('500ms', style({ opacity: 1, transform: 'translateX(0)' }))
    ])
]);
