import { IonicCoreService } from './../../../core/services/ionic-core.service';
import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UploadService } from '@services/upload.service';

export interface IRadioOption {
  name: string;
  id: string;
  value: string;
}

@Component({
  selector: 'tizo-image-upload-field',
  templateUrl: './image-upload-field.component.html',
  styleUrls: ['./image-upload-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadFieldComponent),
      multi: true
    }
  ]
})
export class ImageUploadFieldComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() name: string;
  loading: boolean;
  @HostBinding('class.float-field-container--is-disabled') isDisabled;
  onTouch: () => void;
  form: FormControl = new FormControl('');

  constructor(
    private uploadService: UploadService,
    private ionicCoreService: IonicCoreService,
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  async takePhoto() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const extraButtons: ActionSheetButton[] = this.form.value ? [{
      text: 'Xóa ảnh',
      handler: () => {
        this.loading = false;
        this.form.patchValue(null);
      }
    }] : [];
    this.uploadService.pickImage('tizo-avatars', extraButtons).then(async (imageUrl) => {
      console.log({ imageUrl });
      // in case dismiss without upload
      if (!imageUrl) {
        return;
      }
      this.form.patchValue(imageUrl);
    }).catch((e) => {
      console.error(e);
      this.ionicCoreService.showToastError({
        message: 'Có lỗi khi up ảnh. Vui lòng thử lại sau'
      });
    }).finally(() => {
      this.loading = false;
    });
  }

  writeValue(val) {
    this.form.setValue(val);
  }

  registerOnChange(fn) {
    this.form.valueChanges.subscribe(val => {
      fn(val);
    });
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = !!isDisabled;
  }

}
