<ion-card class="ion-no-margin">
  <ion-toolbar mode="ios">
    <ion-title>
      <div style="white-space: normal;font-size: 16px">
        {{title | translate}}
      </div>
    </ion-title>
    <ion-buttons
        slot="end"
        (click)="dismissModal()">
      <ion-button
          fill="clear"
          color="dark">
        <ion-icon
            slot="icon-only"
            name="close-outline">
        </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-card-content>
    <ion-radio-group [formControl]="form">
      <ion-item *ngFor="let tenant of tenants" lines="full">
        <ion-radio slot="start" [value]="tenant.id"></ion-radio>
        <ion-label>
          <div>{{tenant.officeProvince?.provinceName || tenant.provinceName || tenant.name}}</div>
          <ion-note>{{tenant | contentTranslate: 'description'}}</ion-note>
        </ion-label>
      </ion-item>
      <ion-item lines="full" *ngIf="!hideGeneric">
        <ion-radio slot="start" click [value]="'generic'"></ion-radio>
        <ion-label>
          <div>{{'crossSelling.label.ImNotInTheseLocations' | translate}}</div>
          <ion-note>{{'crossSelling.label.tizoWillContinueToExpandIntoNewAreas' | translate}}</ion-note>
        </ion-label>
      </ion-item>
    </ion-radio-group>
    <ion-row class="ion-justify-content-center ion-margin-top">
      <ion-col size="7">
        <tizo-btn-primary  shape="round" [disabled]="form.invalid" (btnClick)="onClickOk()">
          {{'common.button.confirm'}}
        </tizo-btn-primary>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>

<!-- <ng-template #generic>
  <ion-label>
    <div>Tôi không ở các khu vực trên </div>
    <ion-note>Tizo sẽ tiếp tục mở rộng thêm các khu vực mới</ion-note>
  </ion-label>
</ng-template> -->