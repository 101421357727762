import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { FineractService } from '@services/fineract.service';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { getLanguage } from 'src/app/i18n/utils';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  token: string;
  constructor(
    private configService: ConfigService,
    private fineractService: FineractService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const options: any = {};
    // is fineract api
    if (req.headers.has('fineract-platform-tenantid')) {
      const fineractToken = this.fineractService.tokensByTenant.get(req.headers.get('fineract-platform-tenantid'));
      if (fineractToken) {
        options.setHeaders = {
          Authorization: 'Basic ' + fineractToken
        };
      }
    } else {
      const lang = getLanguage();
      // is normal cloud func api
      if (this.configService.userToken && !req.headers.has('Authorization')) {
        options.setHeaders = {
          Authorization: 'Bearer ' + (this.configService.userToken)
        };
      }

      if (lang) {
        options.params = req.params.set('lang', lang);
      }
    }
    if (!isEmpty(options)) {
      req = req.clone(options);
    }

    return next.handle(req);
  }
}
