export const GENDER_LIST =  {
    male: {
        id: 'male',
        name: 'Nam'
    },
    female: {
        id: 'female',
        name: 'Nữ'
    }
};
