<tizo-modal-search-base
        class="ion-page"
        [title]="'common.label.selectWard' | translate"
        [searchPlaceholder]="'common.label.inputWardName' | translate"
        (infinite)="onInfiniteScroll($event)"
        (search)="onSearch($event)"
>
    <ion-item
            [attr.data-cy]="'modal-search-ward-' + w.id"
            (click)="clickItem(w)"
            *ngFor="let w of wards$ |  async | slice : 0 :(page * perPage );trackBy:trackByFn"
            button
            detail="false">
        {{w.name}}
    </ion-item>
</tizo-modal-search-base>
