import { Component, OnInit, Input, Output, EventEmitter, HostBinding, forwardRef } from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'tizo-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputTimeComponent), multi: true }
  ]
})
export class InputTimeComponent implements OnInit, ControlValueAccessor {
  @Input() mask = '00:00';
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Input('disabled') set disabled(isDisabled) {
    this.isDisabled = isDisabled;
  }
  @HostBinding('class.disabled') isDisabled;
  form = new FormControl('');
  constructor() { }

  ngOnInit() { }
  writeValue(val) {
    this.form.setValue(val);
  }
  registerOnChange(fn) {
    this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched() {

  }
  onBlur() {
    this.blur.emit();
  }
  onFocus() {
    this.focus.emit();
  }

  isValidDate(val: string): boolean {
    return moment(val).isValid();
  }

  parseToTimeDisplay(date: Date): string {
    const hours = this.addZero(date.getHours());
    const mins = this.addZero(date.getMinutes());
    return hours + ':' + mins;
  }

  addZero(val: number): string {
    if (val < 10) {
      return '0' + val.toString();
    }
    return val.toString();
  }
}

