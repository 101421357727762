import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { IProductInCart } from '@crossselling/models';

export const STORAGE_CART = 'tizo_carts';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cart$: BehaviorSubject<IProductInCart[]> = new BehaviorSubject<IProductInCart[]>([]);

  constructor(
    private storageCtrl: Storage
  ) {
    this.loadProductsInCart();
  }

  async addProductToCart(product: IProductInCart) {
    const lastProducts = await this.getProductsIncart();
    const existInCarts = lastProducts.find(prod => {
      return this.compareFn(prod, product);
    });
    if (!existInCarts) {
      lastProducts.push(product);
    } else {
      existInCarts.quantity += +product.quantity;
    }
    this.cart$.next(lastProducts);
    return this.storageCtrl.set(STORAGE_CART, JSON.stringify(lastProducts));
  }

  async removeProductToCart(product: Partial<IProductInCart>) {
    const lastProducts = await this.getProductsIncart();
    const prodIdx = lastProducts.findIndex(prod => this.compareFn(prod, product));
    if (prodIdx > -1) {
      lastProducts.splice(prodIdx, 1);
    }
    this.cart$.next(lastProducts);
    return  this.storageCtrl.set(STORAGE_CART, JSON.stringify(lastProducts));
  }

  async loadProductsInCart() {
    const products = await this.getProductsIncart();
    this.cart$.next(products);
  }

  async getProductsIncart(): Promise<Array<IProductInCart>> {
    const json = await this.storageCtrl.get(STORAGE_CART);
    return JSON.parse(json) || [];
  }

  private compareFn(a: Partial<IProductInCart>, b: Partial<IProductInCart>): boolean {
    return (a.id === b.id) && (a.variant.id === b.variant.id);
  }
}
