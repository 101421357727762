<div class="application-card">
  <div class="application-card__left">
    <img [src]="application.icon" />
  </div>
  <div class="application-card__main">
    <div class="application-card__main__title">
      {{ application.mfiTitle }}
    </div>
    <div *ngIf="application.wantedLoanAmount" class="application-card__main__amount">
      {{ application.wantedLoanAmount | currencyFormat }}
    </div>
  </div>
  <!-- <div class="application-card__right">
    {{ application.statusText }}
  </div> -->
</div>