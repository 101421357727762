import { Component, OnInit, Input } from '@angular/core';
import { CalendarComponentOptions, CalendarModalOptions } from 'ion2-calendar';
import { MONTH_NAMES, WEEK_DAYS_NAME } from '../../constants';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'tizo-calendar-picker',
  templateUrl: './calendar-picker.page.html',
  styleUrls: ['./calendar-picker.page.scss'],
})
export class CalendarPickerPage implements OnInit {
  @Input() options: CalendarModalOptions;
  @Input() defaultDate;
  @Input() title = 'Chọn ngày ';
  @Input() format = 'DD/MM/YYYY';
  calendarCmpOptions: CalendarComponentOptions = {
    monthPickerFormat: MONTH_NAMES,
    monthFormat: 'MM/YYYY',
    weekdays: WEEK_DAYS_NAME
  };
  constructor(
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
    if (this.options) {
      this.calendarCmpOptions = { ...this.calendarCmpOptions, ...this.options };
    }
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }
  onChange(date) {
    console.log(date);
    console.log(date);
    this.modalCtrl.dismiss({
      date
    });
  }
}
