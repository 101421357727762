<div class="image-upload-field" (click)="takePhoto()">
  <div *ngIf="loading">
    <div>
      <ion-spinner name="lines-small"></ion-spinner>
    </div>
  </div>
  <div *ngIf="!loading">
    <div *ngIf="form.value" class="preview" [style.background-image]="'url('+ form.value + ')'  | safe:'style'"></div>
    <div *ngIf="!form.value">
      <ion-icon name="camera-reverse-outline"></ion-icon>
      <ion-text>{{ placeholder || name }}</ion-text>
    </div>
  </div>

</div>