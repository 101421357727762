<ion-item class="ion-no-padding" mode="md">
    <ion-label position="floating">{{name}}</ion-label>
    <ion-datetime
            [doneText]="'common.btn.select' | translate"
            [cancelText]="'common.btn.cancel' | translate"
            mode="ios"
            [displayFormat]="format"
            [value]="value"
            [formControl]="form"
            [required]="required">
    </ion-datetime>
    <ion-icon
            name="calendar-outline"
            slot="end"
            size="small">
    </ion-icon>
</ion-item>
