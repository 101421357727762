import { Component, OnInit, Input, forwardRef, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tizo-btn-select',
  templateUrl: './btn-select.component.html',
  styleUrls: ['./btn-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BtnSelectComponent),
      multi: true
    }
  ]
})
export class BtnSelectComponent implements OnInit, ControlValueAccessor {
  @Input() btns: Array<{ name: string, id: string }> = [];
  @Input() selectedId: string;
  @Output() selected: EventEmitter<string> = new EventEmitter();
  onChange: (id: string) => void;
  constructor() { }

  ngOnInit() { }
  onClick(id) {
    this.selectedId = id;
    if (this.onChange) {
      this.onChange(id);
    }
    this.selected.emit(id);
  }
  writeValue(val) {
    if (val) {
      this.selectedId = val;
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched() {

  }
}
