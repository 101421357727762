<ion-row class="recent-chats ion-align-items-center">
    <ion-col
        (click)="onClickRobotChat()"
        *ngIf="isClient"
        size="3"
        class="recent-chats__item">
        <ion-fab-button color="light">
            <img src="assets/icon/robot.svg">
        </ion-fab-button>
        <div class="recent-chats__text">
            {{'home.label.tizo_support' | translate}}
        </div>
    </ion-col>
    <ion-col
        (click)="onClickChatRoom(chatroom)"
        size="4"
        class="recent-chats__item"
        *ngFor="let chatroom  of chatRooms">
        <div class="ring" *ngIf="!chatroom.isBroadcast && chatroom.lastMessage && chatroom.lastMessageSeenBy && !chatroom.lastMessageSeenBy[userId]">
        </div>
        <ion-fab-button
            color="light">
            <ion-icon [name]="chatroom.isBroadcast ? 'people-outline':'person-outline'">
            </ion-icon>
        </ion-fab-button>
        <div class="recent-chats__text">
            {{isClient ? (chatroom.name | async) : chatroom.name}}
        </div>
    </ion-col>
</ion-row>
