
<ion-item
    mode="md"
    lines="none"
    [disabled]="isDisabled"
    class="ion-no-padding"
    [class.item-has-focus]="isFocus"
    [class.item-has-value]="form.value!=='' && form.value !== null">
    <ion-label position="floating">{{name}}</ion-label>
    <ion-input>
        <input
            [mask]="maskConfig"
            [formControl]="form"
            [readonly]="readonly"
            #input
            type="tel"
            class="input-phone sc-ion-input-ios"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"
            thousandSeparator="."
            [formControl]="form"
            [required]="required">
    </ion-input>
</ion-item>
