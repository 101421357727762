import { Component, Input, OnInit } from '@angular/core';
import { SellingProductCollection } from '@canalcircle/models';
import { BranchIntroComponent } from '@crossselling/modals/branch-intro/branch-intro.component';
import { ModalController, NavController } from '@ionic/angular';
import { ROUTES } from '@shared/contants';
import { isEmpty } from 'lodash';
import { APPLICATION_STORAGE_KEYS } from 'src/app/application/consts/storage-keys';

@Component({
  selector: 'tizo-product-collections',
  templateUrl: './product-collections.component.html',
  styleUrls: ['./product-collections.component.scss'],
})
export class ProductCollectionsComponent implements OnInit {
  @Input() collections: Array<SellingProductCollection> = [];
  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  goToDetail(collection: SellingProductCollection) {
    const route = isEmpty(collection.childrenCollectionIds)
      ? ROUTES.CROSS_SELLING_COLLECTION(collection.id)
      : ROUTES.CROSS_SELLING_MAIN_COLLECTION(collection.id)
    this.navCtrl.navigateForward(route);
  }


  async onClickCollection(collection: SellingProductCollection) {
    if (isEmpty(collection.childrenCollectionIds)) {
      this.goToDetail(collection);
    } else {
      const collectionId = collection.id;
      const viewedCollections = (JSON.parse(localStorage.getItem(APPLICATION_STORAGE_KEYS.viewedCollections)) || []) as Array<string>;
      const isViewed = viewedCollections.indexOf(collectionId) > -1;
      if (isViewed) {
        this.goToDetail(collection);
      } else {
        const modal = await this.modalCtrl.create({
          component: BranchIntroComponent,
          componentProps: {
            collectionId: collection.id
          }
        });
        modal.present();
        modal.onDidDismiss().then(data => {
          if (data.data?.done) {
            viewedCollections.push(collectionId);
            localStorage.setItem(APPLICATION_STORAGE_KEYS.viewedCollections, JSON.stringify(viewedCollections));
            this.goToDetail(collection);
          }
        })
      }
    }
  }
}
