import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

const FADE_IN = trigger('fadeIn', [
  transition('* => void', [
    animate(1000, style({ opacity: '0' }))
  ])
]);

@Component({
  selector: 'tizo-image-loading',
  templateUrl: './image-loading.component.html',
  styleUrls: ['./image-loading.component.scss'],
  animations: [FADE_IN]
})
export class ImageLoadingComponent implements OnInit {
  @Input() imageUrl: string;
  isLoading = true;

  constructor() {
  }

  ngOnInit() {
  }

  onImageLoad(event) {
    this.isLoading = false;
  }
}
