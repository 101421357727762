import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tizo-cross-selling-card',
  templateUrl: './cross-selling-card.component.html',
  styleUrls: ['./cross-selling-card.component.scss'],
})
export class CrossSellingCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
