
<ion-row class="product-collections">
  <ion-col size="6"  *ngFor="let item of collections">
    <ion-card class="product-collections__item ion-no-margin" button mode="ios" (click)="onClickCollection(item)">
      <div class="product-collections__img" [style.background-image]="'url(' + item.image +')'">
      </div>
      <div class="product-collections__title">
       {{item.name}}
      </div>
    </ion-card>
  </ion-col>
</ion-row>