import { Pipe, PipeTransform } from '@angular/core';
import { removeCountryCode } from '../utils/remove-country-code';

@Pipe({
  name: 'removeCountryCode'
})
export class RemoveCountryCodePipe implements PipeTransform {

  transform(phoneNumber: string): any {
    return removeCountryCode(phoneNumber);
  }
}
