import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  forwardRef,
  Output,
  EventEmitter,
  HostBinding
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'tizo-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true
    }
  ]
})
export class InputPhoneComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() readonly: boolean;

  @Input('disabled') set disabled(isDisabled) {
    isDisabled ? this.isDisabled = isDisabled : this.isDisabled = null;
  }

  @Input() placeholder = '';
  @Input() autoFocus: boolean;
  @Input() maxlength = 12;
  @Input() maskConfig = '0000 000 000';
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  @ViewChild('input') inputElement: ElementRef;
  @HostBinding('class.disabled') isDisabled;
  form = new FormControl('');

  constructor(private element: ElementRef) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 500);
    }
  }

  writeValue(val) {
    this.form.setValue(val);
  }

  registerOnChange(fn) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched() {

  }

  onBlur(event) {
    this.blur.emit();
  }

  onFocus(event) {
    this.focus.emit();
  }

  onPress(event) {
  }

  setDisabledState(isDisabled) {
    if (isDisabled) {
      this.isDisabled = isDisabled;
      // this.form.disable();
    }
  }
}
