import { Injectable } from '@angular/core';
import { UserService } from '@services/user.service';
import { ILocation } from '@shared/models';
import { TenantService } from '@services/tenant.service';
import { Client } from '@canalcircle/models';
import { first } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class RoutingOfficeService {
    constructor(
        private userService: UserService,
        private tenantService: TenantService,
    ) {
    }

    async getTenantWithOfficePathsAndClient(forFeature: 'loan' | 'saving', province: ILocation, district: ILocation, ward: ILocation): Promise<{
        [tenantId: string]: {
            officePaths: string[],
            client?: Client,
        }
    }> {
        const existedClients = await this.userService.getClientsByCurrentUser().toPromise();
        const sufficentTenantIds = forFeature === 'loan' ?
            await this.tenantService.getEnabledLoanTenantIds$().pipe(first()).toPromise() :
            await this.tenantService.getEnabledSavingTenantIds$().pipe(first()).toPromise();
        const sufficentClients = existedClients.filter(client => sufficentTenantIds.includes(client.tenantId));

        const result: {
            [tenantId: string]: {
                officePaths: string[],
                client?: Client,
            }
        } = {};

        // If there are existing clients
        if (sufficentClients.length > 0) {
            sufficentClients.forEach(client => {
                result[client.tenantId] = {
                    // to redirect to specific officer only
                    officePaths: (client.officePaths || []).length > 0 ?
                        [this.getLongestOfficePath(client.officePaths)] :
                        [],
                    client,
                };
            });
        } else {
            // If no existing clients, get tenants, offices from location
            const offices = await this.tenantService.findOfficesByLocation({
                provinceCode: province.code,
                districtCode: district.code,
                wardCode: ward.code,
            }).toPromise();

            offices
                .filter((office: any) => office.officePath)
                .forEach((office: any) => {
                    if (!result[office.tenantId]) {
                        result[office.tenantId] = {
                            officePaths: [],
                            client: null,
                        };
                    }
                    if (office.officePath) {
                        result[office.tenantId].officePaths.push(office.officePath);
                    }
                });
        }

        // Remove not enabled saving/loan tenants because findOfficesByLocation does not exclude it
        for (const tenantId of Object.keys(result)) {
            if (!sufficentTenantIds.includes(tenantId)) {
                delete result[tenantId];
            }
        }

        // If none matched, turn back to generic
        if (Object.keys(result).length === 0) {
            result.generic = {
                officePaths: [],
                client: null,
            };
        }

        return result;
    }

    public getLongestOfficePath(officePaths: string[]) {
        return [...officePaths]
            .map(path => path.replace('_unclaimed', ''))
            .sort((p1, p2) => p2.length - p1.length)[0];
    }
}
