import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject, combineLatest, of} from 'rxjs';
import {map, filter, mapTo, first, shareReplay, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@shared/translate/translate.service';
import {PermissionService} from '@vsla/services';
import {MixpanelService} from './mixpanel.service';
import {VSLAConfigService} from '@canalcircle/vsla-core-angular';
import {AngularFireAuth} from '@angular/fire/auth';
import {UserService} from './user.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Config, Tenant} from '@canalcircle/models';
import {StaticPage} from '@shared/models/staticPage';
import * as _ from 'lodash';
import {ISlide} from '@shared/models';
import {FineractService} from './fineract.service';
import firebase from 'firebase';

const PATH_LOCALS = 'assets/locals';
const PROVINCES_PATH = PATH_LOCALS + '/new_provinces.json';
const DISTRICTS_PATH = PATH_LOCALS + '/new_districts.json';
const WARDS_PATH = PATH_LOCALS + '/new_wards.json';
const ETHNICITIES_PATH = PATH_LOCALS + '/ethnicities.json';

const TIZO_CONFIG_COLLECTION = 'tizoConfigs';
const CROSS_SELLING_DOCUMENT_PATH = 'crossSelling';

export interface CommonLocation {
    id: string;
    name: string;
    code: string;
}

export interface TenantWithConfig extends Tenant {
    config: Config;
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    loadDoneAllConfig$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    userToken: string;

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private permissonService: PermissionService,
        private mixpanelService: MixpanelService,
        private afAuth: AngularFireAuth,
        private userService: UserService,
        private vslaConfigService: VSLAConfigService,
        private afStore: AngularFirestore,
        private fineractService: FineractService,
    ) {
    }

    getProvices(): Observable<CommonLocation[]> {
        return this.http.get<CommonLocation[]>(PROVINCES_PATH);
    }

    getDistrictsByProvice(provinceId: string): Observable<CommonLocation[]> {
        return this.http.get(DISTRICTS_PATH).pipe(
            map(districts => districts[provinceId] || [])
        );
    }

    getWardByDistricts(districtsId: string): Observable<CommonLocation[]> {
        return this.http.get(WARDS_PATH).pipe(
            map(wards => wards[districtsId] || [])
        );
    }

    getEthnicities() {
        return this.http.get<CommonLocation[]>(ETHNICITIES_PATH);
    }

    getStaticPage$(pageId): Observable<StaticPage> {
        return this.afStore.collection('tizoStaticPages').doc(pageId).get().pipe(
            map(d => d.data() as StaticPage)
        );
    }

    loadAllConfig() {
        this.fineractService.init();
        this.mixpanelService.init();
        this.userService.loadCurrentUser();
        this.translateService.loadTranslations();
        const config$ = combineLatest([
            this.afAuth.idToken,
            this.userService.getCurrentUser$(),
        ]).pipe(
            shareReplay(),
        );
        config$.subscribe(([token, user]) => {
            this.userToken = token;
            this.vslaConfigService.setToken(token);
            if (user) {
                this.vslaConfigService.setTenantIds(user.tenantIds || []);
            }
        });
        config$.pipe(first()).subscribe(() => {
            this.permissonService.initPermissions();
        });
    }

    isLoadDoneAllConfig$() {
        // const doneTranslate$ = this.translateService.done$.pipe(filter(done => done));
        const donePermissions$ = this.permissonService.done$.pipe(filter(done => done));
        const doneUser$ = this.userService.loadDoneUser$.pipe(filter(done => done));
        const doneFineract$ = this.fineractService.done$.pipe(filter(done => done !== 0));

        return combineLatest([donePermissions$, doneUser$, doneFineract$]).pipe(
            mapTo(true)
        );
    }

    getAvailableTenantsForCSR(): Observable<Array<Tenant>> {
        return this.getAvailableTenantIdsForCSR()
            .pipe(
                switchMap(tenantIds => {
                    return this.afStore.collection('tenants', ref => ref.where('id', 'in', tenantIds))
                        .get()
                        .pipe(map(res => res.docs.map(d => d.data() as Tenant)));
                })
            );
    }

    filterAvaliableTenantsCRSForUser(tenants: Array<any>,
        options: { skipCheckGeneric: boolean } = { skipCheckGeneric: false }): Observable<Array<Tenant>> {
        return this.getAvailableTenantIdsForCSR().pipe(
            map(availablesTenantIds => {
                return tenants.filter(tenant => {
                    return availablesTenantIds.find(tenantId => {
                        if (tenantId === 'generic' && !options.skipCheckGeneric) {
                            return true;
                        }
                        return tenantId === tenant.id;
                    });
                });
            })
        );
    }

    getAvailableTenantIdsForCSR(): Observable<Array<string>> {
        const exceptProps = ['id', 'updatedAt', 'createdAt', 'minOrderPrice'];
        return this.afStore.collection(TIZO_CONFIG_COLLECTION).doc(CROSS_SELLING_DOCUMENT_PATH).get().pipe(
            map(docData => docData.data()),
            map(configData => {
                if (!configData) {
                    return [];
                } else {
                    return Object.keys(configData).map(key => key).filter(item => configData[item] && !exceptProps.includes(item));
                }
            })
        );
    }

    getTizoCrossSellingConfig(): Observable<any> {
        return this.afStore.collection<any>(TIZO_CONFIG_COLLECTION).doc(CROSS_SELLING_DOCUMENT_PATH)
            .valueChanges();
    }

    getTenant(tenantId): Promise<Tenant> {
        return this.afStore.collection('tenants').doc(tenantId).get().pipe(
            map(d => d.data() as Tenant)
        ).toPromise();
    }

    getTenantsWithConfig(tenantIds: string[]): Observable<TenantWithConfig[]> {
        return combineLatest([
            this.afStore.collection('tenants', ref => ref
                .where('id', 'in', tenantIds)
            ).get(),
            this.afStore.collection('configs', ref => ref
                .where('tenantId', 'in', tenantIds)
            ).get(),
        ]).pipe(
            map(([tenants, configs]) => {
                const tenantsData = tenants.docs.map(d => d.data() as Tenant);
                const configsData = configs.docs.map(d => d.data() as Config);
                return tenantsData.map(tenant => ({
                    ...tenant,
                    config: configsData.find(c => c.id === tenant.id),
                }));
            })
        );
    }

    getTenantsConfig(tenantIds: string[], conditions: Array<[string, firebase.firestore.WhereFilterOp, any]> = []): Observable<Config[]> {
        return this.afStore
            .collection('configs', (ref) => {
                let docRef = ref.where('tenantId', 'in', tenantIds);
                for (const condition of conditions) {
                    docRef = docRef.where(condition[0], condition[1], condition[2]);
                }
                return docRef;
            })
            .get()
            .pipe(
                map((configs) => {
                    const configsData = configs.docs.map((d) => d.data() as Config);
                    return configsData;
                })
            );
    }

    getCurrentUserTenants(): Observable<TenantWithConfig[]> {
        return this.userService.getCurrentUser$().pipe(
            switchMap(user => {
                if (!user.tenantIds || user.tenantIds.length === 0) {
                    return of([]);
                }
                return this.getTenantsWithConfig(user.tenantIds);
            }),
        );
    }

    getHomeSlides() {
        return this.afStore.collection<ISlide>('tizoHomeSlides').valueChanges().pipe(
            map(slides => slides.filter(slide => slide.active)),
            map(slides => slides.sort((a, b) => a.order - b.order))
        );
    }

    getHotlines() {
        return this.afStore.collection(TIZO_CONFIG_COLLECTION).doc('hotlines').get().pipe(
            map(res => res.data().hotlines as Array<string>)
        );
    }

    getBankInfo(): Observable<IBankInfo[]> {
        return this.afStore.collection(TIZO_CONFIG_COLLECTION).doc('bankInfo').get().pipe(
            map(res => res.data().banks as Array<IBankInfo>),
            map(res => res.filter(i => i.isEnabled)),
        );
    }
}

export interface IBankInfo {
    accountName: string;
    accountNo: string;
    isEnabled: boolean;
    logo: string;
    name: string;
}