import { Component, OnInit, Input } from '@angular/core';
import { INewFeed } from '@shared/models';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'tizo-new-feed',
  templateUrl: './new-feed.component.html',
  styleUrls: ['./new-feed.component.scss'],
})
export class NewFeedComponent implements OnInit {
  @Input() newFeed: INewFeed;
  constructor(
    private platform: Platform,
  ) { }

  ngOnInit() { }


}
