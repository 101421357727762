<ion-row>
  <ion-col
    class="ion-no-padding ion-activatable"
    *ngFor="let btn of btns"
    (click)="onClick(btn.id)"
    [class.selected]="selectedId === btn.id">
    <ion-ripple-effect></ion-ripple-effect>
    {{btn.name}}
  </ion-col>
</ion-row>
