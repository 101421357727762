import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';
import _intersection from 'lodash/intersection';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { COLLECTION_NAMES } from '../../shared/contants/collections.const';
import { UserService } from './user.service';

export interface ITizoFeatureConfig {
    name: string;
    key: string;
    isEnabled: boolean;
    platforms: Platforms[];
    url: string;
    icon: string;
    order?: number;
    tabName?: string;
    active?: boolean;
}



const PREFIX_CARD_FEATURE = 'card_';
const PREFIX_CARD_GROUP_FEATURE = 'group_card_';
const PREFIX_TAB_FEATURE = 'tab_';
const PREFIX_MENU_FEATURE = 'menu_';

const BLACKLIST_PHONE_NUMBERS = [
    '+84333333333'
];
@Injectable({
    providedIn: 'root',
})
export class FeatureService {
    visibleMenuFeatures$ = new BehaviorSubject<ITizoFeatureConfig[]>([]);

    constructor(
        private afs: AngularFirestore,
        private platform: Platform,
        private userService: UserService,
    ) { }

    init() {
        this.getVisibleMenuFeatureConfigs().subscribe(features => {
            this.visibleMenuFeatures$.next(features);
        });
    }

    get documentPath() {
        return 'features';
        // const isOverdraftMode = localStorage.getItem(APPLICATION_STORAGE_KEYS.userMode) === USER_MODE.OVERDRAFT;
        // return isOverdraftMode ? 'features-v2' : 'features';
    }

    getFeatureConfigs() {
        return this.afs
            .collection(COLLECTION_NAMES.tizoConfigs)
            .doc<{ features: ITizoFeatureConfig[] }>(this.documentPath)
            .valueChanges()
            .pipe(map((res) => (res ? res.features : [])));
    }

    isBlackListUsers$() {
        return this.userService.getCurrentUser$().pipe(
            map(user => BLACKLIST_PHONE_NUMBERS.includes(user?.authInfo?.phoneNumber))
        );
    }

    getVisibleFeatureConfigs() {
        return this.getFeatureConfigs().pipe(
            map((features) =>
                features.filter(
                    (feature) =>
                        feature.isEnabled &&
                        _intersection(
                            this.platform.platforms(),
                            feature.platforms || []
                        ).length > 0
                )
            )
        );
    }

    getVisibleCardsFeatureConfigs() {
        return this.getVisibleFeatureConfigs().pipe(
            map((features) => {
                return features.filter((feature) =>
                    feature.key.trim().startsWith(PREFIX_CARD_FEATURE)
                )
            })
        );
    }

    getVisibleTabsFeatureConfigs() {
        return combineLatest([
            this.getVisibleFeatureConfigs(),
            this.isBlackListUsers$(),
        ]).pipe(
            map(([features, isBlackListUser]) => {
                return features.filter(feature => {
                    return feature.key.trim().startsWith(PREFIX_TAB_FEATURE);
                }).filter(feature => {
                    return !isBlackListUser || (
                        isBlackListUser && ['home', 'account'].includes(feature.tabName)
                    )
                });
            })
        );
    }

    getVisibleMenuFeatureConfigs() {
        return this.getVisibleFeatureConfigs().pipe(
            map((features) =>
                features.filter((feature) =>
                    feature.key.trim().startsWith(PREFIX_MENU_FEATURE)
                )
            )
        );
    }

    isEnableFeature(featureName: string) {
        return this.getVisibleFeatureConfigs().pipe(
            map((features) =>
                features.filter(
                    (feature) => feature.key.indexOf(featureName) > -1
                )
            ),
            map((features) => features.length > 0)
        );
    }

}
