import { Component, Input, OnInit } from '@angular/core';
import { SellingProductCollection } from '@canalcircle/models';
import { ProductApiService } from '@crossselling/apis/product-api.service';
import { IProductCollection } from '@crossselling/models';
import { ModalController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'tizo-branch-intro',
  templateUrl: './branch-intro.component.html',
  styleUrls: ['./branch-intro.component.scss'],
})
export class BranchIntroComponent implements OnInit {
  loading: boolean;
  collection: SellingProductCollection;
  @Input() collectionId: string;


  constructor(
    private modalCtrl: ModalController,
    private productApiService: ProductApiService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.productApiService.getProductCollectionOnFireStoreById(this.collectionId).pipe(
      finalize(() => this.loading = false)
    ).subscribe((res: any) => {
      this.collection = res as SellingProductCollection;
    })
  }

  dissmissModal() {
    this.modalCtrl.dismiss();
  }
  onClickViewMore() {
    this.modalCtrl.dismiss({ done: true });
  }
}
