<!-- <div class="form-section__field float-field-container currency-input" #fieldContainer> -->
<!-- <input #input [formControl]="form" [readonly]="readonly" [placeholder]="placeholder ||  name" -->
<!-- [mask]="form.value === 0 ? '' : currencyConfig.mask" [thousandSeparator]="currencyConfig.thousandSeparator" -->
<!-- [attr.disabled]="isDisabled" (blur)="onBlur($event)" (focus)="onFocus($event)" -->
<!-- (keypress)="onPress($event)" type="tel"> -->
<!-- <label>{{ name }}</label> -->
<!-- <div class="currency-input__suffix">{{currencyConfig.currencySuffix}}</div> -->
<!-- </div> -->
<ion-item
    mode="md"
    lines="none"
    [disabled]="isDisabled"
    class="ion-no-padding"
    [class.item-has-focus]="isFocus"
    [class.item-has-value]="form.value!=='' && form.value !== null">
    <ion-label position="floating">{{name}}</ion-label>
    <ion-input>
        <input
            [formControl]="form"
            [readonly]="readonly"
            #input
            type="tel"
            class="input-currency sc-ion-input-ios"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"
            mask="separator"
            [suffix]="suffix"
            thousandSeparator="."
            [required]="required"
            [formControl]="form">
    </ion-input>
</ion-item>
