import { Directive, Host, Self, Optional, AfterViewInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { IonInput, IonSelect, IonTextarea } from '@ionic/angular';
import { InputNumberComponent } from '@shared/input-number/components/input-number/input-number.component';
import { InputPhoneComponent } from '@shared/input-phone/components/input-phone/input-phone.component';
import { InputCurrencyComponent } from '@shared/input-currency/components/input-currency/input-currency.component';
import { DateInputComponent } from '@shared/date-time/components/date-input/date-input.component';
import { InputTimeComponent } from '@shared/date-time/components/input-time/input-time.component';

@Directive({
  selector: '[tizoInput]'
})
export class InputDirective implements AfterViewInit {
  @Output() focus: EventEmitter<void> = new EventEmitter();
  @Output() blur: EventEmitter<void> = new EventEmitter();

  constructor(
    public control: NgControl,
    @Optional() public ionInput: IonInput,
    @Optional() public ionSelect: IonSelect,
    @Optional() public ionTextArea: IonTextarea,
    @Optional() public tizoInputNumber: InputNumberComponent,
    @Optional() public tizoInputPhone: InputPhoneComponent,
    @Optional() public tizoInputCurrency: InputCurrencyComponent,
    @Optional() public tizoInputDate: DateInputComponent,
    @Optional() public tizoInputTime: InputTimeComponent,

    private element: ElementRef

  ) { }

  ngAfterViewInit() {
    const isTextArea = this.element.nativeElement.nodeName === 'ION-TEXTAREA';
    if (isTextArea) {
      this.element.nativeElement.classList.add('tizo-textarea');
    }
    this.element.nativeElement.classList.add('tizo-input');
    if (this.ionInput) {
      this.ionInput.ionFocus.subscribe(() => this.focus.emit());
      this.ionInput.ionBlur.subscribe(() => this.blur.emit());

    }
    if (this.tizoInputNumber) {
      this.tizoInputNumber.focus.subscribe(() => this.focus.emit());
      this.tizoInputNumber.blur.subscribe(() => this.blur.emit());

    }
    if (this.tizoInputPhone) {
      this.tizoInputPhone.focus.subscribe(() => this.focus.emit());
      this.tizoInputPhone.blur.subscribe(() => this.blur.emit());
    }
    if (this.tizoInputCurrency) {
      this.tizoInputCurrency.focus.subscribe(() => this.focus.emit());
      this.tizoInputCurrency.blur.subscribe(() => this.blur.emit());

    }
    if (this.ionSelect) {
      this.ionSelect.ionFocus.subscribe(() => this.focus.emit());
      this.ionSelect.ionBlur.subscribe(() => this.blur.emit());
    }
    if (this.ionTextArea) {
      this.ionTextArea.ionFocus.subscribe(() => this.focus.emit());
      this.ionTextArea.ionBlur.subscribe(() => this.blur.emit());
    }
    if (this.tizoInputDate) {
      this.tizoInputDate.focus.subscribe(() => this.focus.emit());
      this.tizoInputDate.blur.subscribe(() => this.blur.emit());
    }
    if (this.tizoInputTime) {
      this.tizoInputTime.focus.subscribe(() => this.focus.emit());
      this.tizoInputTime.blur.subscribe(() => this.blur.emit());
    }
  }
}
