<tizo-modal-search-base
        class="ion-page"
        [title]="'common.label.selectDistrict' | translate"
        [searchPlaceholder]="'common.label.inputDistrictName' | translate"
        (infinite)="onInfiniteScroll($event)"
        (search)="onSearch($event)">

    <ion-item
            [attr.data-cy]="'modal-search-district-' + d.id"
            *ngFor="let d of districts$ | async | slice : 0 :(page * perPage )"
            (click)="clickItem(d)"
            detail="false"
            button>
        {{d.name}}
    </ion-item>
</tizo-modal-search-base>
