import { Pipe, PipeTransform } from '@angular/core';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { getLocale } from 'src/app/i18n/utils';

@Pipe({
  name: 'fromNow'
})
export class FromNowPipe implements PipeTransform {
  locale: string;
  constructor() {
      this.locale = getLocale();
  }

  transform(value: Date | string | firebase.firestore.Timestamp): string {
    if (!value) {
      return '';
    }
    let dateInput = value;
    if (value instanceof firebase.firestore.Timestamp) {
      dateInput = value.toDate();
    }
    if (typeof value === 'string') {
      dateInput = new Date(value);
    }
    return moment(dateInput).locale(this.locale).fromNow();
  }
}
