<tizo-modal-primary [title]="title">
  <div class="modal-body">
    <div class="ion-text-center">
      <img [src]="image" alt="">
    </div>
    <div class="ion-text-center ion-margin-top">
      {{ message }}
    </div>
  </div>
  <ion-row class="modal-footer">
    <ion-button style="flex: 1" class="ion-margin-start" expand="block" color="gradient" (click)="dismissModal()">
     {{'common.btn.close' | translate}}
    </ion-button>
  </ion-row>
</tizo-modal-primary>