import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'common-welcome-component',
    templateUrl: './common-welcome-component.html',
    styleUrls: ['./common-welcome-component.scss']
})
export class CommonWelcomeComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() icon: string;
    @Input() description: string;
    @Input() btnText: string;
    @Output() btnClicked: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    onPressClick() {
        this.btnClicked.emit();
    }

}
