<div
  class="input-number"
  [class.disabled]="isDisabled"
  [ngClass]="isFocus ? 'has-focus':'not-focus'">
  <input
    #input
    [attr.disabled]="isDisabled"
    [max]="max"
    [min]="min"
    [formControl]="form"
    type="tel"
    class="input-number__input"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [mask]="showMask ? 'separator':null"
    [thousandSeparator]="'.'">
  <div class="input-number__suffix input-suffix">{{suffix}}</div>
</div>
