import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

export function formatTimestampString(timestamp, format = 'DD/MM/YYYY') {
    if (timestamp && timestamp.seconds >= 0 && timestamp.nanoseconds >= 0) {
        return moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format(format);
    }
    if (timestamp && timestamp._seconds >= 0 && timestamp._nanoseconds >= 0) {
        return moment(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000).format(format);
    }
    if (timestamp && timestamp.getNanoseconds && _.isFunction(timestamp.getNanoseconds)) {
        return moment(timestamp.getNanoseconds() / 1000).format(format);
    }
    if (_.isNumber(timestamp)) {
        return moment(timestamp / 1000000).format(format);
    }
}

@Pipe({ name: 'timestamp' })
export class TimestampPipe implements PipeTransform {
    constructor() {
    }

    transform(timestamp, format: string = 'DD/MM/YYYY') {
        return formatTimestampString(timestamp, format);
    }
}
