<ion-item
    mode="md"
    lines="none"
    class="ion-no-padding"
    #fieldContainer>
    <ion-label position="floating">{{name}}</ion-label>
  <ion-input
      [class]="inputClass"
      [disabled]="disabled"
      (ionFocus)="onFocus()"
      (ionBlur)="onBlur()"
      [readonly]="readonly"
      [type]="type"
      [formControl]="form"
      [required]="required"
      #input>
  </ion-input>
  <!-- <label>{{ name }}</label> -->
</ion-item>
