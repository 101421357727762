import { LANGUAGE_KEYS } from '@shared/calendar';
import { APPLICATION_STORAGE_KEYS } from 'src/app/application/consts/storage-keys';

export function setLanguage(lang) {
  return localStorage.setItem(APPLICATION_STORAGE_KEYS.language, lang);
}

export function getLanguage() {
  return localStorage.getItem(APPLICATION_STORAGE_KEYS.language);
}

export function getLocale() {
  const language = getLanguage() || 'vi';
  return LANGUAGE_KEYS[language];
}
