<div class="chat-card">
  <ion-row class="ion-align-items-center">
    <ion-col size="8">
      <div
        *ngIf="user">
        <div style="color: #414141; font-size: 12px;line-height: 1.5;">
<!--          {{isSupporter ? 'Bắt đầu ngày làm việc và kết nối với khách hàng!' : 'Cùng trò chuyện với các chuyên gia về tài chính, giúp giải đáp thắc mắc về thủ tục, hồ sơ và tư vấn giải pháp tài chính tốt nhất'}}-->
          {{(isSupporter ? 'home.label.chat_supporter_slogan' : 'home.label.chat_user_slogan') | translate}}
        </div>
      </div>
      <ion-button style="margin-left: -16px;" fill="clear">{{'home.btn.chat_now' | translate}} ></ion-button>
    </ion-col>
    <ion-col size="4">
      <img src="assets/icon/robot.svg">
    </ion-col>
  </ion-row>
  <!-- <ion-row
    class="ion-align-items-center ion-justify-content-center">
    <ion-col size="10">
      <tizo-btn-primary shape="round">
        Bắt đầu
      </tizo-btn-primary>
    </ion-col>
  </ion-row> -->
</div>
