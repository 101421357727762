import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { MultiSelectPopup } from './multi-select-popup/multi-select-popup.component';

export interface ISelectOption {
  name: string;
  id: string;
  value: string;
}

@Component({
  selector: 'tizo-multiple-select-field',
  templateUrl: './multiple-select-field.component.html',
  styleUrls: ['./multiple-select-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleSelectFieldComponent),
      multi: true
    }
  ]
})
export class MultipleSelectFieldComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input('disabled') isDisabled: boolean;
  @Input() name: string;
  @Input() options: ISelectOption[] = [];
  // @Input() compareFnc: (item, options: ISelectOption[]) => boolean;
  selectedName: string;
  displayValue: string;
  onTouch: () => void;
  form: FormControl = new FormControl('');

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  async showOptions() {
    if (this.isDisabled) { return; }
    const modal = await this.modalCtrl.create({
      component: MultiSelectPopup,
      componentProps: {
        title: this.name,
        // @TODO: not good?
        options: this.options.map(opt => {
          let isChecked = false;
          (this.form.value || []).forEach(o => {
            if (o.id === opt.id) {
              isChecked = true;
            }
          });
          return {
            ...opt,
            isChecked
          };
        }),
      },
      cssClass: 'modal-transparent',
    });

    await modal.present();
    modal.onWillDismiss().then(async data => {
      if (data.data) {
        this.form.setValue(data.data);
      }
    });
  }

  writeValue(val: ISelectOption[]) {
    this.setDisplayValue(val);
    this.form.setValue(val);
  }

  registerOnChange(fn) {
    this.form.valueChanges.subscribe((val: ISelectOption[]) => {
      this.setDisplayValue(val);
      fn(val);
    });
  }

  private setDisplayValue(val: ISelectOption[]) {
    this.displayValue = val.map(opt => opt.name).join(', ');
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = !!isDisabled;
  }

}
