import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output, ElementRef, Inject, Injector } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KEY_CODES } from '../../key-code';
import { ICurrencyInput } from '../../models';
import { CURRENCY_CONFIG } from '../../token';

@Component({
  selector: 'app-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputCurrencyComponent), multi: true }
  ]
})
export class InputCurrencyComponent implements OnInit, ControlValueAccessor {
  @Input() config: Partial<ICurrencyInput> = {};
  @Input() min = 0;
  @Input() readonly: boolean;
  @Output() change: EventEmitter<number> = new EventEmitter();
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Input('disabled') set disabled(isDisabled) {
    this.isDisabled = isDisabled;
  }
  @HostBinding('class.disabled') isDisabled;
  suffix: string;
  formCtrl: FormControl = new FormControl('');
  constructor(private elemment: ElementRef, @Inject(CURRENCY_CONFIG) public currencyConfig: ICurrencyInput) {
  }

  ngOnInit() {
    if (this.config) {
      this.currencyConfig = { ...this.currencyConfig, ...this.config };
    }
    if (this.isDisabled) {
      this.formCtrl.disable();
    }
    this.suffix = this.currencyConfig.rounding ? '.000' : '';
  }

  writeValue(val) {
    if (this.currencyConfig.rounding) {
      const formValue = val === 0 ? 0 : (+val / this.currencyConfig.unit || '');
      this.formCtrl.setValue(formValue);
    } else {
      this.formCtrl.setValue(val);
    }
  }

  registerOnChange(fn) {
    this.formCtrl.valueChanges.subscribe(val => {
      if (this.currencyConfig.rounding) {
        const formValue = +val * this.currencyConfig.unit;
        fn(formValue);
        this.change.emit(formValue);
      } else {
        fn(val);
        this.change.emit(val);
      }
    });
  }

  registerOnTouched() {

  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.isDisabled = isDisabled;
    }
  }

  onFocus() {
    this.focus.emit();
  }

  onBlur() {
    this.blur.emit();
  }

  onPress(event) {
    const canNotPress: boolean = (event.keyCode === KEY_CODES.minus && !this.currencyConfig.allowNegative);
    if (canNotPress) {
      event.preventDefault();
    }
  }
}
