<div class="referer-card">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <ion-item lines="none">
      <ion-input type="tel" formControlName="referrerPhoneNumber" [placeholder]="'Số điện thoại người giới thiệu'"></ion-input>
      <tizo-btn-primary
        slot="end"
        (click)="onSubmit()"
      >
        <ion-icon name="arrow-forward-outline"></ion-icon>
      </tizo-btn-primary>
    </ion-item>
  </form>
</div>