import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environments/environment';
import {IProductCollection, IProductTag, ProductCollectionType} from '../models';
import {AngularFirestore} from '@angular/fire/firestore';
import {catchError, debounceTime, map, switchMap, take} from 'rxjs/operators';
import * as _ from 'lodash';
import {UserService} from '@services/user.service';
import {
    IShopifyProduct,
    IShopifyProductWithLoanProduct,
    SellingProductCollection,
    SellingProductCollectionType,
    SellingProductCollectionCondition
} from '@canalcircle/models';
import {combineLatest, Observable, of} from 'rxjs';
import {getLanguage} from '../../i18n/utils';
import algoliasearch from 'algoliasearch';

const FIRESTORE_PRODUCT_COLLECTION = 'sellingProductCollections';
const SELLING_PRODUCT = 'sellingProducts';
// const algoliasearch = require('algoliasearch');
// import *  from 'algoliasearch' as algoliasearch;
// @TODO move to other file
export interface IShopifyPaginatedResponse<T> {
    nextPageInfo: string;
    data: T;
}

export interface IProductListResponse extends IShopifyPaginatedResponse<{ productListings: IShopifyProductWithLoanProduct[] }> {
}

@Injectable({
    providedIn: 'root'
})
export class ProductApiService {

    constructor(
        private http: HttpClient,
        private afStore: AngularFirestore,
        private userService: UserService,
    ) {

    }


    // getProductCollection(collectionId: string) {
    //     const url = `${environment.shopify.adminUrl}/collections/${collectionId}`;
    //     return this.http.get(url);
    // }

    async getProductTagByIds(ids: Array<string>) {
        const products = (await this.afStore
            .collection<IProductTag>('productTags', ref => ref.where('id', 'in', ids))
            .get()
            .toPromise()).docs.map(d => d.data() as IProductTag);
        return _.orderBy(products, ['order', 'asc']);
    }

    searchProductsInCollection(params: {
        query: string,
        limit?: string,
        page_info?: string
    }) {
        const url = `${environment.shopify.adminUrl}/products/search`;
        return this.http.get<IProductListResponse>(url, {
            params: {
                ...params,
                no_loan: this.userService.isOverDraftMode() ? '1' : null,
            }
        }).pipe(
            map(res => ({
                nextPageInfo: res.nextPageInfo,
                data: res.data?.productListings || [],
            })),
        );
    }

    getProductCollectionsOnFirestore(types?: ProductCollectionType[], limit?: number) {
        const currentUser = this.userService.currentUser;
        const tenantIds = currentUser?.tenantIds;
        return this.afStore.collection<SellingProductCollection>(FIRESTORE_PRODUCT_COLLECTION, ref => {
            let docRef: any = ref;
            if (types) {
                docRef = docRef.where('position', 'in', types);
            }
            if (limit) {
                docRef = docRef.limit(limit);
            }
            return docRef;
        }).valueChanges()
            .pipe(
                // map(collections => collections
                //     .filter(collection => collection.actived)
                //     .filter(item => (!_.isEmpty(tenantIds) && !_.isEmpty(item.tenantIds))
                //         ? _.intersection(tenantIds, item.tenantIds).length > 0
                //         : true)),
                map(collections => collections.filter(item => item.actived)),
                map(items => _.orderBy(items, ['order', 'asc'])),
            );
    }

    getProductCollectionOnFireStoreById(id: string) {
        return this.afStore.collection(FIRESTORE_PRODUCT_COLLECTION).doc<IProductCollection>(id)
            .get()
            .pipe(
                map(action => action.data() as IProductCollection)
            );
    }

    getProductCollectionOnFireStoreByIds(ids: string[]) {
        if (ids.length === 0) {
            return of([]);
        }
        const idsChunks = _.chunk(ids, 10);

        return combineLatest(
            idsChunks.map(ids => this
                .afStore
                .collection(FIRESTORE_PRODUCT_COLLECTION, ref => ref.where('id', 'in', ids))
                .get()
                .pipe(
                    map(action => action.docs.map(d => d.data() as SellingProductCollection))
                )
            )
        ).pipe(
            map(collections => collections.flat()),
            map(collections => collections.filter(item => item.actived)),
        );
    }

    getParentCollection(childId: string) {
        return this
            .afStore
            .collection(FIRESTORE_PRODUCT_COLLECTION, ref => ref.where('childrenCollectionIds', 'array-contains', childId))
            .valueChanges();
            // .pipe(
            //     map(action => action.docs.map(d => d.data()))
            // );
    }

    // getProductsByCollectionId(collectionId: string, limit = '24', pageInfo?: string): Observable<{ nextPageInfo: string, data: IShopifyProductWithLoanProduct[] }> {
    //     const lang = getLanguage();
    //     const url = `${environment.shopify.adminUrl}/products`;
    //     return this.http.get<IProductListResponse>(url, {
    //         params: {
    //             lang,
    //             collection_id: collectionId,
    //             limit: limit || '24',
    //             no_content: '1',
    //             no_loan: this.userService.isOverDraftMode() ? '1' : null,
    //             ...(pageInfo ? {page_info: pageInfo} : {}),
    //         }
    //     }).pipe(
    //         map(res => ({
    //             nextPageInfo: res.nextPageInfo,
    //             data: res.data?.productListings || [],
    //         })),
    //     );
    // }

    getProductsByCollection(collection: SellingProductCollection, limit = '24', pageInfo?: string): Observable<any> {
        const collectionType = collection.type;
        const conditions = collection.conditions;
        if (collectionType === SellingProductCollectionType.MANUAL) {
            const productIds = collection.sellingProductIds;
            return this.getSellingProducts$(productIds);
        } else if (!_.isEmpty(conditions) && collectionType === SellingProductCollectionType.AUTOMATED) {
            return this.getProductsByCondition(conditions);
        }
        return of([]);
    }


    async productSearchCollectionAll(pageSize = 10, pageNum = 0, queryStr = '', collection, currentSort, method?) {
        const orgQuerySetting: any = {
            // "getRankingInfo": true,
            analytics: false,
            enableABTest: false,
            hitsPerPage: 0,
            attributesToRetrieve: '*',
            attributesToSnippet: [
                'title',
                'title:20'
            ],
            snippetEllipsisText: '…',
            responseFields: '*',
            // explain: '*',
            page: 0,
            maxValuesPerFacet: 100,
            facets: [
                // '*'
            ],
            facetFilters: []
        };

        let searchValue = queryStr || '';

        const client = algoliasearch(environment.ALGOLIA_APP_ID, environment.ALGOLIA_SEARCH_KEY);

        // const queryParameters: Record<string, any> = {};
        // const requestOptions = {
        //     headers: {},
        //     // queryParameters :
        // };
        orgQuerySetting.hitsPerPage = pageSize;
        orgQuerySetting.page = pageNum;
        let productIndex = client.initIndex('sellingProducts');
        if (currentSort) {
            productIndex = client.initIndex(currentSort.replica);
        }
        if (method && method === 'TAG') {
            orgQuerySetting.facets.push('tags');
            orgQuerySetting.facetFilters.push([
                `tags:${this.removeAccents(queryStr)}`
            ]);
            searchValue = '';
        }

        if (collection?.conditions) {
            const conditions = collection?.conditions;
            if (conditions.productTypes?.length) {
                orgQuerySetting.facets.push('type');
                orgQuerySetting.facetFilters.push([
                    `type:${conditions.productTypes.join()}`
                ]);
            }
            if (conditions.productVendors?.length) {
                orgQuerySetting.facets.push('vendor');
                orgQuerySetting.facetFilters.push([
                    `vendor:${conditions.productVendors.join()}`
                ]);
            }
            if (conditions.tags?.length) {
                orgQuerySetting.facets.push('tags');
                orgQuerySetting.facetFilters.push([
                    `tags:${conditions.tags.join()}`
                ]);
            }
        }

        return productIndex.search(searchValue, orgQuerySetting);
        // const searchResponse =  await productIndex.search(searchValue, orgQuerySetting)
        // const productIds = searchResponse.hits.map((item: any )=> item.id);
        // const productsData = await this.getSellingProducts$(productIds).pipe(take(1)).toPromise();
        // return{
        //     ...searchResponse,
        //     hits: productsData.data
        // };
    }

    getProductsByCondition(conditions: SellingProductCollectionCondition) {
        const {tags, productTypes, productVendors} = conditions;
        return this.afStore
            .collection(SELLING_PRODUCT, (ref: any) => {
                if (!_.isEmpty(tags)) {
                    ref = ref.where('tags', 'array-contains-any', tags);
                }
                if (!_.isEmpty(productTypes)) {
                    ref = ref.where('type', 'in', productTypes);
                }
                if (!_.isEmpty(productVendors)) {
                    ref = ref.where('vendor', 'in', productVendors);
                }
                return ref;
            })
            .valueChanges()
            .pipe(map((data: any[]) => ({data})));
    }

    getProductsByIds(ids: string[], limit = '24', pageInfo?: string): Observable<{ data: IShopifyProductWithLoanProduct[] }> {
        // const lang = getLanguage();
        // const url = `${environment.shopify.adminUrl}/products`;
        // return this.http.get<IProductListResponse>(url, {
        //     params: {
        //         lang,
        //         ids,
        //         limit: limit || '24',
        //         no_content: '1',
        //         no_loan: '1',
        //         ...(pageInfo ? {page_info: pageInfo} : {}),
        //     }
        // }).pipe(
        //     map(res => ({
        //         nextPageInfo: res.nextPageInfo,
        //         data: res.data?.productListings,
        //     })),
        // );
        return this.getSellingProducts$(ids);
    }

    // getProductIdsByCollectionId(collectionId: string, limit?: string) {
    //     const url = `${environment.shopify.adminUrl}/collections/${collectionId}/products/ids`;
    //     return this.http.get<{ data: { productIds: string[] } }>(url, {
    //         params: {
    //             limit: limit || '250'
    //         }
    //     }).pipe(
    //         map(res => res.data.productIds),
    //     );
    // }

    getProductById(productId: string) {
        return this.afStore.collection<any>(SELLING_PRODUCT).doc<any>(productId).valueChanges();
        // const url = `${environment.shopify.adminUrl}/products/${productId}`;
        // return this.http.get<{ data: { productListing: IShopifyProductWithLoanProduct } }>(url, {params: {
        //     no_loan: this.userService.isOverDraftMode() ? '1' : null,
        // }}).pipe(
        //     map(res => res.data.productListing)
        // );
    }

    getSellingProductById(id: string) {
        return this.afStore.collection<any>(SELLING_PRODUCT).doc<any>(id).valueChanges();
    }

    getSellingProducts$(ids: string[]) {
        if (ids.length === 0) {
            return of({data: []});
        }
        const idsChunks = _.chunk(ids, 10);

        return combineLatest(
            idsChunks.map(ids => this
                .afStore
                .collection(SELLING_PRODUCT, ref => ref.where('id', 'in', ids))
                .valueChanges()
            )
        )
            .pipe(
                map(docs => ({data: docs.flat() as IShopifyProductWithLoanProduct[]})),
            );
    }

    getHotKeywords() {
        const url = `${environment.shopify.adminUrl}/hot-keywords`;
        const lang = getLanguage();
        return this.http.get<{ data: Array<string> }>(url).pipe(
            map(res => res.data),
            catchError(e => of(
                lang === 'vi'
                    ? ['Laptop', 'Máy tính', 'Tivi', 'Tủ lạnh', 'Nồi cơm', 'Điều hoà']
                    : ['Laptop', 'Computer', 'Television', 'Refrigerator', 'Rice cooker', 'Air conditioner']
            ))
        );
    }

    removeAccents(str) {
        const AccentsMap = [
          "aàảãáạăằẳẵắặâầẩẫấậ",
          "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
          "dđ", "DĐ",
          "eèẻẽéẹêềểễếệ",
          "EÈẺẼÉẸÊỀỂỄẾỆ",
          "iìỉĩíị",
          "IÌỈĨÍỊ",
          "oòỏõóọôồổỗốộơờởỡớợ",
          "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
          "uùủũúụưừửữứự",
          "UÙỦŨÚỤƯỪỬỮỨỰ",
          "yỳỷỹýỵ",
          "YỲỶỸÝỴ"
        ];
        for (let i=0; i < AccentsMap.length; i++) {
          const re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
          const char = AccentsMap[i][0];
          str = str.replace(re, char);
        }
        return str.replace(/ /g, "").toLocaleLowerCase().split('-').join('');
      }
}
