import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionSheetController } from '@ionic/angular';

export interface ISelectOption {
  name: string;
  id: string;
  value: string;
}

@Component({
  selector: 'tizo-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectFieldComponent),
      multi: true
    }
  ]
})
export class SelectFieldComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() name: string;
  @Input() options: ISelectOption[] = [];
  selectedName: string;
  @Input() disabled;
  @Input() required: boolean;

  onTouch: () => void;
  form: FormControl = new FormControl('');

  constructor(
    private actionSheetController: ActionSheetController
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  async showOptions() {
    if (this.disabled) {
      return;
    }
    const buttons = this.options.map(option => {
      const btn: ActionSheetButton = {
        cssClass: option === this.form.value ? 'action-sheet-selected' : '',
        text: option.name,
        handler: () => {
          this.form.setValue(option);
        }
      };

      return btn;
    });
    const actionSheet = await this.actionSheetController.create({
      header: this.placeholder || this.name,
      buttons,
    });
    await actionSheet.present();
  }

  writeValue(val: ISelectOption) {
    this.form.setValue(val);
  }

  registerOnChange(fn) {
    this.form.valueChanges.subscribe(val => {
      fn(val);
    });
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = !!isDisabled;
  }

}
