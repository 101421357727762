import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tizo-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit {
  @Input() noHeader: boolean;

  constructor() { }

  ngOnInit() {}

}
