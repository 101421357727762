import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IChatroom } from '@shared/models/IChatroom';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'tizo-recent-chats',
    templateUrl: './recent-chats.component.html',
    styleUrls: ['./recent-chats.component.scss'],
})
export class RecentChatsComponent implements OnInit, OnChanges {
    @Input() chatRooms: Array<IChatroom & { name: Observable<string> | string }>;
    @Input() isClient: boolean;
    @Output() clickChat = new EventEmitter<IChatroom>();
    @Output() clickRobotChat = new EventEmitter<void>();
    @Input() userId: string;

    constructor(
        private userService: UserService
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.chatRooms) {
            // Currently,map room name to officer name if is client view
            if (this.isClient) {
                this.chatRooms.forEach(chatRoom => {
                    chatRoom.name = this.userService.getUserById(chatRoom.officerUserId).pipe(
                        map(res => {
                            return res.authInfo?.displayName || res.authInfo?.email;
                        })
                    );
                });
            }
        }
    }

    onClickChatRoom(chatRoom: IChatroom) {
        this.clickChat.emit(chatRoom);
    }

    onClickRobotChat() {
        this.clickRobotChat.emit();
    }

    trackByFn = (idx: null, item: IChatroom) => item.id;
}
