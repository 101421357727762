import { Component, OnInit, ContentChild, AfterViewInit, OnDestroy, OnChanges, Input, HostBinding } from '@angular/core';
import { InputDirective } from '../../directives/input.directive';
import { trigger, transition, animate, style } from '@angular/animations';
const fade = trigger('fade', [
  transition(':leave', [
    animate('200ms', style({ opacity: 0, transform: 'translateY(-100%)' }))
  ]),
  transition(':enter', [
    style({ opacity: 0 }),
    animate('500ms', style({ opacity: 1, transform: 'translateX(0)' }))
  ])
]);
@Component({
  selector: 'tizo-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  animations: [fade]
})
export class FormFieldComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @HostBinding('class.has-focus') hasFocus: boolean;
  @HostBinding('class.has-error') hasError: boolean;

  @ContentChild(InputDirective) inputCmp: InputDirective;
  @Input() showErrorsWhen: Array<boolean> = [];
  canShowError = true;
  constructor() { }
  ngOnDestroy() {

  }
  ngOnInit() {
  }
  ngOnChanges() {
    if (this.showErrorsWhen) {
      if (this.showErrorsWhen.length === 0) {
        this.canShowError = true;
      } else {
        this.canShowError = this.showErrorsWhen.every(condition => condition);
      }
      if (this.inputCmp) {
        this.hasError = this.inputCmp.control.invalid && this.canShowError;
      }
    }
  }
  ngAfterViewInit() {
    this.inputCmp.focus.subscribe(() => {
      this.hasFocus = true;
    });
    this.inputCmp.blur.subscribe(() => {
      this.hasFocus = false;
    });
    this.inputCmp.control.statusChanges.subscribe(val => {
      const hasError = this.inputCmp.control.invalid && this.canShowError;
      if (hasError) {
        this.hasError = true;
      } else {
        this.hasError = false;
      }
    });
    // this.inputCmp.blur.subscribe(() => {

    // });
  }
}
