import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  forwardRef,
  Output,
  EventEmitter,
  HostBinding, QueryList, ViewChildren, AfterViewInit
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'tizo-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFieldComponent),
      multi: true
    }
  ]
})
export class PhoneFieldComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit {
  @Input('disabled') set disabled(isDisabled) {
    isDisabled ? this.isDisabled = isDisabled : this.isDisabled = null;
  }
  @Input() readonly: boolean;
  @Input() name = '';
  @Input() placeholder = '';
  @Input() autoFocus: boolean;
  @Input() maxlength = 12;
  @Input() maskConfig = '0000 000 000';
  @Input() required: boolean;
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  @ViewChild('input') inputElement: ElementRef;
  // @ViewChildren('fieldContainer') fieldContainers: QueryList<ElementRef<HTMLInputElement>>;
  @HostBinding('class.disabled') isDisabled;
  form = new FormControl('');
  isFocus = false;
  constructor(private element: ElementRef) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.autoFocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      }, 500);
    }
  }

  ngAfterViewInit() {
    // this.fieldContainers.forEach(container => {
    //   // tslint:disable-next-line:only-arrow-functions
    //   container.nativeElement.addEventListener('click', function (event) {
    //     event.stopPropagation();
    //     const inputElement = this.querySelector('input');
    //     if (inputElement) {
    //       inputElement.focus();
    //     }
    //   }, true);
    // });
  }

  writeValue(val) {
    this.form.setValue(val);
  }

  registerOnChange(fn) {
    this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched() {

  }

  onBlur(event) {
    this.isFocus = false;
    this.blur.emit();
  }

  onFocus(event) {
    this.isFocus = true;
    this.focus.emit();
  }

  setDisabledState(isDisabled) {
    if (isDisabled) {
      this.isDisabled = isDisabled;
      // this.form.disable();
    }
  }
}
