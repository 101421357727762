import { Pipe, PipeTransform } from '@angular/core';
import { MaskPipe } from 'ngx-mask';
import { removeCountryCode } from '../utils/remove-country-code';
import { normalizePhone } from '../utils/phone';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  constructor(private maskPipe: MaskPipe) {

  }
  transform(value: any): any {
    if (!value || value.length === 1) {
      return '';
    }
    value = normalizePhone(value);
    return this.maskPipe.transform(value, '0000 000 000');
  }
}
