<ion-card class="finance-banner-card" @fadeIn (click)="goToLoanPage()"
    *ngIf="!(latestRequest) && !isLoadingLastestRequest  && !isLoadingFinance && isShowLoanCard && isShowSavingCard">
    <ion-row>
        <ion-col size="8">
            <div class="font-weight-bold">
                <ion-text color="light">
                    {{'home.label.finance_sologan' | translate}}
                </ion-text>
            </div>
            <ion-button size="small" class="ion-margin-top" shape="round" mode="ios" color="warning" translate>
                common.label.showMore
            </ion-button>
        </ion-col>
        <ion-col size="4">
            <img src="assets/icon/finance.png">
        </ion-col>
    </ion-row>
</ion-card>

<ion-row *ngIf="!isLoadingFinance" @fadeIn>
    <ion-col style="padding-left: 0" *ngIf="loanAccounts.length > 0 && isShowLoanCard">
        <ion-card class="finance-card" mode="ios" button (click)="goToLoanPage()">
            <div style="display: flex;"
                [ngStyle]="{flexDirection:(savingAccounts.length === 0 || !isShowSavingCard) ? 'row':'column'}">
                <ion-row class="ion-align-items-center" style="flex: 1;">
                    <div class="finance-card__icon-wrapper finance-card__loan-wrapper">
                        <ion-icon src="assets/icon/home/vay-von-white.svg" color="light"></ion-icon>
                    </div>
                    <ion-text class="font-mini" style="flex: 1;margin-left:5px">{{loanAccounts.length}} {{'home.label.loans_amount' | translate}}</ion-text>
                </ion-row>
                <div>
                    <ion-text class="font-mini">{{'home.label.total_outstanding' | translate}}</ion-text>
                    <div class="font-weight-bold">
                        {{totalLoanAmount | currencyFormat}}
                    </div>
                </div>
            </div>

        </ion-card>
    </ion-col>
    <ion-col style="padding-right: 0" *ngIf="savingAccounts.length > 0 && isShowSavingCard">
        <ion-card class="finance-card" mode="ios" button (click)="goToSavingPage()">
            <div style="display: flex;"
                [ngStyle]="{flexDirection:(loanAccounts.length === 0 || !isShowLoanCard) ? 'row':'column'}">
                <ion-row class="ion-align-items-center" style="flex: 1;">
                    <div class="finance-card__icon-wrapper finance-card__saving-wrapper">
                        <ion-icon src="assets/icon/home/tiet-kiem-white.svg" color="light"></ion-icon>
                    </div>
                    <ion-text class="font-mini" style="flex: 1;margin-left:5px">{{savingAccounts.length}} {{'home.label.saving_amounts' | translate}}</ion-text>
                </ion-row>
                <div>
                    <ion-text class="font-mini">{{'home.label.total_saving_amount' | translate}}</ion-text>
                    <div class="font-weight-bold">
                        {{totalSavingAmount | currencyFormat}}
                    </div>
                </div>
            </div>
        </ion-card>
    </ion-col>
</ion-row>

<!-- Latest request -->
<ion-card @fadeIn class="recent-request" mode="ios" *ngIf="latestRequest" (click)="onClickLatestRequest()">
    <ion-item lines="none" button>
        <ion-icon size="small" src="assets/icon/send.svg" slot="start"></ion-icon>
        <ion-label>
            <div class="font-small">{{latestRequest.title}}</div>
            <div class="font-mini" *ngIf="latestRequest.data?.amount">
                <ion-text>
                    {{'home.label.request_amounts' | translate}}: {{latestRequest.data.amount | currencyFormat}}
                </ion-text>
            </div>
            <div class="font-mini">
                <ion-text [color]="color">
                    {{'home.label.request_status' | translate}}: {{ 'loan_saving_request.status.' + latestRequest.status  | translate }}
                </ion-text>
            </div>
        </ion-label>
    </ion-item>
</ion-card>
