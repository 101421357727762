<tizo-dialog-primary [title]="title">
    <div class="dialog-body">
        <ion-list>
            <ion-item *ngFor="let option of options">
              <ion-label>{{option.name}}</ion-label>
              <ion-checkbox slot="end" [(ngModel)]="option.isChecked"></ion-checkbox>
            </ion-item>
          </ion-list>

    </div>
    <div class="dialog-footer">
        <tizo-btn-primary class="mr-5" color="dark" fill="clear" (btnClick)="modalCtrl.dismiss()">
            {{ 'common.btn.cancel' | translate }}
        </tizo-btn-primary>
        <tizo-btn-primary (btnClick)="onSubmit()" color="primary" fill="clear">
            {{ "common.btn.select" | translate }}
        </tizo-btn-primary>
    </div>
</tizo-dialog-primary>

