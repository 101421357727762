<div class="ion-margin-horizontal h-100">
    <div class="wrapper">
        <h1 class="title">
            {{ title }}!
        </h1>
        <common-ion-icon [icon]="icon" fontSize="230px"></common-ion-icon>
        <h1 class="message">
            {{ description }}
        </h1>
        <div *ngIf="btnText" class="w-100">
            <tizo-btn-primary
                    (btnClick)="onPressClick()"
                    color="gradient"
                    data-cy="common-welcome-btn"
                    expand="block"
                    size="large"
            >
                {{ btnText }}
            </tizo-btn-primary>
        </div>
    </div>
</div>
