import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IonicCoreService } from '@services/ionic-core.service';
import { UserService } from '@services/user.service';
import { ROUTES } from '@shared/contants';
import { CommonConfirmModalComponent } from '@shared/components/common-confirm-modal/common-confirm-modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'tizo-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent implements OnInit {
  @Input() data: any;
  @Input() crossSellingPoints: any;
  isSeller$: Observable<boolean>;

  constructor(
    private navCtrl: NavController,
    private userService: UserService,
    private ionicCoreService: IonicCoreService,
    private translateService: TranslateService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.isSeller$ = this.userService.isSeller$();
    
  }

  async onRegisterSeller() {
    const modal = await this.modalCtrl.create({
      cssClass: 'modal-transparent',
      component: CommonConfirmModalComponent,
      componentProps: {
        title: this.translateService.instant('home.label.confirm'),
        content: this.translateService.instant('crossSelling.message.register_seller_confirm')
      }
    });
    modal.present();
    modal.onDidDismiss().then(data => {
      if (data?.data?.confirmed) {
        this.registerSeller();
      }
    });
  }

  async registerSeller() {
    await this.ionicCoreService.showLoading();
    this.userService.registerSeller().then(() => {
      this.ionicCoreService.showToastSuccess({
        message: this.translateService.instant('crossSelling.message.register_seller_success'),
      });
    }).catch(error => {
      this.ionicCoreService.showToastWarning({
        message: this.translateService.instant(error.message)
      });
      console.error(error);
    }).finally(() => {
      this.ionicCoreService.hideLoading();
    });
  }

  goToOrders() {
    this.navCtrl.navigateForward(ROUTES.CROSS_SELLING_ORDERS);
  }

  goToCommisions() {
    this.navCtrl.navigateForward(ROUTES.LOYALTY_LIST);
  }
}
