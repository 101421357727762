import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { User } from '@shared/models';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

marker('home.label.chat_supporter_slogan');
marker('home.label.chat_user_slogan');
@Component({
    selector: 'tizo-chat-card',
    templateUrl: './chat-card.component.html',
    styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent implements OnInit {
    @Input() user: User;
    @Input() isSupporter: boolean;

    constructor() {
    }

    ngOnInit() {
    }
}
