import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { INewFeed, INewsCategory } from '@shared/models';
import { Plugins } from '@capacitor/core';
import { Platform, NavController } from '@ionic/angular';
import { ROUTES } from '@shared/contants';
import { NewFeedApiService } from '@api/new-feed-api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const ITEMS_PER_CATEGORY = 4;

@Component({
  selector: 'tizo-news-category',
  templateUrl: './news-category.component.html',
  styleUrls: ['./news-category.component.scss'],
})
export class NewsCategoryComponent implements OnInit, OnDestroy {
  un$ = new Subject();
  @Input() category: INewsCategory;
  feeds: INewFeed[] = [];
  isLoading: boolean;
  constructor(
    private navCtrl: NavController,
    private feedService: NewFeedApiService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.feedService
      .getFeeds(this.category.parentPath + this.category.id + '/', true, ITEMS_PER_CATEGORY)
      .pipe(takeUntil(this.un$))
      .subscribe(feeds => {
        this.feeds = feeds.docs.map(d => d.data() as INewFeed);
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });
  }

  goToDetailNewFeed(id: string) {
    this.navCtrl.navigateForward([ROUTES.NEW_FEED_DETAIL], {
      queryParams: { id }
    });
  }

  goToDetailCategory() {
    this.navCtrl.navigateForward([ROUTES.NEWS_CATEGORY_DETAIL(this.category.id)]);
  }

  ngOnDestroy() {
    this.un$.next();
    this.un$.complete();
  }
}
