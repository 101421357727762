<div class="date-input">
  <input
    [attr.disabled]="isDisabled"
    mask="{{mask}}"
    [showMaskTyped]="showMaskedType"
    [formControl]="form"
    [dropSpecialCharacters]="false"
    (blur)="onTouch();onBlur()"
    (focus)="onFocus()"
    type="tel">
</div>
