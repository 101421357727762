<ion-card>
  <ion-toolbar mode="ios">
    <ion-title class="ion-text-center">{{title}}</ion-title>
    <ion-buttons slot="end" (click)="dismissModal()">
      <ion-button>
        <ion-icon
          slot="icon-only"
          name="close">
        </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-calendar
    [ngModel]="defaultDate"
    (change)="onChange($event)"
    [format]="format"
    [options]="calendarCmpOptions">
  </ion-calendar>
  <!-- <ion-row class="ion-margin-top">
      <ion-col size="6">
        <ion-button color="gradient" expand="block" fill="outline">Chọn</ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button color="danger"  expand="block" fill="outline">Đóng</ion-button>
      </ion-col>
    </ion-row> -->
</ion-card>
