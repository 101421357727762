import { removeCountryCode, addCountryCode } from './remove-country-code';
export const normalizePhone = (value: any, addCode= false): string => {
  if (value && typeof value == 'object' && value.phoneDigits) {
    value = value.phoneDigits;
  }
  if (!value) {
    value = '0000000000';
  }
  if (typeof value !== 'string') {
    value = '' + value;
  }
  if (typeof value === 'string') {
    value = value.trim();
    value = value.replace(/\s/, '');
  }
  if (value.startsWith('+')) {
    value = removeCountryCode(value);
  }
  if (!value.startsWith('0')) {
    value = '0' + value;
  }
  if (value.length == 1) {
    value = '0000000000';
  }
  if (addCode) {
    value = addCountryCode(value);
  }
  return value;
};
