import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'tizo-mfi-promotion-card',
  templateUrl: './mfi-promotion-card.component.html',
  styleUrls: ['./mfi-promotion-card.component.scss'],
})
export class MfiPromotionCardComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Output() onClick = new EventEmitter();
  constructor() { }

  ngOnInit() {}

}
