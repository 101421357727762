import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'tizo-image-resize',
  templateUrl: './image-resize.component.html',
  styleUrls: ['./image-resize.component.scss'],
})
export class ImageResizeComponent implements OnInit, AfterViewInit {
  imageUrl: SafeUrl;
  /** Image source */
  @Input() src = '';
  // ** Max height of this image after resize,default 200px */
  // @Input() maxHeight = 200;
  // ** Width of this image after resize,defeault 200px */
  @Input() width = 200;
  @Input() height = 200;
  // ** Ratio between width and height,default set to 1 */
  // @Input() ratio = 0.75;
  /** Whether if image fit cover,contain,default set to contain */
  @Input() objectFit = 'contain';

  @ViewChild('img') imgElement: ElementRef;
  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  async ngOnInit() {
    if (this.src) {
      if (this.src.startsWith('https://cdn.shopify')) {
        this.resizeShopify();
      }
    }
  }

  resizeShopify() {
    const urlParts = this.src.split('.');
    urlParts[urlParts.length - 2] = urlParts[urlParts.length - 2] + `_${this.width}x${this.height}`;
    this.src = urlParts.join('.');
  }

  async ngAfterViewInit() {
    // this.imgElement.nativeElement.src = this.src;
    // this.imageUrl = this.src;
    // const imageUrl = await this.compressImage(this.imgElement.nativeElement,this.width);
    // this.imageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(imageUrl);

  }
  compressImage(img, newWidth: number): Promise<string> {
    img.crossOrigin = 'anonymous';
    const canvas = document.createElement('canvas');
    return new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const oldWidth = img.width;
        const oldHeight = img.height;
        const newHeight = Math.floor((oldHeight / oldWidth) * newWidth);
        // if (width > height) {
        //   if (width > this.maxWidth) {
        //     height = Math.round((height *= this.maxWidth / width))
        //     width = this.maxWidth
        //   }
        // } else {
        //   if (height > this.maxHeight) {
        //     width = Math.round((width *= this.maxHeight / height))
        //     height = this.maxHeight
        //   }
        // }
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        resolve(canvas.toDataURL('image/jpeg', 1));
      };
      img.onerror = (err) => {
        reject(err);
      };
    });
  }

}


