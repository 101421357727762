import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { User } from '@shared/models';
import { Observable, of, combineLatest, BehaviorSubject, from } from 'rxjs';
import { Client, Employee, VSLAGroup } from '@canalcircle/models';
import { VSLA_ROLES } from '@shared/contants';
import { CC_ROLES } from '@shared/contants';
import { VSLAGroupService } from '@canalcircle/vsla-core-angular';
import firebase from 'firebase';
import * as _ from 'lodash';
import { APPLICATION_STORAGE_KEYS, USER_MODE } from '../../application/consts/storage-keys';
import { firestore } from 'firebase';
import { STORAGE_CART } from '@crossselling/services/cart.service';
import { Storage } from '@ionic/storage';

const USERS_COLLECTIONS = 'users';
const USER_STORAGE_KEY = 'tizo_storage_user';
const USER_ID_STORAGE_KEY = 'tizo_user_id';

export interface VSLAInfo {
    enableVsla: boolean;
    latestGroupTerm: VSLAGroup;
}
@Injectable({
    providedIn: 'root'
})
export class UserService {
    authUser$: BehaviorSubject<firebase.User> = new BehaviorSubject(null);
    currentUser$: BehaviorSubject<User> = new BehaviorSubject(null);
    // * Decide whether if user load done or not,it was used in ConfigGuard */
    loadDoneUser$ = new BehaviorSubject(false);

    constructor(
        private afAuth: AngularFireAuth,
        private afStore: AngularFirestore,
        private vslaGroupService: VSLAGroupService,
        private storageCtrl: Storage
    ) {
    }

    getCurrentUserId() {
        return this.authUser$.pipe(map(user => user?.uid), take(1));
    }

    isOfficer() {
        return this.currentUser.roles.includes('officer');
    }

    registerSeller(): Promise<void> {
        return this.afStore.firestore.runTransaction(async txn => {
            const userRef = this.afStore.doc('users/' + this.currentUser.id).ref;
            const user = (await txn.get(userRef)).data() as User;
            if ((user.roles || []).includes(CC_ROLES.seller)) {
                throw new Error('crossSelling.message.already_register_seller')
            }
            if ((user.roles || []).includes(CC_ROLES.pendingseller)) {
                throw new Error('crossSelling.message.pending_register_seller')
            }
            await txn.update(userRef, { roles: firestore.FieldValue.arrayUnion(CC_ROLES.pendingseller) })
        });
    }

    get currentUser() {
        return this.currentUser$.value;
    }
    // this.afAuth.user is slow (more than 1 second)
    // replaced this.afAuth.user by authUser$
    async loadCurrentUser() {
        this.afAuth.user.subscribe(user => {
            this.authUser$.next(user);
        });
        this.loadDoneUser$.next(false);
        const userLocal = this.getUserFromLocal();
        if (userLocal) {
            this.loadDoneUser$.next(true);
            this.currentUser$.next(userLocal);
        }
        /*In case not has local user,load user from server */
        // Alway subcribe from server to update cache;
        this.getCurrentUser$()
            .subscribe(user => {
                if (user) {
                    this.currentUser$.next(user);
                    this.saveUserToLocal(user);
                }
                this.loadDoneUser$.next(true);
            },
                (e) => {
                    this.loadDoneUser$.next(true);
                });
    }

    getCachedCurrentUser(): User {
        return this.currentUser;
    }

    /**  Use getCachedCurrentUser instated if not need real time   */
    getCurrentUser$(): Observable<User> {
        return this.authUser$
            .pipe(
                switchMap(user => user
                    ? this.afStore.collection('users').doc<User>(user.uid).valueChanges()
                    : of(null))
            );
    }
    
    getCurrentUserDoc(): AngularFirestoreDocument<User> {
        return this.afStore.collection('users').doc(this.currentUser$.value.id);
    }

    getVslaInfo$(): Observable<VSLAInfo> {
        return this.getCurrentUser$().pipe(
            switchMap(user => {
                if (!user) {
                    return combineLatest([
                        of([]),
                        of(false),
                    ]);
                }
                return combineLatest([
                    this.vslaGroupService.getGroupsForUser$(user.id).pipe(
                        catchError(e => of([]))
                    ),
                    of((user.roles || []).some(role => {
                        return VSLA_ROLES[role];
                    }))
                ]);
            }),
            map(([groupsWithTerms, check2]) => ({
                enableVsla: groupsWithTerms.length > 0 || check2,
                latestGroupTerm: groupsWithTerms[0],
            }))
        );
    }

    getCurrentAuthUser() {
        return this.authUser$;
    }

    getClientByCurrentUser(): Observable<Client | null> {
        return this.authUser$.pipe(
            switchMap(user => !user ?
                of(null) :
                this.getClientByUserId(user.uid)
            ),
            take(1)
        );
    }

    getClientByCurrentUserAndTenantId(tenantId): Observable<Client | null> {
        return this.authUser$.pipe(
            switchMap(user => !user ?
                of(null) :
                this.getClientsByCurrentUser().pipe(
                    map(clients => clients.find(client => client.tenantId === tenantId))
                )
            ),
            take(1)
        );
    }

    getClientByCurrentUserAndTenantId$(tenantId): Observable<Client | null> {
        return this.authUser$.pipe(
            switchMap(user => !user ?
                of(null) :
                this.getClientByUserIdAndTenantId(user.uid, tenantId)
            ),
            take(1)
        );
    }

    getClientsByCurrentUser(): Observable<Client[]> {
        return this.authUser$.pipe(
            switchMap(user => !user ?
                null :
                this.getClientsByUserId$(user.uid)
            ),
            take(1)
        );
    }

    getUsersByIds$(userIds: Array<string>) {
        return this.afStore.collection<User>('users', ref => ref.where('id', 'in', userIds))
            .get({ source: 'server' }).pipe(
                map(actions => actions.docs.map(d => d.data() as User))
            );
    }

    // FOR CLIENTS
    getClientsByIds(clientIds: Array<string>) {
        return this.afStore.collection<Client>('clients', ref => ref.where('id', 'in', clientIds)).valueChanges().pipe(take(1));

    }

    getCurrentUserWithClients$(): Observable<{ user: User, clients: Client[] }> {
        return this.authUser$.pipe(
            filter(user => !!user),
            switchMap(user => combineLatest([
                this.afStore.collection('users').doc<User>(user.uid).valueChanges(),
                this.getClientsByUserId$(user.uid)
            ]).pipe(
                map(([user, clients]) => ({ user, clients }))
            ))
        );
    }

    getClientByUserId(userId: string): Observable<Client | null> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('uid', '==', userId)
        )
            .valueChanges()
            .pipe(map(sn => sn.length ? sn[0] : null));
    }

    getClientByUserIdAndTenantId(userId: string, tenantId: string): Observable<Client | null> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('uid', '==', userId)
                .where('tenantId', '==', tenantId)
        )
            .valueChanges()
            .pipe(map(sn => sn.length ? sn[0] : null));
    }

    getClientsByTenantIds$(tenantIds: Array<string>): Observable<Client[]> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('tenantIds', 'array-contains-any', tenantIds)
        ).get({ source: 'server' }).pipe(
            map(snaps => snaps.docs.map(d => d.data() as Client))
        );
    }

    getClientsByOfficePaths$(officePaths: Array<string>): Observable<Client[]> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('officePaths', 'array-contains-any', officePaths)
        ).get({ source: 'server' }).pipe(
            map(snaps => snaps.docs.map(d => d.data() as Client))
        );
    }

    getClientsByTenantId(tenantId: string): Observable<Client[]> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('tenantId', '==', tenantId)
        ).valueChanges();
    }

    getClientsByUserId$(userId: string): Observable<Client[]> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('uid', '==', userId)
        ).valueChanges();
    }


    getUserById(uid: string) {
        return this.afStore.collection('users').doc<User>(uid).get().pipe(
            map(doc => doc.data() as User)
        );
    }

    getUserByPhoneNumber(phoneNumber: string) {
        return this.afStore.collection('users', ref => ref.where('authInfo.phoneNumber', '==', phoneNumber)).get().pipe(
            map(result => result.docs.map(d => d.data() as User)),
            map(result => result[0]),
        );
    }

    trackLastLogin(uid: string) {
        // this.isLoggedIn = true
        return this.upDateUserById(uid, { lastLoggedInAt: { tizo: new Date() } });
    }

    upDateUserById(uid: string, update): Promise<any> {
        return this.afStore.collection(USERS_COLLECTIONS).doc<User>(uid).set(update, { merge: true });
    }

    addNewTenantToCurrentUser(tenantId: string) {
        return this.afStore.collection(USERS_COLLECTIONS).doc(this.currentUser.id).update({
            tenantIds: firebase.firestore.FieldValue.arrayUnion(tenantId)
        });
    }

    async createNewAnonymousUser(params: CreateNewUserParams): Promise<User> {
        const randId = this.afStore.createId();
        const user: User = {
            id: randId,
            authInfo: {
                displayName: params.name,
                uid: randId,
                email: '',
                phoneNumber: '',
                photoURL: ''
            },
            roles: [],
            tenantIds: params.tenantIds || [],
            order: 1,
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
        };

        await this.afStore.collection(USERS_COLLECTIONS).doc(randId).set(user, { merge: true });

        return user;
    }

    // * Check if has role supporter chat */
    isSupporter(roles: Array<string>) {
        return roles.includes(CC_ROLES.officer) || roles.includes(CC_ROLES.ccsuperagent);
    }

    // * Check if is  cc agent  */
    isAgent(roles: Array<string>) {
        return roles.includes(CC_ROLES.ccagent);
    }

    isSeller(roles: Array<string>) {
        return roles.includes(CC_ROLES.seller);
    }

    isSellerUser() {
        const roles = this.currentUser.roles;
        return roles.includes(CC_ROLES.seller);
    }

    // * Check if is  cc agent  */
    isSeller$() {
        return this.getCurrentUser$().pipe(
            map(user => (user?.roles || []).includes(CC_ROLES.seller)),
        );
    }

    // * Check if is supper agent  */
    isSuperAgent(roles: Array<string>) {
        return roles.includes(CC_ROLES.ccsuperagent);
    }

    // * Check if is officer agent  */
    isOfficerAgent(roles: Array<string>) {
        return roles.includes(CC_ROLES.officer);
    }

    // findEmployeeById(uid: string) {
    //     return this.afStore.collection('users').doc<User>(uid).get();
    // }

    //  For employees
    getEmployeesByUid$(uid: string) {
        return this.afStore.collection<Employee>('employees', ref => ref.where('uid', '==', uid)).valueChanges();
    }

    getEmployeeIdsByUid$(uid: string) {
        return this.getEmployeesByUid$(uid).pipe(
            map(employees => employees.map(ep => ep.id))
        );
    }

    setUserMode(mode: USER_MODE) {
        return localStorage.setItem(APPLICATION_STORAGE_KEYS.userMode, mode);
    }

    getUserMode() {
        return localStorage.getItem(APPLICATION_STORAGE_KEYS.userMode);
    }

    isOverDraftMode() {
        return localStorage.getItem(APPLICATION_STORAGE_KEYS.userMode) === USER_MODE.OVERDRAFT;
    }

    removeLocalUser() {
        localStorage.removeItem(USER_STORAGE_KEY);
        localStorage.removeItem(USER_ID_STORAGE_KEY);
        localStorage.removeItem(APPLICATION_STORAGE_KEYS.userMode);
        localStorage.removeItem(APPLICATION_STORAGE_KEYS.viewedCollections);
        this.storageCtrl.remove(STORAGE_CART);
    }

    private saveUserToLocal(user: User) {
        if (!user) {
            return localStorage.removeItem(USER_STORAGE_KEY);
        }
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    }

    private getUserFromLocal(): User {
        return JSON.parse(localStorage.getItem(USER_STORAGE_KEY)) || null;
    }

    // private saveUserIdToLocal(id: string) {
    //     if (!id) {
    //         return localStorage.removeItem(USER_ID_STORAGE_KEY);
    //     }
    //     return localStorage.setItem(USER_ID_STORAGE_KEY, id);
    // }
    // private getUserIdFromLocal(): string {
    //     return localStorage.getItem(USER_ID_STORAGE_KEY);
    // }
}


export interface CreateNewUserParams {
    name: string;
    tenantIds: string[];
}
