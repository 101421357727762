import { trigger, transition, animate, style } from '@angular/animations';

export const fadeIn = trigger('fadeIn', [
  transition(':leave', [
    animate('100ms', style({opacity: 0}))
  ]),
  transition(':enter', [
    style({opacity: 0}),
    animate('1s', style({opacity: 1}))
  ])
]);
