import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';
import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionSheetController, ModalController } from '@ionic/angular';

export interface ISelectOption {
  name: string;
  id: string;
  value: string;
  isChecked: boolean;
}

@Component({
  selector: 'tizo-multi-select-popup',
  templateUrl: './multi-select-popup.component.html',
  styleUrls: ['./multi-select-popup.component.scss'],
})
export class MultiSelectPopup implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() options: ISelectOption[] = [];

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  onSubmit() {
      this.modalCtrl.dismiss(this.options.filter(opt => opt.isChecked));
  }

}
