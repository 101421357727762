<ion-header class="ion-no-border header-primary">
  <ion-toolbar>
    <ion-buttons slot="start" >
      <ion-back-button *ngIf="showBackButton" [icon]="'chevron-back-outline'" defaultHref="/"></ion-back-button>
    </ion-buttons>
   
    <ion-title [class.ion-margin-start]="!showBackButton">
      {{title}}
    </ion-title>
    <ion-buttons slot="end">
      <ng-content></ng-content>
    </ion-buttons>
  </ion-toolbar>
  <ng-content select=".second-toolbar"></ng-content>
</ion-header>  