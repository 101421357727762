import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IRadioOption {
  name: string;
  id: string;
  value: string;
}

@Component({
  selector: 'tizo-radio-field',
  templateUrl: './radio-field.component.html',
  styleUrls: ['./radio-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioFieldComponent),
      multi: true
    }
  ]
})
export class RadioFieldComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() name: string;
  @Input() options: IRadioOption[] = [];
  @HostBinding('class.float-field-container--is-disabled') isDisabled;
  onTouch: () => void;
  form: FormControl = new FormControl('');

  constructor(
  ) { }

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  writeValue(val) {
    this.form.setValue(val);
  }

  registerOnChange(fn) {
    this.form.valueChanges.subscribe(val => {
      fn(val);
    });
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = !!isDisabled;
  }

}
