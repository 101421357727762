import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import * as firebase from 'firebase';
@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date | string | firebase.firestore.Timestamp | Array<number>, format = 'DD/MM/YYYY'): any {
    if (!value) {
      return value;
    }
    let dateInput = value;
    if (value instanceof firebase.firestore.Timestamp) {
      dateInput = value.toDate();
    }
    if (typeof value === 'string') {
      dateInput = new Date(value);
    }
    if (Array.isArray(value)) {
      return value.reverse().join('/');
    }
    return moment(dateInput).format(format);
  }
}
