import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { VSLATermUser, Role, User } from '@canalcircle/models';
import { UserService } from '@services/user.service';
import { COLLECTION_NAMES } from '@vsla/shared/contants';
import { VSLATermService } from '@canalcircle/vsla-core-angular';

export enum PERMISSION_SCOPES {
    'global' = 'global',
    'vsla' = 'vsla',
}

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    public globalPerms: Array<string> = []; // array of permission ids
    public vslaPermsByTerm: { [termId: string]: Array<string> } = {}; // map of <termId => array of permission ids>
    done$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private currentTermId: string;

    constructor(
        private afs: AngularFirestore,
        private userService: UserService,
        private vslaTermService: VSLATermService,
    ) {
    }

    public initPermissions() {
        this.userService.getCurrentUser$().pipe(
            switchMap(user => {
                this.done$.next(false);
                return !user ?
                    of([null, null, null]) :
                    combineLatest([
                        of(user),
                        this.getRolesForTenant$(user.tenantIds),
                        this.vslaTermService.getTermsUserForUser$(user.authInfo.uid),
                    ]);
            })
        ).subscribe(([user, roleDocs, termUserDocs]: [User, Role[], VSLATermUser[]]) => {
            if (roleDocs && termUserDocs) {
                const globalPerms = roleDocs
                    .filter(role => (user.roles || []).includes(role.key))
                    .map(role => role.permissions || [])
                    .flat();
                const vslaPermsByTerm = {};
                termUserDocs.forEach((termUser: VSLATermUser) => {
                    const role = roleDocs.find(role => role.key === termUser.roleId && role.tenantId === termUser.tenantId);
                    vslaPermsByTerm[termUser.termId] = role?.permissions || [];
                });

                this.globalPerms = globalPerms;
                this.vslaPermsByTerm = vslaPermsByTerm;
                console.log('Get updated roles, permissions: ', { globalPerms, vslaPermsByTerm });
            }
            this.done$.next(true);
        });
        return this.done$;
    }

    public getRolesForTenant$(tenantIds: string[]) {
        if (!tenantIds || tenantIds.length === 0) { return of([]); }
        return this.afs.collection('roles', ref => ref.where('tenantId', 'in', tenantIds)).valueChanges();
    }

    public setVSLATermId(termId: string) {
        this.currentTermId = termId;
        return this;
    }

    public can(permission: string, scope: PERMISSION_SCOPES = PERMISSION_SCOPES.vsla) {
        const { globalPerms, vslaPermsByTerm } = this;
        switch (scope) {
            case PERMISSION_SCOPES.global:
                return globalPerms.includes(permission);
            case PERMISSION_SCOPES.vsla:
                return Array.isArray(vslaPermsByTerm[this.currentTermId]) && vslaPermsByTerm[this.currentTermId].includes(permission);
            default:
                return false;
        }
    }
}
