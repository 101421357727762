<ion-content>
  <ion-toolbar mode="ios" slot="fixed" class="top-bar">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="dissmissModal()">
        <ion-icon name="chevron-back-outline" color="light"></ion-icon>
      </ion-button>
        <!-- <ion-back-button  (click)="dissmissModal()"></ion-back-button> -->
    </ion-buttons>
  </ion-toolbar>
    
    <!-- images -->
  
    <tizo-spinner *ngIf="loading"></tizo-spinner>
  
    <ng-container *ngIf="collection">
      <div class="branch-intro__img" [style.background-image]="'url('+ (collection.bannerImage || collection.image) + ')'">
      </div>
      <div [innerHtml]="collection.content" class="ion-padding branch-intro__content"></div>
      
    </ng-container>
  
</ion-content>

<ion-footer *ngIf="collection">
  <ion-row class="ion-justify-content-center footer">
    <ion-col size="8">
      <tizo-btn-primary (btnClick)="onClickViewMore()" shape="round">{{'cross_selling.label.view_product' | translate}}</tizo-btn-primary>
    </ion-col>
  </ion-row>
</ion-footer>