import {
  Component, OnInit
} from '@angular/core';

@Component({
  selector: 'tizo-fields-container',
  templateUrl: './fields-container.component.html',
  styleUrls: ['./fields-container.component.scss'],
})
export class FieldsContainerComponent implements OnInit{
  constructor(
  ) {
  }

  ngOnInit() {
  }

  
}
