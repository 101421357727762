import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { IonicNativeService } from '@core/ionic-native/ionic-native.service';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import '@patches/fix-sign-out-subscription';
import { ConfigService } from '@services/config.service';
import { FeatureService } from '@services/feature.service';
import { LocationService } from '@services/location.service';
import { MixpanelService, TRACKING_EVENTS } from '@services/mixpanel.service';
import { RoutingService } from '@services/routing.service';
import { TabService } from '@services/tab.service';
import { TenantService } from '@services/tenant.service';
import { UpdateService } from '@services/update.service';
import { UserService } from '@services/user.service';
import { UtilsService } from '@services/utils.service';
import { User } from '@shared/models';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotificationService } from 'src/app/notification/services/notification.service';
import { default as packageJson } from '../../package.json';
import { getLanguage, getLocale, setLanguage } from './i18n/utils';

const { SplashScreen, StatusBar } = Plugins;
const { FirebaseAnalytics } = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    user$: Observable<User>;
    authUser: User;
    version: string;
    build: string;
    envName: string;

    // menuConditions: any = {};
    constructor(
        private platform: Platform,
        private mixpanelService: MixpanelService,
        private configService: ConfigService,
        private tenantService: TenantService,
        private route: Router,
        private tabService: TabService,
        private locationService: LocationService,
        private notificationService: NotificationService,
        private userService: UserService,
        private featureService: FeatureService,
        private utilsService: UtilsService,
        private updateService: UpdateService,
        private ionicNativeService: IonicNativeService,
        private translateService: TranslateService,
        private routingService: RoutingService,
    ) {
        // Check version
        this.version = packageJson.version;
        this.build = packageJson.build;
        this.envName = environment.name;

        this.initializeApp();
        this.initLanguage();
        this.updateService.handleUpdate();
        this.configService.loadAllConfig();
        this.notificationService.init();
        this.locationService.init();
        this.configService.isLoadDoneAllConfig$().subscribe(done => {
            SplashScreen.hide({ fadeOutDuration: 1000 });
        });
        this.route.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.onRoute(event);
            }
        });
        //  @TODO Need recheck it
        this.user$ = this.userService.getCurrentUser$();

        this.user$.pipe(filter(user => !!user)).subscribe((user) => {
            // this.authUser = user;
            this.tenantService.init(user);
            this.featureService.init();

        });
        this.assignUserIdToAnalytics();
    }

    onRoute(event: NavigationEnd) {
        const url = event.urlAfterRedirects.split('/');
        const pageName = url[url.length - 1];
        const pageNotHideTabs = ['dashboard', 'groups', 'loader', 'welcome-finish', 'update-info'];
        if (url.indexOf('vsla') !== -1 && pageNotHideTabs.indexOf(pageName) === -1) {
            this.tabService.showHideTabBar(true);
        } else {
            this.tabService.showHideTabBar(false);
        }
        this.routingService.setPreviousUrl(event);
    }

    initializeApp() {
        this.platform.ready().then(async () => {
            if (this.platform.is('cordova')) {
                this.ionicNativeService.lockScreen();
                try {
                    StatusBar.setBackgroundColor({ color: '#000' });
                    StatusBar.setOverlaysWebView({ overlay: false });
                } catch (e) {
                    console.log('Error when setBackgroundColor, setOverlaysWebView', e);
                }
            }
            this.mixpanelService.track(TRACKING_EVENTS.OPEN_APP);
        });
    }

    assignUserIdToAnalytics() {
        if (this.utilsService.isMobile()) {
            this.user$.subscribe(user => {
                console.log('assignUserIdToAnalytics', user);
                if (user) {
                    FirebaseAnalytics.setUserId({
                        userId: user.id,
                    });
                    FirebaseAnalytics.setUserProperty({
                        name: 'phoneNumber',
                        value: user.authInfo?.phoneNumber || '',
                    });
                    FirebaseAnalytics.setUserProperty({
                        name: 'displayName',
                        value: user.authInfo?.displayName || '',
                    });
                }
            });
        }
    }

    initLanguage() {
        const currentLanguage = getLanguage();
        // console.log('OnlangChange: currentLanguage', currentLanguage);
        this.translateService.setDefaultLang('vi');
        this.translateService.use(currentLanguage || 'vi');

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          console.log('OnlangChange: ', event);
          setLanguage(event.lang);
          moment.locale(getLocale());
        });
      }
}
