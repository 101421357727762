<ion-card>
  <ion-card-content>
    <div style="height: 100px;">
      <ion-skeleton-text animated style="width: 100%;height: 100%;"></ion-skeleton-text>
    </div>
    <h3>
      <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
    </h3>
    <p>
      <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
    </p>
    <p>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
    </p>
  </ion-card-content>
</ion-card>