import { ICurrencyInput } from '../models';

export const DEFAULT_CURRENCY_INPUT: ICurrencyInput = {
  unit: 1000,
  roundSuffix: '.000',
  currencySuffix: 'đ',
  mask: 'separator',
  thousandSeparator: '.',
  allowNegative: false,
  rounding: true
};
