import { Directive, ViewContainerRef, TemplateRef, Renderer2, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { FeatureService } from '@services/feature.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import _intersection from 'lodash/intersection';

@Directive({
  selector: '[ifEnableFeature]'
})
export class IfEnableFeatureDirective implements AfterViewInit, OnDestroy {
  // Input to pass key of feature config
  @Input() ifEnableFeature: string;
  destroy$ = new Subject();
  constructor(
    private featureService: FeatureService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private platform: Platform,
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngAfterViewInit() {
    this.featureService.getFeatureConfigs()
      .pipe(takeUntil(this.destroy$))
      .subscribe(features => {
        const featureConfig = features.find(feature => feature.key === this.ifEnableFeature);
        // If feature config not exsit or disabled,remove from view
        if (!featureConfig) {
          this.viewContainerRef.remove();
        } else if (!featureConfig.isEnabled) {
          this.viewContainerRef.remove();
        } else if (_intersection(this.platform.platforms(), featureConfig.platforms || []).length === 0) {
          this.viewContainerRef.remove();
        } else {
          // If feature config  exsit and isEnabled,attach template to view
          // Note: Only attach once time,if was attach to view before,dont do it
          const isExsitedRef = this.viewContainerRef.get(0);
          if (!isExsitedRef) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        }
      });
  }
}
