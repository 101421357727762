import { Directive, AfterViewInit, ViewContainerRef, ElementRef, Input, TemplateRef, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Directive({
  selector: '[ifAuthenticate]'
})
export class IfAuthenticateDirective implements AfterViewInit, OnInit, OnDestroy {
  @Input() ifAuthenticate: boolean;
  destroy$: Subject<void> = new Subject();
  constructor(
    private authService: AuthService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private render2: Renderer2,
  ) { }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  ngAfterViewInit() {
    this.authService.isAuthenticated().pipe(
      takeUntil(this.destroy$)
    ).
      subscribe(isAuthenticated => {
        if (this.ifAuthenticate === isAuthenticated) {
          this.viewContainerRef.createEmbeddedView(this.templateRef).detectChanges();
          try {
            this.render2.addClass(this.templateRef.elementRef.nativeElement.nextElementSibling, 'fade-in');
          } catch (e) {
            // @TODO: Mute this exception due to bug of angular 9
          }
        } else {
          this.viewContainerRef.remove();
        }
      });
  }
}
