import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { sprintf } from 'sprintf-js';
import { getLanguage } from 'src/app/i18n/utils';

@Pipe({
  name: 'contentTranslate'
})
export class ContentTranslatePipe implements PipeTransform {
  language: string;
  constructor() {
      this.language = getLanguage();
  }
  // productCollection | contentTranslate : 'title'
  transform(value: any, path: string, params?: any): string {
    if (!value) {
      return '';
    }
    const translatedContent = get(value, `translations.${this.language}.${path}`) as string;
    let content = translatedContent || get(value, path) as string;
    if (content.includes('%') && (value.data || params)) {
      content = sprintf(content, params || value.data);
    }
    return content;
  }
}
