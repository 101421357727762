import {Component, Input, OnInit} from '@angular/core';
import { LocationService } from '@services/location.service';
import { IonicCoreService } from '@services/ionic-core.service';
import { UserService } from '@services/user.service';
import { NavController } from '@ionic/angular';
import { ROUTES } from '@shared/contants';
import { firestore } from 'firebase';
import {GetLastMeetingResult} from '@canalcircle/vsla-core-angular';

@Component({
    selector: 'tizo-location-alert-card',
    templateUrl: './location-alert-card.component.html',
    styleUrls: ['./location-alert-card.component.scss'],
})
export class LocationAlertCardComponent implements OnInit {

    suggestText = 'Bật chia sẻ vị trí để Tizo phục vụ bạn tốt hơn';

    constructor(
        private locationService: LocationService,
        private ionicCoreService: IonicCoreService,
        private userService: UserService,
        private navController: NavController,
    ) {
    }

    ngOnInit() {
    }

    async handleLocation() {
        await this.ionicCoreService.showLoading();
        try {
            const geo = await this.locationService.getCurrentLocation();
            const locationData = await this.locationService.encodeLatLongToAddress(geo);
            const userId = await this.userService.getCurrentUserId().toPromise();
            await this.userService.upDateUserById(userId, {
                meta: {
                    location: {
                        ...locationData,
                        geoPoint: new firestore.GeoPoint(locationData.geoPoint.lat, locationData.geoPoint.long)
                    }
                }
            });
            await this.ionicCoreService.showToastSuccess({
                message: 'Cập nhật địa chỉ thành công'
            });
        } catch (e) {
            console.error(e);
        }
        await this.ionicCoreService.hideLoading();
        return this.navController.navigateForward([ROUTES.ACCOUNT_EDIT()]);
    }
}
