import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticatedGuard, ConfigGuard, VslaGuard} from '@guard/index';

const routes: Routes = [
    // {path: '', redirectTo: 'landing', pathMatch: 'full'},
    {
        path: '',
        redirectTo: 'main-tab',
        pathMatch: 'full',
        // loadChildren: () => import('./landing/landing.module').then(m => m.LandingPageModule)
    },
    {
        path: 'main-tab',
        loadChildren: () => import('./main-tab/main-tab.module').then(m => m.MainTabPageModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
        canActivateChild: [ConfigGuard, AuthenticatedGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivateChild: [ConfigGuard],
    },
    {
        path: 'notifications',
        loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
        canActivateChild: [ConfigGuard],
    },

    // global path,not include in tabs
    // {
    //     path: 'account-info',
    //     loadChildren: () => import('./account/pages/account/account.module').then(m => m.AccountPageModule),
    //     canActivate: [ConfigGuard, AuthenticatedGuard],
    //     canActivateChild: [ConfigGuard, AuthenticatedGuard]
    // },
    {
        path: 'on-boarding',
        loadChildren: () => import('./on-boarding/on-boarding.module').then(m => m.OnBoardingPageModule),
        canActivate: [ConfigGuard],
    },
    {
        path: 'loyalty',
        loadChildren: () => import('./loyalty/loyalty.module').then(m => m.LoyaltyModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'top-up',
        loadChildren: () => import('./topup/topup.module').then(m => m.TopupModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'saving',
        loadChildren: () => import('./saving/saving.module').then(m => m.SavingModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'e-receipt',
        loadChildren: () => import('./e-receipt/e-receipt.module').then(m => m.EReceiptModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
        canActivateChild: [ConfigGuard, AuthenticatedGuard]
    },
    {
        path: 'cross-selling',
        loadChildren: () => import('./cross-selling/cross-selling.module').then(m => m.CrossSellingModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
    },
    {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
        canActivate: [ConfigGuard],
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'redirect',
        loadChildren: () => import('./redirector/redirector.module').then(m => m.RedirectorModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
    },
    {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [AuthenticatedGuard],
    },
    {
        path: 'loan',
        loadChildren: () => import('./loan/loan.module').then(m => m.LoanModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
    },
    {
        path: 'register-ambassador',
        loadChildren: () => import('./auth/pages/register-ambassador/register-ambassador.module').then(m => m.RegisterAmbassadorPageModule)
    },
    {
        path: 'overdraft',
        loadChildren: () => import('./overdraft/overdraft.module').then(m => m.OverdraftModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
        canActivateChild: [ConfigGuard, AuthenticatedGuard]
    },
    {
        path: 'vsla-intro',
        loadChildren: () => import('./vsla-intro/vsla-intro.module').then(m => m.VslaIntroPageModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactPageModule)
    },
    {
        path: 'feedback',
        loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule)
    },
    {
        path: 'recognition',
        loadChildren: () => import('./recognition/recognition.module').then(m => m.RecognitionModule)
    },
    {
        path: 'account/edit',
        loadChildren: () => import('./account/pages/account-edit/account-edit.module').then(m => m.AccountEditPageModule)
    },
    {
        path: 'account/preview',
        loadChildren: () => import('./account/pages/account-preview/account-preview.module').then(m => m.AccountPreviewPageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
