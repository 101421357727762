import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { ConfigService } from '@services/config.service';
import { Tenant } from '@canalcircle/models';


@Component({
    selector: 'tizo-select-tenant-modal',
    templateUrl: './select-tenant-modal.component.html',
    styleUrls: ['./select-tenant-modal.component.scss'],
})
export class SelectTenantModalComponent implements OnInit, OnDestroy {
    @Input() tenants: Array<Tenant> = [];
    @Input() title = 'crossSelling.label.crossSellingLocationSupport';
    @Input() hideGeneric = false;
    destroy$: Subject<void> = new Subject();
    isLoading: boolean;
    form = new FormControl('', [Validators.required]);

    constructor(
        private modalCtrl: ModalController,
        private coreConfigService: ConfigService
    ) {
    }

    ngOnInit() {
        this.loadTenants();
        if (this.tenants.length > 0) {
            this.form.setValue(this.tenants[0].id);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    dismissModal() {
        this.modalCtrl.dismiss();
    }

    loadTenants() {
        // this.isLoading = true;
        // this.coreConfigService.getAvailableTenantsForCSR().subscribe(tenants => {
        //     this.tenants = tenants;
        //     this.isLoading = false;
        // }, e => {
        //     this.isLoading = false;
        // });
    }
    onClickOk() {
        this.modalCtrl.dismiss(this.form.value);
    }
}
