<ion-card button class="feature-card">
  <div class="feature-card__content">
    <div class="feature-card__image-box">
      <img [src]="icon" />
    </div>
    <div class="feature-card__name">
      <ion-text>{{ ('features.card.'+ key) | translate}}</ion-text>
    </div>
  </div>
</ion-card>

