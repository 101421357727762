import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { SMS } from '@ionic-native/sms/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { TranslateService } from '@ngx-translate/core';

async function loadImg(url): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        // Create original image
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            resolve(img);
        };
        img.src = url
    });
}

@Injectable({
  providedIn: 'root'
})
export class IonicNativeService {

  constructor(
    private platForm: Platform,
    private toastCtrl: ToastController,
    private clipBoardCtrl: Clipboard,
    private photoViewerCtrl: PhotoViewer,
    private callNumberCtrl: CallNumber,
    private smsCtrl: SMS,
    private screenOrientationCrl: ScreenOrientation,
    private translateService: TranslateService
  ) {
  }

  copy(text: string) {
    const mess = this.translateService.instant('common.message.copied');
    if (this.platForm.is('cordova')) {
      this.clipBoardCtrl.copy(text).then(res => {
        this.showToast(mess);
      });
    } else {
      navigator.clipboard.writeText(text).then(() => {
        this.showToast(mess);
      });
    }
  }

  async viewPhotoElement(imgElement: HTMLImageElement) {
    const canvas = document.createElement("canvas");

    canvas.width = imgElement.naturalWidth;
    canvas.height = imgElement.naturalHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(imgElement, 0, 0, imgElement.naturalWidth, imgElement.naturalHeight, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL('image/jpeg', 0.6);

    var options = {
        share: false, // default is false
        closeButton: true, // default is true
        copyToReference: true, // default is false
        headers: '',  // If this is not provided, an exception will be triggered
        piccasoOptions: { } // If this is not provided, an exception will be triggered
    };
    return this.photoViewerCtrl.show(dataURL, '', options);
  }

  /**
   * This will reload the image from the url again
   **/
  async viewPhoto(url: string) {
    const imgElement = await loadImg(url);

    const canvas = document.createElement("canvas");

    canvas.width = imgElement.naturalWidth;
    canvas.height = imgElement.naturalHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(imgElement, 0, 0, imgElement.naturalWidth, imgElement.naturalHeight, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL('image/jpeg', 0.6);

    var options = {
        share: false, // default is false
        closeButton: true, // default is true
        copyToReference: true, // default is false
        headers: '',  // If this is not provided, an exception will be triggered
        piccasoOptions: { } // If this is not provided, an exception will be triggered
    };
    return this.photoViewerCtrl.show(dataURL, '', options);
  }

  private async showToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      position: 'bottom',
      duration: 2000
    });
    await toast.present();
  }

  makeCall(phoneNumber: string) {
    return this.callNumberCtrl.callNumber(phoneNumber, true);
  }

  sendSms(phoneNumber: string, sms = '') {
    return this.smsCtrl.send(phoneNumber, sms, { android: { intent: 'INTENT' }, replaceLineBreaks: true });
  }

  lockScreen() {
    this.screenOrientationCrl.lock(this.screenOrientationCrl.ORIENTATIONS.PORTRAIT);
  }
}
