<tizo-modal-search-base
    class="ion-page"
    [title]="title | translate"
    [keyword]="keyword"
    [searchPlaceholder]="'common.label.inputProvinceName' | translate"
    (infinite)="onInfiniteScroll($event)"
    (search)="onSearch($event)">
    <ion-list>
        <ion-item
            [attr.data-cy]="'modal-search-province-' + p.id"
            (click)="clickItem(p)"
            *ngFor="let p of provices$ | async | slice : 0 : (page * perPage);trackBy:trackByFn"
            button
            [color]="selected && p.id === selected.id ? 'light' : undefined"
            detail="false">
            {{p.name}}
        </ion-item>
    </ion-list>
</tizo-modal-search-base>
