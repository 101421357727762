<ion-header class="ion-no-border header-primary">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-back-button [icon]="'chevron-back-outline'" defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{title}}
    </ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="toggleSearchBar()">
        <ion-icon name="{{isShowSearchBar ? 'close' : 'search'}}"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
  <ion-toolbar class="ion-padding-horizontal">
    <ion-searchbar (ionChange)="onSearch($event)" mode="ios" [placeholder]="'vsla.label.search_by_name' | translate"></ion-searchbar>
  </ion-toolbar>
</ion-header>
