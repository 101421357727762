import {SavingProductEnum} from '@canalcircle/models';
import {APPLICATION_STORAGE_KEYS, USER_MODE} from 'src/app/application/consts/storage-keys';

const getRootRoute = () => {
    const isOverdraftMode = localStorage.getItem(APPLICATION_STORAGE_KEYS.userMode) === USER_MODE.OVERDRAFT;
    return isOverdraftMode ? '/overdraft' : '/main-tab';
};

export const ROUTES = {
    ROOT_PAGE: '/on-boarding',
    ROOT_PAGE_ON_WEB: '/auth/login',
    ROOT_PAGE_ON_MOBILE: '/auth/login',
    // Main tab
    MAIN_TAB: '/main-tab',

    // Application module
    APPLICATION_LIST: () => `${getRootRoute()}/applications`,
    APPLICATION_CREATE: () => `${getRootRoute()}/applications/create`,
    APPLICATION_DETAIL: id => `${getRootRoute()}/applications/detail/${id}`,
    APPLICATION_OVERVIEW: id => `${getRootRoute()}/applications/overview/${id}`,

    // Notification module
    NOTIFICATION_LIST: () => `/notifications`,
    NOTIFICATION_DETAIL: id => `/notifications/detail/${id}`,

    // Account
    ACCOUNT: () => `${getRootRoute()}/account`,
    ACCOUNT_INFO: '/account-info',
    ACCOUNT_PREVIEW: () => `/account/preview`,
    ACCOUNT_EDIT: () => `/account/edit`,
    LOGIN: () => '/auth/login',

    // VSLA
    VSLA_GROUP_LIST: () => `main-tab/vsla/groups/`,
    VSLA_LOADER: () => `main-tab/vsla/loader/`,

    // On boarding page
    ON_BORADING: 'on-boarding',

    // Home routing
    NEWS_CATEGORY_DETAIL: (id: string) => `/main-tab/home/news-category-detail/${id}`,
    NEW_FEED_DETAIL: `/main-tab/home/new-feed-detail`,
    NEWS_CATEGORIES: '/main-tab/home/news-categories',

    // loyalty module
    LOYALTY_LIST: '/main-tab/loyalty',
    // @TODO: investigate more abount nested tab routing. Temporarily fix.
    _LOYALTY_LIST: '/loyalty',
    EXCHANGE_POINT: '/loyalty/exchange-point',
    COLLECT_POINT: '/loyalty/collect-point',
    CAMPAIGN_DETAIL: `/loyalty/campaign-detail`,
    // billing
    BILLING_TYPE_LIST: () => `/billing/billing-types`,
    BILLING_PROVIDER_LIST: (id: string) => `/billing/billing-providers/${id}`,
    BILLING_PROVIDER_DETAIL: (id: string) => `/billing/billing-provider-details/${id}`,
    BILLING_DETAIL: (pId: string, dId) => `/billing/billing-provider-details/${pId}/detail/${dId}`,

    // Top up
    TOP_UP: '/top-up',
    SAVING: 'saving',
    SCAN_QR: 'scan-qr',

    // Cross selling
    CROSS_SELLING: '/cross-selling',
    CROSS_SELLING_CART: '/main-tab/cart',
    _CROSS_SELLING_CART: '/cross-selling/cart',
    CROSS_SELLING_COLLECTIONS: '/cross-selling/collections',
    CROSS_SELLING_SEARCH: '/cross-selling/search',
    CROSS_SELLING_CHECKOUT: '/cross-selling/checkout',
    CROSS_SELLING_CHECKOUT_DONE: '/cross-selling/checkout-done',
    CROSS_SELLING_ORDERS: '/main-tab/orders',
    CROSS_SELLING_ORDER_DETAIL: (id: string) => `/cross-selling/order-detail/${id}`,
    CROSS_SELLING_COLLECTION: (id: string) => `/cross-selling/collection-detail/${id}`,
    CROSS_SELLING_COLLECTION_PRODUCTS: (id: string) => `/cross-selling/collection-products/${id}`,
    CROSS_SELLING_MAIN_COLLECTION: (id: string) => `/cross-selling/main-collection/${id}`,
    CROSS_SELLING_PRODUCT_DETAIL: (id: string) => `/cross-selling/product-detail/${id}`,
    CROSS_SELLING_PRODUCT_RECENTS: `/cross-selling/product-recents`,
    CROSS_SELLING_PRODUCT_FAVORITES: `/cross-selling/product-favorite`,

    // My savings
    SAVING_LOADER: '/saving',
    SAVING_HOME: '/saving/home',
    SAVING_ON_BOARDING: '/on-boarding/saving',
    SAVING_CREATE_ACCOUNT_REQUEST_1: '/saving/create/step-1',
    SAVING_CREATE_ACCOUNT_REQUEST_2: '/saving/create/step-2',
    SAVING_CREATE_VIEW_ACCOUNT_REQUEST: '/saving/create-view-saving-request',
    SAVINGS_LIST: '/saving/list',
    SAVING_LIST_REQUESTS: '/saving/requests',
    SAVING_DETAIL: (savingType: SavingProductEnum, tenantId: string, savingId: string) => `/saving/detail/${savingType}/${tenantId}/${savingId}`,
    SAVING_DEPOSIT_WITHDRAWAL: (savingType: SavingProductEnum, action: 'deposit' | 'withdrawal', tenantId: string, savingId: string) =>
        `/saving/${action}/${savingType}/${tenantId}/${savingId}`,

    // My loans
    LOAN_HOME: '/loan/home',
    LOAN_ON_BOARDING: '/on-boarding/loan',
    LOAN_CREATE_ACCOUNT_REQUEST: '/loan/create',
    LOAN_CREATE_DONE: '/loan/create-request-done',
    LOANS_LIST: '/loan/list',
    LOAN_LIST_REQUESTS: '/loan/requests',
    LOAN_DETAIL: (tenantId: string, savingId: string) => `/loan/detail/${tenantId}/${savingId}`,

    // overdraft
    OVERDRAFT: '/overdarft',
    OVERDRAFT_HOME: '/overdraft/home',
    OVERDRAFT_PERSONAL: '/overdraft/personal',
    OVERDRAFT_PURPOSE: '/overdraft/purpose',
    OVERDRAFT_ACTIVED: '/overdraft/actived',
    OVERDRAFT_ACCOUNT_EDIT: '/overdraft/account/edit',

    SERVICE_SELECT: '/auth/service-select',
    // Over draft
    OVER_DRAFT_CREATE_APPLICATION: (tenantPageId: string) => '/overdraft/create-application/' + tenantPageId,

    OVER_DRAFT_LOAN_HOME: '/overdraft/loan-home',
    OVER_DRAFT_LOAN_LIST: '/overdraft/loan-home/loan-list',
    OVER_DRAFT_LOAN_DETAIL: (tenantId: string, loanId: string) => `/overdraft/loan-home/loan-detail/${tenantId}/${loanId}`,
    OVER_DRAFT_LOAN_PAYMENT_1: '/overdraft/loan-home/loan-payment/step-1',
    OVER_DRAFT_LOAN_PAYMENT_2: (tenantId: string, paymentId: string) => `/overdraft/loan-home/loan-payment/step-2/${tenantId}/${paymentId}`,
    OVER_DRAFT_LOAN_PAYMENT_3: (tenantId: string, paymentId: string) => `/overdraft/loan-home/loan-payment/step-3/${tenantId}/${paymentId}`,
    OVER_DRAFT_LOAN_PAYMENT_4: `/overdraft/loan-home/loan-payment/step-4`,

    CONTRACT_LIST: '/overdraft/contract/list',
    CONTRACT_CREATE: '/overdraft/contract/create',
    CONTRACT_VERIFY: (id) => `/overdraft/contract/verify/${id}`,
    CONTRACT_RESULT: '/overdraft/contract/result',
    CONTRACT_VIEW: (id) => `/overdraft/contract/view/${id}`,
    // Landing
    LANDING: '/',

    // Recognition
    RECOGNITION: '/recognition',
    RECOGNITION_RESULT: '/recognition/result',
    RECOGNITION_CONGRATULATION: '/recognition/congratulation',

    // Feedback
    FEEDBACK: '/feedback',

};


