<tizo-dialog-primary [title]="title | translate" [showCloseButton]="showCloseButton">
  <div class="dialog-body" [innerHTML]="content">
  </div>
  <div class="dialog-footer">
    <tizo-btn-primary class="mr-5" [color]="cancelTextColor" fill="clear" (btnClick)="onCancel()" data-cy="confirm-modal-cancel">
      {{ cancelText | translate }}
    </tizo-btn-primary>
    <tizo-btn-primary (btnClick)="onOk()" shape="round" [color]="okTextColor" data-cy="confirm-modal-ok">
      {{ okText | translate}}
    </tizo-btn-primary>
  </div>
</tizo-dialog-primary>
