import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { SavingService } from '../../../saving/saving.service';
import { LoanService } from '../../../loan/loan.service';
import {
    ILoanAccount,
    ILoanRequest,
    ISavingAccount,
    ISavingRequest,
    SavingRequestStatusEnum,
    SavingRequestType
} from '@canalcircle/models';
import { fadeIn } from '@shared/animations';
import { NavController } from '@ionic/angular';
import { FineractService } from '@services/fineract.service';
import { map } from 'rxjs/operators';
import { FeatureService } from '@services/feature.service';

@Component({
    selector: 'tizo-finance-card',
    templateUrl: './finance-card.component.html',
    styleUrls: ['./finance-card.component.scss'],
    animations: [
        fadeIn
    ]
})
export class FinanceCardComponent implements OnInit {
    totalLoanAmount: number;
    totalSavingAmount: number;
    isLoadingFinance: boolean;
    loanAccounts: Array<ILoanAccount> = [];
    savingAccounts: Array<ISavingAccount> = [];
    isAuthorized$: Observable<boolean>;
    latestRequest: ISavingRequest<any> & { _type?: 'saving' | 'loan' } | ILoanRequest<any> &  { _type?: 'saving' | 'loan' };

    isShowLoanCard: boolean;
    isShowSavingCard: boolean;
    isLoadingLastestRequest: boolean;
    color: string;

    constructor(
        private savingService: SavingService,
        private loanService: LoanService,
        private navCtrl: NavController,
        private finreactService: FineractService,
        private fetureService: FeatureService
    ) {
    }

    ngOnInit() {
        this.loadFinance();
        this.isAuthorized$ = this.finreactService.authorizedViewAccountsClients$
            .pipe(
                map(clients => clients.length > 0)
            );
        this.loadLatestRequest();
        this.loadLatestRequest();
        this.loadFeatureConfig();
    }

    // Loading saving and loans
    loadFinance() {
        this.isLoadingFinance = true;
        const savingAccounts$ = this.savingService.getSavingAccounts$('active');
        const loanAccounts$ = this.loanService.getLoanAccounts$('active');
        combineLatest([savingAccounts$, loanAccounts$]).subscribe(res => {
            const [savingAccounts, loanAccounts] = res;
            this.savingAccounts = savingAccounts;
            this.loanAccounts = loanAccounts;
            this.totalSavingAmount = this.savingAccounts
                .filter(a => a.status.active)
                .reduce((total, a) => total += a.summary.accountBalance, 0);
            this.totalLoanAmount = this.loanAccounts
                .filter(a => a.status.active)
                .reduce((total, a) => total += (a.summary?.totalOutstanding || 0), 0);
            this.isLoadingFinance = false;
        }, e => {
            this.isLoadingFinance = false;
        });
    }

    // Load latest request loan or saving
    loadLatestRequest() {
        this.isLoadingLastestRequest = true;

        combineLatest([
            this.savingService.getLatestSavingRequest$(),
            this.loanService.getLatestLoanRequest$()
        ]).subscribe(res => {
            const requests = res.filter(item => item != null)
                .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());
            this.latestRequest = requests.length > 0 ? requests[0] : null;
            console.log('lasest request', this.latestRequest);
            if (this.latestRequest) {
                switch (this.latestRequest.status) {
                    case SavingRequestStatusEnum.PENDING:
                    case SavingRequestStatusEnum.CLAIMED:
                        this.color = 'warning';
                        break;
                    case SavingRequestStatusEnum.APPROVED:
                    case SavingRequestStatusEnum.PAID:
                        this.color = 'success';
                        break;
                    case SavingRequestStatusEnum.CANCELED:
                    case SavingRequestStatusEnum.REJECTED:
                    case SavingRequestStatusEnum.FAILED:
                        this.color = 'danger';
                        break;
                }
            }
            this.isLoadingLastestRequest = false;
        }, e => {
            this.isLoadingLastestRequest = false;
        });
    }
    loadFeatureConfig() {
        this.fetureService.getVisibleCardsFeatureConfigs().subscribe(cards => {
            this.isShowLoanCard = cards.some(card => card.key === 'card_loan');
            this.isShowSavingCard = cards.some(card => card.key === 'card_saving');
        });
    }
    goToLoanPage() {
        this.navCtrl.navigateForward(['redirect', 'loan']);
    }

    goToSavingPage() {
        this.navCtrl.navigateForward(['redirect', 'saving']);
    }

    onClickLatestRequest() {
        if (this.latestRequest._type == 'saving') {
            this.goToSavingPage();
        } else if (this.latestRequest._type == 'loan') {
            this.goToLoanPage();
        }
    }
}
