import { firestore } from 'firebase';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Notification, NotificationMode, NotificationType } from '@canalcircle/models';
import { ROUTES } from '@shared/contants';
import { GeneralNotificationTypes } from '../constants';
import { getLocale } from 'src/app/i18n/utils';
export default class NotificationModel {
  private datePipe: DatePipe;
  locale: string;
  actionText: string;
  actionLink: string;


  constructor(
    public notification: Notification,
  ) {
    // @TODO: find a way to share instance of datepipe
    this.locale = getLocale();
    this.datePipe = new DatePipe(this.locale);
    this.setExtraInfo();
  }

  setExtraInfo() {
    const type = this.getType();
    const metaData = this.notification.metaData;

    switch (type) {
      case NotificationType.LOAN_REQUEST_REJECT:
      case NotificationType.LOAN_REQUEST_ACCEPT:
      case NotificationType.LOAN_REQUEST_SUBMITTED:
      case NotificationType.LOAN_REQUEST_CLAIMED:
      case NotificationType.LOAN_DISBURSEMENT:
        // this.actionLink = ROUTES.APPLICATION_OVERVIEW(this.getReference());
        break;
      case NotificationType.TIZO_CTA:
      case NotificationType.MARKETING:
        this.actionLink = this.getReference();
        break;
      case NotificationType.POINT_PLUS: 
      case NotificationType.MONEY_CLAIMED:
        if(metaData?.orderId){
          this.actionLink = ROUTES.CROSS_SELLING_ORDER_DETAIL(metaData.orderId);
        }
        break;
      case NotificationType.CROSS_SELLING_ADD_ORDER:
        if(metaData?.orderId){
          this.actionLink = ROUTES.CROSS_SELLING_ORDER_DETAIL(metaData.orderId);
        }
        break;
    }

    switch (type) {
      case NotificationType.LOAN_REQUEST_REJECT:
      case NotificationType.LOAN_REQUEST_ACCEPT:
      case NotificationType.LOAN_REQUEST_SUBMITTED:
      case NotificationType.LOAN_REQUEST_CLAIMED:
      case NotificationType.APPLICATION_APPROVED:
        this.actionText = 'notification.label.viewLoanApplicationDetail';
        break;
      case NotificationType.TIZO_CTA:
        this.actionText = 'notification.label.viewNow';
        break;
      case NotificationType.MARKETING:
        this.actionText = this.actionLink ? 'notification.label.viewNow' : null;
        break;
      case NotificationType.BUY_CODE_CARD:
        this.actionText = 'notification.label.copyCardCode';
        break;
      case NotificationType.POINT_PLUS:
      case NotificationType.POINT_SUBTRACT:
      case NotificationType.MONEY_CLAIMED:
      case NotificationType.MONEY_WITHDRAWAL:
      case NotificationType.CROSS_SELLING_ADD_ORDER:
          this.actionText = 'notification.label.viewDetails';
    }
  }

  getId() {
    return this.notification.id;
  }

  getTitle() {
    return this.notification.name;
  }

  getIsRead() {
    return this.notification.isRead;
  }

  getContent() {
    return this.notification.content;
  }

  getType() {
    return this.notification.type;
  }

  getReference() {
    return this.notification.reference;
  }

  getMetaData() {
    return this.notification.metaData;
  }

  getShortContent() {
    return this.notification.shortContent;
  }

  getCreatedAtFormmated() {
    return this.datePipe.transform((this.notification.createdAt as firestore.Timestamp).toDate(), 'dd/MM/yyyy');
  }

  getCreatedAtAgo() {
    return moment((this.notification.createdAt as firestore.Timestamp).toDate()).locale(this.locale).fromNow();
  }

  getNotificationIcon() {
    switch (this.notification.type) {
      case NotificationType.LOAN_REQUEST_REJECT:
      case NotificationType.LOAN_REQUEST_ACCEPT:
      case NotificationType.LOAN_REQUEST_SUBMITTED:
      case NotificationType.LOAN_REQUEST_CLAIMED:
      case NotificationType.APPLICATION_APPROVED:
        return 'assets/notification_icons/application.svg';
      case NotificationType.TIZO_CTA:
      case NotificationType.MARKETING:
        return 'assets/notification_icons/system.svg';
      case NotificationType.BUY_CODE_CARD:
        return 'assets/notification_icons/token.svg';
      case NotificationType.VSLA_NEW_MEETING_INVITATION:
        return 'assets/notification_icons/group.svg';
      default:
        return 'assets/notification_icons/system.svg';
    }
  }

  get isInApp() {
    return !this.notification.mode
      || this.notification.mode === NotificationMode.IN_APP
      || this.notification.mode === NotificationMode.IN_OUT_APP;
  }

  get isOutApp() {
    return !this.notification.mode
      || this.notification.mode === NotificationMode.OUT_APP
      || this.notification.mode === NotificationMode.IN_OUT_APP;
  }

  get isInOutApp() {
    return !this.notification.mode
      || this.notification.mode === NotificationMode.IN_OUT_APP;
  }
}
