import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callbackFilter'
})
export class CallbackFilterPipe implements PipeTransform {
  transform(items: any[], cb: (val: any) => {}): any {
    return cb(items);
  }
}
