import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule, Injectable, LOCALE_ID, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {FirebaseAnalytics} from '@ionic-native/firebase-analytics/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {environment} from 'src/environments/environment';
import {CoreModule} from '@core/core.module';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceLocator} from '@services/locator.service';

import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
// import { TranslationService } from '@shared/translate/translate.service';
import {ErrorHandler} from '@angular/core';
import {NetworkModule} from '@shared/network/network.module';
import {SuperTabsModule} from '@ionic-super-tabs/angular';
import {IonicStorageModule} from '@ionic/storage';
import {VslaCoreModule} from '@canalcircle/vsla-core-angular';
import {INTERCEPTORS} from '@core/interceptors';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {Camera} from '@ionic-native/camera/ngx';
import {Keyboard}from '@ionic-native/keyboard/ngx';
import {ImagePicker} from '@ionic-native/image-picker/ngx';
import {IonicNativeModule} from '@core/ionic-native/ionic-native.module';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {TranslateService} from '@shared/translate/translate.service';
import {SharedModule} from '@shared/shared.module';
// import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, DEBUG_MODE, UserTrackingService } from '@angular/fire/analytics';
import { default as packageJson } from '../../package.json';
import { Market } from '@ionic-native/market/ngx';
import { ComponentsModule } from './home-new/components/component.module';
import { removeFirestoreListening$ } from '@patches/fix-sign-out-subscription';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { DEFAULT_THEME, NbThemeModule } from '@nebular/theme';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDSN,
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/angular v5.16.0.
    environment: environment.name,
    integrations: [
    new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
    }),
    new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'canalcircle.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
    }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: environment.name === 'production' ? 0.1 : 1.0,
    release: environment.release,
  });
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            backButtonText: '',
            scrollAssist: true,
            scrollPadding: true,
            swipeBackEnabled: false,
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFireStorageModule,
        // AngularFireAnalyticsModule,
        CoreModule,
        NgxMaskModule.forRoot(),
        HttpClientModule,
        NetworkModule.forRoot(),
        SuperTabsModule.forRoot(),
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'vi',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        VslaCoreModule.initializeApp({
            authToken: '',
            tenantIds: [],
            firebase: {
                ...environment.firebase,
                stopListenChangeSubject$: removeFirestoreListening$,
            },
            apiUrl: environment.functionsUrl
        }),
        IonicNativeModule,
        SharedModule,
        ComponentsModule,
        NbThemeModule.forRoot({
          name: 'default', // change here it can be default, corporate, cosmic or dark
        },
        [ DEFAULT_THEME ],
        ),

    ],
    providers: [
        Camera,
        Keyboard,
        ImagePicker,
        MaskPipe,
        StatusBar,
        SocialSharing,
        Market,
        SplashScreen,
        InAppBrowser,
        FirebaseAnalytics,
        // UserTrackingService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: 'TranslationService', useClass: TranslateService},
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
        // { provide: DEBUG_MODE, useValue: environment.production === false},
        // { provide: APP_VERSION, useValue: packageJson.version},
        ...INTERCEPTORS
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(private injector: Injector) {
        ServiceLocator.injector = injector;
    }
}
