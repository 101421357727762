<ion-header class="home-header">
    <ion-toolbar>
        <ion-item>
            <ion-avatar slot="start" (click)="goToAccountPage()">
                <img [src]="user?.meta?.avatar || defaultAvatar" alt="">
            </ion-avatar>
            <!-- User info -->
            <ion-label (click)="goToAccountPage()">
                <div class="home-header__info">
                    <div>{{dayPeriods | translate}}, <span class="home-header__username">{{lastName}}</span></div>
                </div>
                <tizo-role-badge></tizo-role-badge>
                <!-- <div class="home-header__point">
                    <img src="/assets/icon/token.svg"> <span style="margin-left:'10px'">{{totalPoints}}</span>
                </div> -->
            </ion-label>

            <!--  Notification      -->
            <ion-button fill="clear" slot="end" (click)="goToNotificationPage()">
                <ion-icon name="notifications-outline" color="light">
                </ion-icon>
                <div class="ringring" *ngIf="totalUnreadNotifications > 0"></div>
                <div class="total-unread" *ngIf="totalUnreadNotifications > 0">{{totalUnreadNotifications <= 9 ? totalUnreadNotifications : '9+'}}</div>
            </ion-button>
        </ion-item>
    </ion-toolbar>
</ion-header>
