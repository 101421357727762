export const removeCountryCode = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  if (typeof phoneNumber !== 'string') {
    phoneNumber = '' + phoneNumber;
  }
  if (phoneNumber.startsWith('+')) {
    return '0' + phoneNumber.slice(3);
  }
  return phoneNumber;
};

export const addCountryCode = (phoneNumber: string): string => {
  if (phoneNumber.startsWith('0')) {
    return '+84' + phoneNumber.slice(1);
  }
  return phoneNumber;
};
