import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tizo-header-primary',
  templateUrl: './header-primary.component.html',
  styleUrls: ['./header-primary.component.scss'],
})
export class HeaderPrimaryComponent implements OnInit {
  @Input() title: string;
  @Input() showBackButton = true;
  constructor() { }

  ngOnInit() { }

}
