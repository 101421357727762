import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'tizo-role-badge',
    templateUrl: './role-badge.component.html',
    styleUrls: ['./role-badge.component.scss'],
})
export class RoleBadgeComponent implements OnInit, OnDestroy {
    roleName = '';
    roleBgColor = '';
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private userService: UserService
    ) {

    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    ngOnInit() {
        this.userService.getCurrentUser$().pipe(
            takeUntil(this.destroy$)
        ).subscribe(user => {
            const roles = user.roles || [];
            const isSeller = roles.indexOf('seller') > -1;
            if(isSeller){
                this.roleName = 'common.roles.seller';
                this.roleBgColor = '#EDBB18'
            } else {
                this.roleName = 'common.roles.member';
                this.roleBgColor = '#fff';
            }
            // if (this.userService.isSuperAgent(roles)) {
            //     this.roleName = 'common.roles.ccsuperagent';
            //     this.roleBgColor = '#D97DF9';
            // } else if (this.userService.isOfficerAgent(roles)) {
            //     this.roleName = 'common.roles.officer';
            //     this.roleBgColor = '#4CC89B';
            // } else if (this.userService.isAgent(roles)) {
            //     this.roleName = 'common.roles.agent';
            //     this.roleBgColor = '#FCCB4D';
            // }
        });
    }
}
