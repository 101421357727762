import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NotificationType } from '@canalcircle/models';
@Component({
  selector: 'tizo-point-change-modal',
  templateUrl: './point-change.modal.component.html',
  styleUrls: ['./point-change.modal.component.scss'],
})
export class PointChangeModalComponent implements OnInit {
  @Input() image: string;
  @Input() message: string;
  @Input() title: string;

  constructor(
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
