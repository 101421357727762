import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
@Component({
  selector: 'common-confirm-modal',
  templateUrl: './common-confirm-modal.component.html',
  styleUrls: ['./common-confirm-modal.component.scss']
})
export class CommonConfirmModalComponent {
  @Input() title = marker('common.label.alert');
  @Input() content: string;
  @Input() okText = marker('common.label.ok');
  @Input() cancelText = marker('common.label.cancel');
  @Input() okTextColor = 'gradient';
  @Input() cancelTextColor = 'dark';
  @Input() showCloseButton = true;

  constructor(
    public modalCtrl: ModalController
  ) {
  }

  async onOk() {
    this.modalCtrl.dismiss({ confirmed: true });
  }

  async onCancel() {
    this.modalCtrl.dismiss({ confirmed: false });
  }
}
