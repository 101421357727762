import { Component, OnInit, Input } from '@angular/core';
import TizoApplicationModel from 'src/app/application/models/TizoApplication.model';

@Component({
  selector: 'tizo-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
})
export class ApplicationCardComponent implements OnInit {
  @Input() application: TizoApplicationModel;
  constructor() { }

  ngOnInit() {}

}
