import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'tizo-no-login',
  templateUrl: './no-login.component.html',
  styleUrls: ['./no-login.component.scss'],
})
export class NoLoginComponent implements OnInit {
  @Input() text = 'Bạn cần đăng nhập để thực hiện chức năng này ';
  constructor(private navCtrl: NavController, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() { }
  goToLogin() {
    const currentUrl = this.router.url;
    console.log(currentUrl);
    this.navCtrl.navigateForward(['auth'], {
      queryParams: {
        redirectTo: currentUrl
      }
    });
  }
}
