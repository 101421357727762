import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { COLLECTION_NAMES } from '../../shared/contants/collections.const';
import { map } from 'rxjs/operators';
import { Platforms } from '@ionic/core';
import { Platform } from '@ionic/angular';
import _intersection from 'lodash/intersection';
import { UserService } from './user.service';
import { combineLatest, Observable } from 'rxjs';
import { User } from '@shared/models/user';
import { PhonePipe } from '@shared/input-phone/phone.pipe';
import { UtilsService } from './utils.service';
import { isValidPhoneNumber } from '@shared/utils';

@Injectable({
    providedIn: 'root'
})
export class RefererService {

    constructor(
        private afs: AngularFirestore,
        private userService: UserService,
        private utilsService: UtilsService,
    ) {
    }

    isAlreadyEnteredReferer(): Observable<boolean> {
        return  this.userService.getCurrentUser$().pipe(
            map(user => {
                return user && !!user?.meta?.referrerPhoneNumber;
            })
        );
    }

    async saveReferer(phoneNumber: string) {
        if (!isValidPhoneNumber(phoneNumber)) {
            throw new Error('Số điện thoại không đúng định dạng');
        }
        const currentUid = this.userService.currentUser?.id;

        if (!currentUid) {
            throw new Error('Không tìm thấy người dùng');
        }

        return this.afs.firestore.runTransaction(async txn => {
            const ref = this.afs.collection('users').doc(currentUid).ref;
            const currentUser = await txn.get(ref);
            const currentUserData = currentUser.data() as User;
            const e164PhoneNumber = this.utilsService.convertPhoneToE164Format(phoneNumber);
            if (!currentUser.exists) {
                throw new Error('Không tìm thấy người dùng');
            }
            if (currentUserData?.meta?.referrerPhoneNumber) {
                throw new Error('Bạn đã nhập mã giới thiệu rồi');
            }
            if (e164PhoneNumber === currentUserData.authInfo.phoneNumber) {
                throw new Error('Số điện thoại người giới thiệu phải khác số điện thoại của bạn');
            }
            await txn.set(ref, {
                meta: {
                    referrerPhoneNumber: e164PhoneNumber,
                }
            }, { merge: true });
        });
    }

}
