import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageLoadingComponent } from '@shared/image/image-loading/image-loading.component';
import { ImageResizeComponent } from './image-resize/image-resize.component';


@NgModule({
  declarations: [
    ImageLoadingComponent,
    ImageResizeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ImageLoadingComponent,
    ImageResizeComponent
  ]
})
export class ImageModule {
}
