import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

@Component({
  selector: 'tizo-header-searchbar',
  templateUrl: './header-searchbar.component.html',
  styleUrls: ['./header-searchbar.component.scss'],
})
export class HeaderSearchbarComponent implements OnInit {
  @Input() title = '';
  @Output() search: EventEmitter<string> = new EventEmitter();
  @ViewChild(IonSearchbar, { static: false }) searchBar: IonSearchbar;
  isShowSearchBar = false;
  constructor() { }

  ngOnInit() { }

  toggleSearchBar() {
    this.isShowSearchBar = !this.isShowSearchBar;
    if (this.isShowSearchBar) {
      setTimeout(() => {
        this.searchBar.setFocus();
      }, 500);
    }
    if (!this.isShowSearchBar) {
      this.search.emit('');
    }
  }
  onSearch(event) {
    const { value } = event.detail;
    this.search.emit(value);
  }
}
