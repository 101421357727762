import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { DistrictsSearchPage }              from './districts-search/districts-search.page';
import { WardsSearchPage }                  from './wards-search/wards-search.page';
import { ProvinceSearchPage }               from './province-search/province-search.page';
import { IonicModule }                      from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalSearchBaseComponent }         from './modal-search-base/modal-search-base.component';
import { StaticPageComponent }              from '@core/modals/static-page/static-page.component';
import { PointChangeModalComponent }        from './point-change/point-change.modal.component';
import { ModalModule }                      from '@shared/modal/modal.module';
import { SharedModule }                     from '@shared/shared.module';
import { SelectTenantModalComponent }       from '@core/modals/select-tenant-modal/select-tenant-modal.component';


@NgModule({
    declarations: [
        ProvinceSearchPage,
        DistrictsSearchPage,
        WardsSearchPage,
        ModalSearchBaseComponent,
        StaticPageComponent,
        PointChangeModalComponent,
        SelectTenantModalComponent
    ],
    exports: [
        ProvinceSearchPage,
        StaticPageComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        SharedModule,
    ],
    entryComponents: [
        ProvinceSearchPage,
        DistrictsSearchPage,
        WardsSearchPage,
        StaticPageComponent,
        PointChangeModalComponent,
        SelectTenantModalComponent
    ],
})
export class ModalsModule {
}
