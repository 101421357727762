import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { INewFeed, INewsCategory } from '@shared/models';
import { AngularFirestore, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { firestore } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class NewFeedApiService {

  constructor(
    private http: HttpClient,
    private afsStore: AngularFirestore
  ) { }

  getRootCategories$() {
    return this.afsStore.collection<INewsCategory>('newsCategories', ref => {
      return ref.where('parentPath', '==', '/');
    }).get().pipe(
      map(res => res.docs.map(doc => doc.data() as INewsCategory))
    );
  }

  getChildrenCategories$(parentPath = '/') {
    return this.afsStore.collection<INewsCategory>('newsCategories', ref => {
      return ref.where('parentPath', '>=', parentPath).where('parentPath', '<=', parentPath + '\uf8ff');
    }).valueChanges();
  }

  getCategory$(id: string): Observable<INewsCategory> {
    return this.afsStore.collection<INewsCategory>('newsCategories').doc(id).valueChanges() as Observable<INewsCategory>;
  }

  getFeeds(categoryPath = '/', loadChildren = true, limit = 10, after?: QueryDocumentSnapshot<INewFeed>): Observable<firestore.QuerySnapshot> {
    return this.getChildrenCategories$(categoryPath).pipe(
      switchMap(categories => {
        const categoriesPaths = [];
        // Include children
        if (loadChildren) {
          categories.forEach(cate => {
            categoriesPaths.push(`${cate.parentPath}${cate.id}/`);
          });
        }
        // Include itself
        categoriesPaths.push(categoryPath);

        if (categoriesPaths.length > 10) {
          console.log({ categories });
          throw new Error('Total categories must be <= 10 for path: ' + categoryPath);
        }
        return this.afsStore.collection<INewFeed>('news', ref => {
          // @ts-ignore
          ref = ref
            .where('categoryPath', 'in', categoriesPaths.length === 0 ? ['FAKE_PATH_FOR_EMPTY_RESULT'] : categoriesPaths)
            .where('publishAt', '<=', new Date());
          // @ts-ignore
          ref = ref.orderBy('publishAt', 'desc').limit(limit);
          if (after) {
            // @ts-ignore
            ref = ref.startAfter(after);
          }
          return ref;
        }).get().pipe(
          catchError(e => {
            console.error(e);
            return of({
              docs: []
            }) as Observable<firestore.QuerySnapshot>;
          })
        );
      })
    );
  }

  getFeed$(id: string): Observable<INewFeed> {
    return this.afsStore.collection<INewFeed>('news').doc<INewFeed>(id).valueChanges();
  }

  getLastestFeed$(limit = 4) {
    return this.afsStore.collection('news', ref => ref.orderBy('updatedAt', 'desc').limit(limit))
    .get()
    .pipe(
        map(actions => actions.docs.map(d => d.data() as INewFeed))
    );
  }
}
