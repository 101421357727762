import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
    ICreateLoanAccountRequestData,
    ILoanAccount,
    ILoanAccountShort,
    ILoanRequest, ILoanStatus, LoanAccountTransactionTypeEnum,
    LoanRequestData,
    LoanRequestStatusEnum,
    LoanRequestType, SavingRequestStatusEnum, SavingRequestType,
    ILoanProduct,
    LoanProduct,
    Client,
} from '@canalcircle/models';
import { FineractService } from '@services/fineract.service';
import { TenantService } from '@services/tenant.service';
import { UserService } from '@services/user.service';
import { CurrencyFormatPipe } from '@shared/input-currency/pipes/currency-format.pipe';
import { TranslatePipe } from '@shared/translate/translate.pipe';
import { firestore } from 'firebase';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, first, map, mapTo, switchMap, take, tap, timeout } from 'rxjs/operators';
import { ROUTES } from '@shared/contants';
import { SavingService } from '../saving/saving.service';
import { RoutingOfficeService } from '@services/routing-office.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '@environments/environment';
import { IBankInfo } from '@services/config.service';

@Injectable({
    providedIn: 'root',
})
export class LoanService {
    readonly COLLECTION_ID = 'loanRequests';
    readonly ALLOW_TXN_TYPES = [
        LoanAccountTransactionTypeEnum.REPAYMENT,
        LoanAccountTransactionTypeEnum.DISBURSEMENT
    ];

    constructor(
        private httpClient: HttpClient,
        private fineractService: FineractService,
        private afStore: AngularFirestore,
        private userService: UserService,
        private currencyFormatPipe: CurrencyFormatPipe,
        private tenantService: TenantService,
        // private translatePipe: TranslatePipe,
        private translateService: TranslateService,
        private savingService: SavingService,
        private routingOfficeService: RoutingOfficeService,
    ) {

    }

    async createLoanRequest(data: ICreateLoanAccountRequestData) {
        const tenantWithOfficePathsAndClient = await this.routingOfficeService.getTenantWithOfficePathsAndClient(
            'loan',
            data.location.province,
            data.location.district,
            data.location.ward
        );

        const batch = this.afStore.firestore.batch();

        for (const [tenantId, { officePaths }] of Object.entries(tenantWithOfficePathsAndClient)) {
            const loanRequest: ILoanRequest<ICreateLoanAccountRequestData> = {
                id: this.afStore.createId(),
                createdAt: firestore.Timestamp.now(),
                updatedAt: firestore.Timestamp.now(),
                data,
                type: LoanRequestType.CREATE_ACCOUNT,
                userId: this.userService.currentUser.id,
                tenantId,
                title: `Yêu cầu vay: ${this.currencyFormatPipe.transform(data.amount)}. Mục đích: ${data.purpose.name}. Kỳ hạn ${data.period.name}`,
                status: LoanRequestStatusEnum.PENDING,
                events: {
                    [firestore.Timestamp.now().toMillis()]: {
                        status: LoanRequestStatusEnum.PENDING,
                        message: null
                    }
                },
                officePaths: officePaths.length === 0 ? null : officePaths,
            };

            batch.set(this.afStore
                .collection<ILoanRequest<ICreateLoanAccountRequestData>>(this.COLLECTION_ID)
                .doc(loanRequest.id).ref, loanRequest);
        }

        await batch.commit();
    }

    public getLoanRequests$(types: LoanRequestType[] = [], loadClient?: boolean): Observable<ILoanRequest<LoanRequestData>[]> {
        return this.userService.getCurrentUserId().pipe(
            switchMap(uid => this.afStore.collection<ILoanRequest<LoanRequestData>>('loanRequests', ref => ref.where('userId', '==', uid))
                .valueChanges()
                .pipe(
                    map(loanRequests => {
                        return loanRequests
                            .filter(sr => types.length === 0 || types.includes(sr.type))
                            .sort((s1, s2) => s2.createdAt.toMillis() - s1.createdAt.toMillis());
                    }),
                    switchMap(loanRequests => {
                        if (!loadClient) {
                            return of(loanRequests);
                        }
                        if (loanRequests.length === 0) {
                            return of([]);
                        }

                        const observables$ = loanRequests.map(request => {
                            return this.userService.getClientByUserIdAndTenantId(request.userId, request.tenantId).pipe(
                                map(client => ({
                                    ...request,
                                    clientId: client.id
                                })),
                            );
                        });

                        return combineLatest(observables$);
                    })
                )
            )
        );
    }


    getLatestLoanRequest$() {
        return this.getLoanRequests$().pipe(
            map(loanRequests => loanRequests.length > 0 ? loanRequests[0] : null),
            map(request => {
                if (request != null) {
                    (request as any)._type = 'loan';
                }
                return request;
            })
        );
    }

    public cancelLoanRequests(requestId: string): Promise<void> {
        return this.afStore.firestore.runTransaction(async txn => {
            const ref = this.afStore.collection(this.COLLECTION_ID).doc(requestId).ref;
            const request = (await txn.get(ref)).data() as ILoanRequest<any>;
            if (!request) {
                throw new Error('Yêu cầu không tồn tại');
            }
            if (request.status !== LoanRequestStatusEnum.PENDING) {
                throw new Error('Trạng thái yêu cầu không hợp lệ');
            }

            await txn.update(ref, {
                status: LoanRequestStatusEnum.CANCELED,
                events: {
                    ...request.events,
                    [firestore.Timestamp.now().toMillis()]: {
                        status: LoanRequestStatusEnum.CANCELED,
                        message: null
                    }
                }
            });
        });
    }

    public getLoanProductsFbForTenantId$(tenantId: string): Observable<LoanProduct[]> {
        return this.afStore.collection('loanProducts', ref => ref.where('tenantId', '==', tenantId)).get().pipe(
            map(d => d.docs.map(d => d.data() as LoanProduct))
        );
    }

    public getLoanProductsCoreBForTenantId$(tenantId: string, clientId: string): Observable<ILoanProduct[]> {
        return this.httpClient.get<ILoanProduct[]>(this.fineractService.baseUrl + '/self/loanproducts', {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
            params: {
                clientId
            }
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
        );
    }

    public getRepaymentReconcilliations(tenantId: string, clientId: string): Observable<IRepaymentReconciliationListItem[]> {
        return this.httpClient.get<{ pageItems: IRepaymentReconciliationListItem[] }>(this.fineractService.baseUrl + `/self/loans/paymentreconciliation/client/${clientId}`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
            params: {
                allSchedule: 'true'
            }
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(rrs => rrs.pageItems.map(rr => this.formatRepaymentReconciliationListItem(rr, tenantId)))
        );
    }

    public getRepaymentReconcilliation(tenantId: string, clientId: string, paymentId: string): Observable<IRepaymentReconciliationListItem> {
        return this.httpClient.get<IRepaymentReconciliationListItem>(this.fineractService.baseUrl + `/self/loans/paymentreconciliation/${paymentId}/client/${clientId}`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
            params: {
                allSchedule: 'true'
            }
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(rr => this.formatRepaymentReconciliationListItem(rr, tenantId))
        );
    }

    public sendRepaymentReconcilliation(tenantId: string, clientId: string, loanSchedules: IFutureRepayment[], infoData?: any): Observable<ICreateRepaymentReconciliationResponse> {
        const paymentAmount = loanSchedules.reduce((t, sh) => t += sh.scheduledPrincipalAmount + sh.scheduledInterestAmount, 0);
        const loanPaymentReconciliation = {
            clientId,
            paymentAmount,
            paymentDate: moment().format('DD/MM/YYYY').toString(),
            fieldsJson: infoData || '',  // image, payment detail
            dateFormat: "dd/MM/yyyy",
            locale: "vi",
            loanSchedules: loanSchedules.map(repayment => ({
                loanId: repayment.loanId,
                scheduleId: repayment.scheduleId,
                installment: repayment.installment,
                dueDate: moment(repayment._closestDueDate).format('DD/MM/YYYY').toString(),
                principalAmount: repayment.scheduledPrincipalAmount,
                interestAmount: repayment.scheduledInterestAmount,
                locale: "vi",
                dateFormat: "dd/MM/yyyy"
            }))
        };

        return this.httpClient.post<ICreateRepaymentReconciliationResponse>(this.fineractService.baseUrl + '/self/loans/paymentreconciliation', loanPaymentReconciliation, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
        );
    }

    public sendPaymentTo9P(params: { paymentId: string, paymentCode: string, requestId: string, amount: number, bankCode: string, tenantId: string }): Observable<ICreateRepaymentReconciliationResponse> {
        console.log('sendPaymentTo9P params:', params);
        return this.httpClient.post<ICreateRepaymentReconciliationResponse>(
            `${environment.functions9pUrl}/integration9pay/depost-transfer-request`,
            params
        );
    }

    public setWaitForConfirmPayment(params: { paymentId: string, clientId: string, tenantId: string }): Observable<any> {
        console.log('sendPaymentTo9P params:', params);
        return this.httpClient.post<ICreateRepaymentReconciliationResponse>(this.fineractService.baseUrl + `/self/loans/paymentreconciliation/${params.paymentId}/client/${params.clientId}`, {}, {
            headers: this.fineractService.prepareHeaderForTenant(params.tenantId),
            params: {
                command: 'waitingforconfirmation'
            }
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
        );
    }

    public closePayment(params: { paymentId: string, clientId: string, tenantId: string }): Observable<any> {
        console.log('closePayment params:', params);
        return this.httpClient.post<ICreateRepaymentReconciliationResponse>(this.fineractService.baseUrl + `/self/loans/paymentreconciliation/${params.paymentId}/client/${params.clientId}`, {}, {
            headers: this.fineractService.prepareHeaderForTenant(params.tenantId),
            params: {
                command: 'close'
            }
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
        );
    }

    getBanksForPayment(tenantId: string, paymentId: string): Observable<IBankPayment[]> {
        return this.httpClient.post<{ data: { banks: IBankPayment[] } }>(
            `${environment.functions9pUrl}/integration9pay/banks`,
            { paymentId, tenantId }
        ).pipe(
            map(({ data }) => data?.banks || []),
            // @TODO: bank that missing img. check it again on prod
            map(banks => banks.filter(bank => !['ABBANK', 'VRB', 'TTTTTT'].includes(bank.code)))
        )
    }

    getSelectedBankByCode(tenantId: string, paymentId: string, bankCode: string): Observable<IBankPayment> {
        return this.getBanksForPayment(tenantId, paymentId).pipe(
            map(banks => banks.find(bank => bank.code === bankCode))
        )
    }

    public getFutureRepayments(tenantId: string, clientId: string): Observable<({ repayment: IFutureRepayment } & { loanAccount: ILoanAccount })[]> {
        return this.httpClient.get<IFutureRepayment[]>(this.fineractService.baseUrl + '/self/loans/schedules/client/' + clientId, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(repayments => repayments.map(repayment => ({
                ...repayment,
                _closestDueDate: new Date(repayment.closestDueDate[0], repayment.closestDueDate[1] - 1, repayment.closestDueDate[2])
            }))),
            switchMap(repayments => {
                const accounts$ = repayments.map(repayment => this.getLoanAccount(repayment.loanAccountNo.toString(), tenantId).pipe(
                    map(loanAccount => ({
                        loanAccount,
                        repayment
                    }))
                ));
                return accounts$.length > 0 ? forkJoin(accounts$) : of([]);
            })
        );
    }

    public calculateSchedule(client: Client, amount: number, loanProductCoreB: ILoanProduct, loanProduct: LoanProduct): Observable<ICalculateLoanResponse> {
        const today = moment().format('DD/MM/YYYY').toString();
        const request = {
            dateFormat: 'dd/MM/yyyy',
            locale: 'en',
            productId: loanProductCoreB.id,
            principal: amount,
            loanTermFrequency: loanProduct.repaymentEvery * loanProduct.numberOfRepayments,
            loanTermFrequencyType: loanProductCoreB.repaymentFrequencyType.id,
            numberOfRepayments: loanProductCoreB.numberOfRepayments,
            repaymentEvery: loanProductCoreB.repaymentEvery,
            repaymentFrequencyType: loanProductCoreB.repaymentFrequencyType.id,
            interestRatePerPeriod: loanProductCoreB.interestRatePerPeriod,
            amortizationType: loanProductCoreB.amortizationType.id,
            interestType: loanProductCoreB.interestType.id,
            interestCalculationPeriodType: loanProductCoreB.interestCalculationPeriodType.id,
            expectedDisbursementDate: today,
            transactionProcessingStrategyId: loanProductCoreB.transactionProcessingStrategyId,
            clientId: client.externalIdInfo[client.tenantId],
            loanType: 'individual',
            submittedOnDate: today
        };

        return this.httpClient.post<ICalculateLoanResponse>(this.fineractService.baseUrl + '/self/loans', request, {
            headers: this.fineractService.prepareHeaderForTenant(client.tenantId),
            params: {
                command: 'calculateLoanSchedule'
            }
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
        );
    }

    public getLoanAccounts$(statusKey?: keyof ILoanStatus): Observable<ILoanAccountWithLoanCategory[]> {
        return this.fineractService.authenticatedClients$.pipe(
            switchMap(clients => clients.length === 0 ?
                of([]) :
                forkJoin(
                    clients.map(
                        client => this.getLoanAccountsForTenantId$(client.externalIdInfo[client.tenantId], client.tenantId).pipe(
                            // In case not authorized yet.
                            catchError(_ => of([] as ILoanAccountShort[])),
                            map(accounts => {
                                return statusKey ?
                                    accounts.filter(account => account.status[statusKey] === true) :
                                    accounts;
                            }),
                            switchMap(loanAccountShorts => {
                                const $s: Observable<ILoanAccountWithLoanCategory>[] = [];
                                loanAccountShorts.forEach(loanAccountShort => {
                                    $s.push(this.getLoanAccountWithCategory(loanAccountShort.id.toString(), loanAccountShort._tenantId));
                                });
                                return $s.length === 0 ?
                                    of([]) :
                                    forkJoin($s);
                            })
                        )
                    )
                )),
            map((accounts: ILoanAccountWithLoanCategory[][]) => accounts.flat().flat().sort((ac1, ac2) => +ac2.id - +ac1.id))
        );
    }

    public getLoanAccount(id: string, tenantId: string): Observable<ILoanAccount> {
        return this.httpClient.get<ILoanAccount>(`${this.fineractService.baseUrl}/self/loans/${id}?associations=transactions,repaymentSchedule`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(loanAccount => this.formatLoanAccount(loanAccount, tenantId)),
        );
    }

    public getLoanAccountWithCategory(id: string, tenantId: string): Observable<ILoanAccountWithLoanCategory> {
        return this.httpClient.get<ILoanAccount>(`${this.fineractService.baseUrl}/self/loans/${id}?associations=transactions,repaymentSchedule`, {
            headers: this.fineractService.prepareHeaderForTenant(tenantId),
        }).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(loanAccount => this.formatLoanAccount(loanAccount, tenantId)),
            switchMap((loanAcc: any) => {
                // loan account sometimes has loanPurposeCode or loanPurposeName :)
                return this.getLoanCategoryByPurposeCode(loanAcc.loanPurposeCode, loanAcc.loanPurposeName).pipe(
                    map(loanCategory => ({
                        ...loanAcc,
                        loanCategory
                    }))
                )
            })
        );
    }

    public getLoanCategoryByPurposeCode(purposeCode?: string, purposeName?: string): Observable<ILoanCategory> {
        const byCode$ = purposeCode ? this.afStore.collection<ILoanCategory>('loanCategories', ref => ref.where('loanPurposeCode', '==', purposeCode)).get().pipe(
            map(d => d.empty ? null : d.docs[0].data() as ILoanCategory),
            // tap(r => console.log('byCode$: ', r)),
            catchError(e => of(null)),
        ) : of(null);
        const byName$ = purposeName ? this.afStore.collection<ILoanCategory>('loanCategories', ref => ref.where('name', '==', purposeName)).get().pipe(
            map(d => d.empty ? null : d.docs[0].data() as ILoanCategory),
            // tap(r => console.log('byNames: ', r)),
            catchError(e => of(null)),
        ) : of(null);
        return forkJoin([byCode$, byName$]).pipe(
            map(([byCode, byName]) => byCode || byName)
        )
    }

    public getLoanAccountsForTenantId$(clientId: string, tenantId: string): Observable<ILoanAccountShort[]> {
        return this.httpClient.get<{ loanAccounts: ILoanAccountShort[] }>(
            `${this.fineractService.baseUrl}/self/clients/${clientId}/accounts`,
            {
                headers: this.fineractService.prepareHeaderForTenant(tenantId),
            }
        ).pipe(
            timeout(this.fineractService.TIMEOUT),
            map(({ loanAccounts }) => {
                loanAccounts = loanAccounts || [];
                return loanAccounts.map(loanAccount => ({
                    ...loanAccount,
                    _tenantId: tenantId,
                }));
            }),
        );
    }

    public getEntryPage(): Observable<string> {
        return combineLatest([
            this.getLoanRequests$([LoanRequestType.CREATE_ACCOUNT]),
            this.savingService.getSavingRequests$([SavingRequestType.VIEW_ACCOUNTS]),
            this.fineractService.authorizedViewAccountsClients$.asObservable(),
        ]).pipe(
            map(([loanRequests, savingRequests, authorizedClients]) => {
                const validCreateLoanAccountRequests = loanRequests.filter(request =>
                    ![LoanRequestStatusEnum.CANCELED, LoanRequestStatusEnum.REJECTED].includes(request.status));
                const validViewAccountSavingRequests = savingRequests.filter(request =>
                    ![SavingRequestStatusEnum.CANCELED, SavingRequestStatusEnum.REJECTED].includes(request.status));

                if (
                    validCreateLoanAccountRequests.length > 0 ||
                    validViewAccountSavingRequests.length > 0 ||
                    authorizedClients.length > 0
                ) {
                    return ROUTES.LOAN_HOME;
                }

                return ROUTES.LOAN_ON_BOARDING;
            })
        );
    }

    private formatLoanAccount(loanAccount: ILoanAccount, tenantId: string) {
        loanAccount.transactions = loanAccount.transactions || [];
        loanAccount.transactions = loanAccount.transactions.filter(txn => this.ALLOW_TXN_TYPES.includes(txn.type.id) && !txn.manuallyReversed);
        loanAccount.transactions.forEach(txn => {
            txn._title = this.translateService.instant('loan.enums.' + txn.type.code);
            txn._date = new Date(txn.date[0], txn.date[1] - 1, txn.date[2]);

            return txn;
        });
        loanAccount.transactions.sort((t1, t2) => t2.id - t1.id);

        if (loanAccount.repaymentSchedule) {
            loanAccount.repaymentSchedule.periods = loanAccount.repaymentSchedule.periods || [];
            loanAccount.repaymentSchedule.periods.forEach(period => {
                period._dueDate = new Date(period.dueDate[0], period.dueDate[1] - 1, period.dueDate[2]);
                if (period.obligationsMetOnDate) {
                    period._obligationsMetOnDate = new Date(period.obligationsMetOnDate[0], period.obligationsMetOnDate[1] - 1, period.obligationsMetOnDate[2]);
                }

                return period;
            });
        }


        loanAccount._tenantId = tenantId;
        return loanAccount;
    }

    private formatRepaymentReconciliationListItem(repaymentReconcilliation: IRepaymentReconciliationListItem, tenantId: string) {
        repaymentReconcilliation._paymentDate = new Date(repaymentReconcilliation.paymentDate);
        repaymentReconcilliation._createdDate = new Date(repaymentReconcilliation.createdDate);
        repaymentReconcilliation._tenantId = tenantId;
        repaymentReconcilliation._fillBankInfo = !!repaymentReconcilliation.bankCode;
        switch (repaymentReconcilliation.statusEnum.code) {
            case 'payment.pending':
                repaymentReconcilliation._statusColor = 'warning';
                break;
            case 'payment.processing':
                repaymentReconcilliation._statusColor = 'tertiary';
                break;
            case 'payment.success':
                repaymentReconcilliation._statusColor = 'success';
                break;
            case 'payment.rejected':
            case 'payment.invalid':
            case 'payment.closed':
                repaymentReconcilliation._statusColor = 'danger';
                break;
        }
        return repaymentReconcilliation;
    }

}

export interface ICalculateLoanResponse {
    loanTermInDays: number
    totalPrincipalDisbursed: number
    totalPrincipalExpected: number
    totalPrincipalPaid: number
    totalInterestCharged: number
    totalFeeChargesCharged: number
    totalPenaltyChargesCharged: number
    totalRepaymentExpected: number
    totalOutstanding: number
    periods: IPeriod[]
}
export interface IPeriod {
    dueDate: number[]
    principalDisbursed?: number
    principalLoanBalanceOutstanding: number
    feeChargesDue: number
    feeChargesOutstanding?: number
    totalOriginalDueForPeriod: number
    totalDueForPeriod: number
    totalOutstandingForPeriod: number
    totalActualCostOfLoanForPeriod: number
    period?: number
    fromDate?: number[]
    daysInPeriod?: number
    principalOriginalDue?: number
    principalDue?: number
    principalOutstanding?: number
    interestOriginalDue?: number
    interestDue?: number
    interestOutstanding?: number
    penaltyChargesDue?: number
    totalPaidForPeriod?: number
    totalInstallmentAmountForPeriod?: number
}

export interface IFutureRepayment {
    clientName: string
    clientId: number
    loanId: number
    loanAccountNo: string
    scheduleId: number
    installment: number
    scheduledPrincipalAmount: number
    scheduledInterestAmount: number
    closestDueDate: number[]
    _closestDueDate: Date
    loanProductName: string
}


export interface ILoanAccountWithLoanCategory extends ILoanAccount {
    loanCategory: ILoanCategory
}

export interface ILoanCategory {
    id: string;
    actived: boolean;
    applicationPageId: string;
    description: string;
    icon: string;
    cardIcon: string;
    cardStyleId: string;
    loanPurposeCode: string;
    name: string;
}

export interface IRepaymentReconciliationListItem {
    id: number,
    clientExternalId: string,
    bankCode?: string,
    clientId: number,
    clientName: string,
    paymentNo: string,
    paymentAmount: number,
    paymentRequestAmount: number,
    requestId: string,
    statusEnum: {
        id: number,
        code: 'payment.pending' | 'payment.processing' | 'payment.success' | 'payment.rejected' | 'payment.invalid' | 'payment.closed'
        value: string
    },
    createdDate: string,
    _createdDate: Date
    paymentDate: string,
    _paymentDate: Date,
    loanSchedulePaymentReconciliationDataList: {
        loanAccountNo: string
    }[],
    _tenantId: string,
    _fillBankInfo: boolean,
    _statusColor: string,
}

export interface ICreateRepaymentReconciliationResponse {
    accountNumber: string
    resourceId: number
    transactionId: string
}


export interface IBankPayment {
    account_name: string
    account_no: string
    branch_name: string
    code: string
    image_url: string
    name: string
}