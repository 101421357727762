import { env } from './.env';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//
export const environment = {
    name: 'demo',
    version: env.npm_package_version + '-demo#' + env.release.slice(0, 5),
    release: env.release,
    production: false,
    firebase: {
        apiKey: 'AIzaSyCwi8EJL_3R6wKFBUMSr0Lkcok6JlqmbXo',
        authDomain: 'canalcircle-production.firebaseapp.com',
        databaseURL: 'https://canalcircle-production.firebaseio.com',
        projectId: 'canalcircle-production',
        storageBucket: 'canalcircle-production.appspot.com',
        messagingSenderId: '781051316577',
        appId: '1:781051316577:web:df5f8556b690bc22',
    },
    functionsUrl: 'https://us-central1-canalcircle-production.cloudfunctions.net',
    functions9pUrl: 'https://us-central1-canalcircle-production.cloudfunctions.net',
    trandataUrl: 'https://api.uat.trandata.io/aggregator/api/v1',
    ALGOLIA_APP_ID: '4FQ5LYIRC0',
    ALGOLIA_SEARCH_KEY: 'f3f2d55c9c4abd747d1c1338db127407',
    ALGOLIA_LOCATION: 'us-central1',
    mixpanel: {
        token: '2c460602c48f7859aef7f4d4ef920ffd',
    },
    sentryDSN: 'https://f9e2dab089a040b8a3a60a22284a683b@sentry.io/1838235',
    hubspotLiveChat: '//js.hs-scripts.com/7309731.js',
    googleMapApiKey: 'AIzaSyBYIEM66UOSsCoxXmtD5EoG5vYJNg6bpdg',
    shopify: {
        url: 'https://canalcircle-com.myshopify.com/api/2020-07/graphql.json',
        accesstoken: '78388954c1e2eebe3387b9ce72962435',
        adminUrl: 'https://us-central1-canalcircle-production.cloudfunctions.net/shopify-v1'
    },
    fineractUrl: 'https://api-demo.canalcircle.com/fineract-provider/api/v1',
    oneLink: 'http://onelink.to/r6sjfu'
  
  };
  
  