<div
  class="phone-input">
  <input
    #input
    [formControl]="form"
    [readonly]="readonly"
    [placeholder]="placeholder"
    [mask]="maskConfig"
    [attr.disabled]="isDisabled"
    [maxlength]="maxlength"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (keypress)="onPress($event)"
    type="tel">
</div>
