<div class="mfi-promotion-card">
  <div class="mfi-promotion-card__body">
    <div class="mfi-promotion-card__body__left">
      <div class="mfi-promotion-card__title">{{ title }}</div>
      <div class="mfi-promotion-card__description">{{ description }}</div>
    </div>
    <div class="mfi-promotion-card__body__right">
      <img src="/assets/imgs/mfi-promotion.svg" />
    </div>
  </div>
  <div class="mfi-promotion-card__footer">
    <button>
      Vay ngay
    </button>
  </div>
</div>