export const LOAN_PURPOSES = [
    {
        id: 'BORROW_URGENT',
        label: 'Vay khẩn cấp',
    },
    {
        id: 'BORROW_FOR_EXPENSE',
        label: 'Vay tiêu dùng',
    },
    {
        id: 'BORROW_FOR_BUSINESS',
        label: 'Vay sản xuất kinh doanh',
    },
];

export const RECEIVE_MONEY_METHODS = [
    {
        id: 'CASH',
        label: 'Tiền mặt',
    },
    {
        id: 'BANK_TRANSFER',
        label: 'Chuyển khoản',
    },
];
