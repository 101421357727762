<div class="news-category">
    <div class="news-category__header" (click)="goToDetailCategory()">
        <div class="news-category__header__title">
            {{ category.title }}
        </div>
        <div class="news-category__header__sub_title">
            <ion-icon name="arrow-forward-outline"></ion-icon>
        </div>
    </div>
    <ion-row class="news-category__body" *ngIf="!isLoading && feeds.length > 0">
        <ion-col size="6" size-md="4" *ngFor="let newFeed of feeds" (click)="goToDetailNewFeed(newFeed.id)">
            <tizo-new-feed [newFeed]="newFeed">
            </tizo-new-feed>
        </ion-col>
    </ion-row>

    <ion-row *ngIf="!isLoading && feeds.length === 0">
        <ion-col size="12" class="d-flex ion-justify-content-center">
            <i>{{'news.label.no_categories' | translate}}</i>
        </ion-col>
    </ion-row>

    <ng-template [ngIf]="isLoading">
        <ion-row>
            <ion-col size="6" size-md="4" *ngFor="let item of [1,2,3,4,5,6]">
                <tizo-new-feed-loading></tizo-new-feed-loading>
            </ion-col>
        </ion-row>
    </ng-template>
</div>