import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'tizo-date-time',
    templateUrl: './date-time.component.html',
    styleUrls: ['./date-time.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimeComponent), multi: true}
    ]
})
export class DateTimeComponent implements OnInit, ControlValueAccessor {
    @Input() name;
    @Input() format = 'DD/MM/YYYY';
    @Input() value = new Date().toISOString();
    @Input() required: boolean;
    form = new FormControl('');

    constructor() {
    }

    ngOnInit() {
    }

    writeValue(value: string) {
        this.form.setValue(value);
    }

    registerOnChange(fn: any) {
        this.form.valueChanges.subscribe(fn);

    }

    registerOnTouched(fn: any) {
    }
}
